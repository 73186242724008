import React, { useState, useEffect } from "react";
import { StickyNavbar } from "../../components/UserNavbar";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CountUp from "react-countup";
import UserFooter from "../../components/UserFooter";
import Slider from "react-slick";
import Preloader from "../../components/Preloader";
import { TbRulerMeasure } from "react-icons/tb";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { getServices } from "../../apiServices/apiServices";
import { FaArrowRightLong, FaRegBuilding } from "react-icons/fa6";
import ContactModal from "../../components/modal/user/ContactModal";
import { Divider } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Garden from "../../assets/garden.svg";
import ComingSoonPage from "../../components/ComingSoonPage";
import ComingsoonImg from "../../assets/coming soon.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper/modules";
import moment from "moment";

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [residentialData, setResidentialData] = useState([]);
  const [commercialData, setCommercialData] = useState([]);
  const [plotData, setPlotData] = useState([]);
  const [counterValue, setCounterValue] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [modal, setModal] = useState({ contact_modal: false });
  const [overviewData, setOverviewData] = useState({});
  const [seoData, setSeoData] = useState({});
  const [buyersData, setBuyersData] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    fetchResidentialData();
    fetchCommercialData();
    fetchPlotsData();
    fetchCounterData();
    fetchBannerList();
    fetchOverviewData();
    fetchSEOData();
    fetchBuyerDataList();
  }, []);

  const fetchResidentialData = async () => {
    try {
      const res = await getServices(
        `/api/property/list_property?property_type=1`
      );
      if (res.responseCode === 200) {
        setResidentialData(res.responseData);
      } else if (res.responseCode === 205) return;
      else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const fetchCommercialData = async () => {
    try {
      const res = await getServices(
        `/api/property/list_property?property_type=2`
      );
      if (res.responseCode === 200) {
        setCommercialData(res.responseData);
      } else if (res.responseCode === 205) return;
      else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const fetchPlotsData = async () => {
    try {
      const res = await getServices(
        `/api/property/list_property?property_type=3`
      );
      if (res.responseCode === 200) {
        setPlotData(res.responseData);
      } else if (res.responseCode === 205) return;
      else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const fetchOverviewData = async () => {
    try {
      const res = await getServices(`/api/overview/list`);
      if (res.responseCode === 200) {
        setOverviewData(res.responseData[0]);
      } else {
        setOverviewData([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const fetchSEOData = async () => {
    try {
      const res = await getServices(`/api/seo/list?seo_type=4`);
      if (res.responseCode === 200) {
        setSeoData(res.responseData[0]);
      } else {
        setSeoData({});
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchCounterData = async () => {
    try {
      const res = await getServices(`/api/stats/list`);
      if (res.responseCode === 200) {
        setCounterValue(res.responseData);
      } else {
        setCounterValue([]);
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchBannerList = async () => {
    try {
      const res = await getServices(`/api/banner/list`);
      if (res.responseCode === 200) {
        setBannerList(res.responseData);
      } else {
        toast.error(res.responseMessage);
        setBannerList([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchBuyerDataList = async () => {
    try {
      const res = await getServices(`/api/lookup/list_lookup?page=1`);
      setLoading(false);
      if (res.responseCode === 200) {
        setBuyersData(res.responseData);
      } else {
        setBuyersData([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const settings = {
    dots: false,
    infinite: false, // Set to true for continuous sliding
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          cssEase: "linear",
          infinite: false, // Ensure infinite is true in responsive settings as well
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          cssEase: "linear",
          infinite: false,
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          cssEase: "linear",
          infinite: false,
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          cssEase: "linear",
          infinite: false,
        },
      },
    ],
  };

  const lookup_settings = {
    dots: false,
    infinite: false, // Set to true for continuous sliding
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          cssEase: "linear",
          infinite: false, // Ensure infinite is true in responsive settings as well
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          cssEase: "linear",
          infinite: false,
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          cssEase: "linear",
          infinite: false,
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          cssEase: "linear",
          infinite: false,
        },
      },
    ],
  };

  if (loading) {
    return <Preloader />;
  }

  // const CustomArrowPrev = (onClickHandler, hasPrev, label) =>
  //   hasPrev && (
  //     <button
  //       type="button"
  //       onClick={onClickHandler}
  //       title={label}
  //       className="custom-arrow custom-arrow-prev"
  //       style={{
  //         position: "absolute",
  //         top: "50%",
  //         left: 12,
  //         zIndex: 2,
  //         backgroundColor: "white",
  //         border: "none",
  //         color: "#36b8cc",
  //         width: "50px",
  //         height: "50px",
  //         borderRadius: "50%",
  //         padding: 0,
  //         cursor: "pointer",
  //         transform: "translateY(-50%)",
  //         transition: "color 0.3s ease, background-color 0.3s ease",
  //         fontSize: "24px",
  //         lineHeight: "50px",
  //         textAlign: "center",
  //       }}
  //       onMouseEnter={(e) => {
  //         e.currentTarget.style.color = "white";
  //         e.currentTarget.style.backgroundColor = "#36b8cc";
  //       }}
  //       onMouseLeave={(e) => {
  //         e.currentTarget.style.color = "#36b8cc";
  //         e.currentTarget.style.backgroundColor = "white";
  //       }}
  //     >
  //       &#10094;
  //     </button>
  //   );

  // const CustomArrowNext = (onClickHandler, hasNext, label) =>
  //   hasNext && (
  //     <button
  //       type="button"
  //       onClick={onClickHandler}
  //       title={label}
  //       className="custom-arrow custom-arrow-next"
  //       style={{
  //         position: "absolute",
  //         top: "50%",
  //         right: 12,
  //         zIndex: 2,
  //         backgroundColor: "white",
  //         border: "none",
  //         color: "#36b8cc",
  //         width: "50px",
  //         height: "50px",
  //         borderRadius: "50%",
  //         padding: 0,
  //         cursor: "pointer",
  //         transform: "translateY(-50%)",
  //         transition: "color 0.3s ease, background-color 0.3s ease",
  //         fontSize: "24px",
  //         lineHeight: "50px",
  //         textAlign: "center",
  //       }}
  //       onMouseEnter={(e) => {
  //         e.currentTarget.style.color = "white";
  //         e.currentTarget.style.backgroundColor = "#36b8cc";
  //       }}
  //       onMouseLeave={(e) => {
  //         e.currentTarget.style.color = "#36b8cc";
  //         e.currentTarget.style.backgroundColor = "white";
  //       }}
  //     >
  //       &#10095;
  //     </button>
  //   );

  const windowHeight = window.innerHeight;
  const carouselHeight = windowHeight / 4;

  // const carouselStyle = {
  //   width: "50%",
  //   height: `${carouselHeight}px`,
  //   borderRadius: "20px",
  // };

  const imageStyle = {
    objectFit: "cover",
    width: "100%",
    background: "none",
  };

  return (
    <div>
      <Helmet>
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        {seoData.og_title !== "" &&
          seoData.og_title !== null &&
          seoData.og_title !== "null" && (
            <meta property="og:title" content={seoData.og_title} />
          )}
        {seoData.og_description !== "" &&
          seoData.og_description !== null &&
          seoData.og_description !== "null" && (
            <meta property="og:description" content={seoData.og_description} />
          )}
      </Helmet>

      <StickyNavbar>
        {/* {bannerList.length > 0 && ( */}
        <Slider
          dots={false}
          infinite={true}
          speed={5000}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={4000}
          className="gap-0 top-slider"
        >
          {bannerList.map((banner, i) => (
            <div
              key={i}
              onClick={() => {
                setModal({ ...modal, contact_modal: true });
              }}
            >
              <img
                src={banner.image}
                alt="Rai&rao"
                style={imageStyle}
                className="h-[220px] sm:h-[300px] md:h-[450px] lg:h-[600px] "
              />
            </div>
          ))}
        </Slider>
        {/* )} */}

        {/* counter */}

        {/* <div className="flex justify-around items-center lg:m-10 lg:gap-20">
          {counterValue.map((counter, i) => (
            <div className="p-5" key={i}>
              <CountUp
                className="text-3xl md:text-6xl font-semibold flex justify-center items-center"
                end={counter.count}
                decimals={i === counterValue.length - 1 ? 2 : 0}
              />
              <p className="text-xs md:text-base text-[#857d98] text-center">
                {counter.desc}
              </p>
            </div>
          ))}
        </div> */}

        {/* Residential */}
        <div className="max-w-[1300px] mx-auto">
          <div className="w-full mb-10 pt-10">
            <div className="w-full flex justify-between items-center mb-5 px-4">
              <h1 className="text-2xl md:text-2xl font-normal uppercase tracking-widest text-[#3C3450]">
                Residential
              </h1>
              <Link to="/residential">
                <button className="text-[#224c8f] hover:rounded-3xl px-4 py-1 hover:bg-gray-100 h-fit flex items-center gap-2">
                  Explore <FaArrowRightLong />
                </button>
              </Link>
            </div>

            <div className="px-4 sm:px-0">
              {residentialData.length > 0 ? (
                <>
                  <Slider
                    {...{
                      ...settings,
                      infinite: false,
                    }}
                    className="gap-3 prop-slider"
                  >
                    {residentialData.map((property, index) => {
                      const imageFiles = property.file?.filter(
                        (file) =>
                          file.endsWith(".jpg") ||
                          file.endsWith(".jpeg") ||
                          file.endsWith(".png") ||
                          file.endsWith(".gif") ||
                          file.endsWith(".webp") ||
                          file.endsWith(".svg") ||
                          file.endsWith(".heif") ||
                          file.endsWith(".heic") ||
                          file.endsWith(".avif")
                      );

                      return (
                        <Link
                          to={`/property_details/${property.property_code}`}
                          key={index}
                          className="flex justify-center bg-[#eff2ef] hover:bg-[#e3e3e3] rounded-xl gap-6 outline-none border transition-all relative"
                        >
                          <img
                            src={imageFiles[0]}
                            alt="Rai&rao"
                            className="rounded-md h-64 object-cover w-full"
                          />
                          <div className="text-[#273238] p-2 flex flex-col gap-2">
                            <p className="font-normal tracking-[0.5px] uppercase text-base">
                              {/* {property.property_name} */}
                              {property.property_name?.length > 25
                                ? property.property_name.substring(0, 25) +
                                  "..."
                                : property.property_name}
                            </p>
                            <span className="font-medium text-base">
                              <span className="font-sans">₹</span>
                              {property.price}
                            </span>
                            {/* {property.residentialInfo && ( */}
                            <div className="flex gap-3">
                              <p className="flex font-medium text-[12px] gap-1">
                                <FaRegBuilding className="w-5 h-5 text-[#858585]" />
                                {property.label === "1"
                                  ? "1BHK"
                                  : property.label === "2"
                                  ? "2BHK"
                                  : property.label === "3"
                                  ? "3BHK"
                                  : property.label === "4"
                                  ? "3BHK+"
                                  : ""}
                              </p>
                              <p className="flex font-medium text-[12px] gap-1">
                                <TbRulerMeasure className="w-5 h-5 text-[#858585]" />
                                {property.sqft}{" "}
                                <span className="font-normal text-[12px]">
                                  {property.area_type === "1"
                                    ? "Acres"
                                    : property.area_type === "2"
                                    ? "Cents"
                                    : property.area_type === "3"
                                    ? "Sqft"
                                    : ""}
                                </span>
                              </p>
                              {property.residentialInfo.terrace !== null &&
                                property.residentialInfo.terrace !== "" &&
                                property.residentialInfo?.terrace !==
                                  "null" && (
                                  <p className="flex font-medium text-[12px] gap-1">
                                    <img
                                      src={Garden}
                                      className="w-5 h-5 text-[#dadada]"
                                    />
                                    {property.residentialInfo.terrace !==
                                      null &&
                                    property.residentialInfo.terrace !== "" &&
                                    property.residentialInfo?.terrace !== "null"
                                      ? property.residentialInfo.terrace +
                                        " " +
                                        (property.residentialInfo
                                          .terrace_type === "1"
                                          ? "Acres"
                                          : property.residentialInfo
                                              .terrace_type === "2"
                                          ? "Cents"
                                          : property.residentialInfo
                                              .terrace_type === "3"
                                          ? "Sqft"
                                          : "")
                                      : ""}
                                  </p>
                                )}
                            </div>
                            {/* )} */}
                          </div>

                          <div className="absolute top-2 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                            <p>
                              {property.category === "1"
                                ? "Buy"
                                : property.category === "2"
                                ? "Rent/Lease"
                                : ""}
                            </p>
                          </div>
                          {property.residentialInfo?.interior_status && (
                            <div className="absolute top-10 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                              <p>
                                {property.residentialInfo?.interior_status ==
                                "1"
                                  ? "Furnished"
                                  : property.residentialInfo?.interior_status ==
                                    "2"
                                  ? "Semifurnished"
                                  : property.residentialInfo?.interior_status ==
                                    "3"
                                  ? "Unfurnished"
                                  : ""}
                              </p>
                            </div>
                          )}
                        </Link>
                      );
                    })}
                  </Slider>

                  <div className="block sm:hidden">
                    {residentialData.length > 2 ? (
                      <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={"auto"}
                        autoplay={true}
                        initialSlide={1}
                        coverflowEffect={{
                          rotate: 50,
                          stretch: 0,
                          depth: 100,
                          modifier: 1,
                          slideShadows: true,
                        }}
                        modules={[EffectCoverflow, Pagination]}
                        className="mySwiper"
                      >
                        {residentialData.map((property, index) => {
                          const imageFiles = property.file?.filter(
                            (file) =>
                              file.endsWith(".jpg") ||
                              file.endsWith(".jpeg") ||
                              file.endsWith(".png") ||
                              file.endsWith(".gif") ||
                              file.endsWith(".webp") ||
                              file.endsWith(".svg") ||
                              file.endsWith(".heif") ||
                              file.endsWith(".heic") ||
                              file.endsWith(".avif")
                          );

                          return (
                            <SwiperSlide key={index}>
                              <Link
                                to={`/property_details/${property.property_code}`}
                                className="justify-center block h-fit bg-[#eff2ef] hover:bg-[#e3e3e3] rounded-xl gap-6 outline-none border transition-all relative"
                              >
                                <img
                                  src={imageFiles[0]}
                                  alt="Rai&rao"
                                  className="rounded-md h-64 object-cover w-full"
                                />
                                <div className="text-[#273238] p-2 flex flex-col gap-2">
                                  <p className="font-normal tracking-[0.5px] uppercase text-base">
                                    {/* {property.property_name} */}
                                    {property.property_name?.length > 25
                                      ? property.property_name.substring(
                                          0,
                                          25
                                        ) + "..."
                                      : property.property_name}
                                  </p>
                                  <span className="font-medium text-base">
                                    <span className="font-sans">₹</span>
                                    {property.price}
                                  </span>
                                  {/* {property.residentialInfo && ( */}
                                  <div className="flex gap-3">
                                    <p className="flex font-medium text-[12px] gap-1">
                                      <FaRegBuilding className="w-5 h-5 text-[#858585]" />
                                      {property.label === "1"
                                        ? "1BHK"
                                        : property.label === "2"
                                        ? "2BHK"
                                        : property.label === "3"
                                        ? "3BHK"
                                        : property.label === "4"
                                        ? "3BHK+"
                                        : ""}
                                    </p>
                                    <p className="flex font-medium text-[12px] gap-1">
                                      <TbRulerMeasure className="w-5 h-5 text-[#858585]" />
                                      {property.sqft}{" "}
                                      <span className="font-normal text-[12px]">
                                        {property.area_type === "1"
                                          ? "Acres"
                                          : property.area_type === "2"
                                          ? "Cents"
                                          : property.area_type === "3"
                                          ? "Sqft"
                                          : ""}
                                      </span>
                                    </p>
                                    {property.residentialInfo.terrace !==
                                      null &&
                                      property.residentialInfo.terrace !== "" &&
                                      property.residentialInfo?.terrace !==
                                        "null" && (
                                        <p className="flex font-medium text-[12px] gap-1">
                                          <img
                                            src={Garden}
                                            className="w-5 h-5 text-[#dadada]"
                                          />
                                          {property.residentialInfo.terrace !==
                                            null &&
                                          property.residentialInfo.terrace !==
                                            "" &&
                                          property.residentialInfo?.terrace !==
                                            "null"
                                            ? property.residentialInfo.terrace +
                                              " " +
                                              (property.residentialInfo
                                                .terrace_type === "1"
                                                ? "Acres"
                                                : property.residentialInfo
                                                    .terrace_type === "2"
                                                ? "Cents"
                                                : property.residentialInfo
                                                    .terrace_type === "3"
                                                ? "Sqft"
                                                : "")
                                            : ""}
                                        </p>
                                      )}
                                  </div>
                                  {/* )} */}
                                </div>

                                <div className="absolute top-2 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                                  <p>
                                    {property.category === "1"
                                      ? "Buy"
                                      : property.category === "2"
                                      ? "Rent/Lease"
                                      : ""}
                                  </p>
                                </div>
                                {property.residentialInfo?.interior_status && (
                                  <div className="absolute top-10 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                                    <p>
                                      {property.residentialInfo
                                        ?.interior_status == "1"
                                        ? "Furnished"
                                        : property.residentialInfo
                                            ?.interior_status == "2"
                                        ? "Semifurnished"
                                        : property.residentialInfo
                                            ?.interior_status == "3"
                                        ? "Unfurnished"
                                        : ""}
                                    </p>
                                  </div>
                                )}
                              </Link>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    ) : (
                      <Slider
                        {...{
                          ...settings,
                          infinite: false,
                        }}
                        className="my-slider gap-3 prop-slider"
                      >
                        {residentialData.map((property, index) => {
                          const imageFiles = property.file?.filter(
                            (file) =>
                              file.endsWith(".jpg") ||
                              file.endsWith(".jpeg") ||
                              file.endsWith(".png") ||
                              file.endsWith(".gif") ||
                              file.endsWith(".webp") ||
                              file.endsWith(".svg") ||
                              file.endsWith(".heif") ||
                              file.endsWith(".heic") ||
                              file.endsWith(".avif")
                          );

                          return (
                            <Link
                              to={`/property_details/${property.property_code}`}
                              key={index}
                              className="flex justify-center bg-[#eff2ef] hover:bg-[#e3e3e3] rounded-xl gap-6 outline-none border transition-all relative"
                            >
                              <img
                                src={imageFiles[0]}
                                alt="Rai&rao"
                                className="rounded-md h-64 object-cover w-full"
                              />
                              <div className="text-[#273238] p-2 flex flex-col gap-2">
                                <p className="font-normal tracking-[0.5px] uppercase text-base">
                                  {/* {property.property_name} */}
                                  {property.property_name?.length > 25
                                    ? property.property_name.substring(0, 25) +
                                      "..."
                                    : property.property_name}
                                </p>
                                <span className="font-medium text-base">
                                  <span className="font-sans">₹</span>
                                  {property.price}
                                </span>
                                {/* {property.residentialInfo && ( */}
                                <div className="flex gap-3">
                                  <p className="flex font-medium text-[12px] gap-1">
                                    <FaRegBuilding className="w-5 h-5 text-[#858585]" />
                                    {property.label === "1"
                                      ? "1BHK"
                                      : property.label === "2"
                                      ? "2BHK"
                                      : property.label === "3"
                                      ? "3BHK"
                                      : property.label === "4"
                                      ? "3BHK+"
                                      : ""}
                                  </p>
                                  <p className="flex font-medium text-[12px] gap-1">
                                    <TbRulerMeasure className="w-5 h-5 text-[#858585]" />
                                    {property.sqft}{" "}
                                    <span className="font-normal text-[12px]">
                                      {property.area_type === "1"
                                        ? "Acres"
                                        : property.area_type === "2"
                                        ? "Cents"
                                        : property.area_type === "3"
                                        ? "Sqft"
                                        : ""}
                                    </span>
                                  </p>
                                  {property.residentialInfo.terrace !== null &&
                                    property.residentialInfo.terrace !== "" &&
                                    property.residentialInfo?.terrace !==
                                      "null" && (
                                      <p className="flex font-medium text-[12px] gap-1">
                                        <img
                                          src={Garden}
                                          className="w-5 h-5 text-[#dadada]"
                                        />
                                        {property.residentialInfo.terrace !==
                                          null &&
                                        property.residentialInfo.terrace !==
                                          "" &&
                                        property.residentialInfo?.terrace !==
                                          "null"
                                          ? property.residentialInfo.terrace +
                                            " " +
                                            (property.residentialInfo
                                              .terrace_type === "1"
                                              ? "Acres"
                                              : property.residentialInfo
                                                  .terrace_type === "2"
                                              ? "Cents"
                                              : property.residentialInfo
                                                  .terrace_type === "3"
                                              ? "Sqft"
                                              : "")
                                          : ""}
                                      </p>
                                    )}
                                </div>
                                {/* )} */}
                              </div>

                              <div className="absolute top-2 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                                <p>
                                  {property.category === "1"
                                    ? "Buy"
                                    : property.category === "2"
                                    ? "Rent/Lease"
                                    : ""}
                                </p>
                              </div>
                              {property.residentialInfo?.interior_status && (
                                <div className="absolute top-10 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                                  <p>
                                    {property.residentialInfo
                                      ?.interior_status == "1"
                                      ? "Furnished"
                                      : property.residentialInfo
                                          ?.interior_status == "2"
                                      ? "Semifurnished"
                                      : property.residentialInfo
                                          ?.interior_status == "3"
                                      ? "Unfurnished"
                                      : ""}
                                  </p>
                                </div>
                              )}
                            </Link>
                          );
                        })}
                      </Slider>
                    )}
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center min-h-40 px-2">
                  <p>
                    No properties found. Don't worry, new listings are added
                    regularly. Stay tuned!
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="w-full mb-10">
            <div className="w-full flex justify-between items-center mb-5 px-4">
              <h1 className="text-2xl md:text-2xl font-normal tracking-widest uppercase text-[#3C3450]">
                Plots
              </h1>
              <Link to="/plot">
                <button className="text-[#224c8f] hover:rounded-3xl px-4 py-1 hover:bg-gray-100 h-fit flex items-center gap-2">
                  Explore <FaArrowRightLong />
                </button>
              </Link>
            </div>
            <div className="px-4 sm:px-0">
              {plotData.length > 0 ? (
                <>
                  <Slider
                    {...{
                      ...settings,
                      infinite: false,
                    }}
                    className="gap-3 prop-slider"
                  >
                    {plotData.map((property, index) => {
                      const imageFiles = property.file?.filter(
                        (file) =>
                          file.endsWith(".jpg") ||
                          file.endsWith(".jpeg") ||
                          file.endsWith(".png") ||
                          file.endsWith(".gif") ||
                          file.endsWith(".webp") ||
                          file.endsWith(".svg") ||
                          file.endsWith(".heif") ||
                          file.endsWith(".heic") ||
                          file.endsWith(".avif")
                      );

                      return (
                        <Link
                          to={`/property_details/${property.property_code}`}
                          key={index}
                          className="flex flex-col md:flex-row justify-center bg-[#eff2ef] hover:bg-[#e3e3e3] rounded-xl gap-6 outline-none border transition-all relative"
                        >
                          <img
                            src={imageFiles[0]}
                            alt="Rai&rao"
                            className="rounded-md h-64 object-cover w-full"
                          />
                          <div className="text-[#273238] p-2 flex flex-col justify-between gap-2">
                            <p className="font-normal tracking-[0.5px] uppercase text-base">
                              {/* {property.property_name} */}
                              {property.property_name?.length > 25
                                ? property.property_name.substring(0, 25) +
                                  "..."
                                : property.property_name}
                            </p>
                            <span className="font-medium text-base">
                              <span className="font-sans">₹</span>
                              {property.price}
                            </span>
                            {property.property_type === "3" && (
                              <div className="flex flex-wrap gap-3">
                                <p className="flex font-medium text-[12px] gap-1">
                                  <FaRegBuilding className="w-5 h-5 text-[#858585]" />
                                  {property.label === "1"
                                    ? "Agricultural"
                                    : property.label === "2"
                                    ? "Commercial"
                                    : property.label === "3"
                                    ? "Residential"
                                    : ""}
                                </p>
                                <p className="flex gap-2 font-medium text-[12px]">
                                  <TbRulerMeasure className="w-5 h-5 text-[#858585]" />
                                  {property.sqft}{" "}
                                  <span className="font-normal">
                                    {property.area_type === "1"
                                      ? "Acres"
                                      : property.area_type === "2"
                                      ? "Cents"
                                      : property.area_type === "3"
                                      ? "Sqft"
                                      : ""}
                                  </span>
                                </p>
                              </div>
                            )}
                          </div>

                          <div className="absolute top-2 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                            <p>
                              {property.category === "1"
                                ? "Buy"
                                : property.category === "2"
                                ? "Rent/Lease"
                                : ""}
                            </p>
                          </div>
                        </Link>
                      );
                    })}
                  </Slider>

                  <div className="block sm:hidden">
                    {plotData.length > 2 ? (
                      <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={"auto"}
                        initialSlide={1}
                        autoplay={true}
                        coverflowEffect={{
                          rotate: 50,
                          stretch: 0,
                          depth: 100,
                          modifier: 1,
                          slideShadows: true,
                        }}
                        modules={[EffectCoverflow, Pagination]}
                        className="mySwiper"
                      >
                        {plotData.map((property, index) => {
                          const imageFiles = property.file?.filter(
                            (file) =>
                              file.endsWith(".jpg") ||
                              file.endsWith(".jpeg") ||
                              file.endsWith(".png") ||
                              file.endsWith(".gif") ||
                              file.endsWith(".webp") ||
                              file.endsWith(".svg") ||
                              file.endsWith(".heif") ||
                              file.endsWith(".heic") ||
                              file.endsWith(".avif")
                          );

                          return (
                            <SwiperSlide key={index}>
                              <Link
                                to={`/property_details/${property.property_code}`}
                                key={index}
                                className="flex flex-col md:flex-row justify-center bg-[#eff2ef] hover:bg-[#e3e3e3] rounded-xl gap-6 outline-none border transition-all relative"
                              >
                                <img
                                  src={imageFiles[0]}
                                  alt="Rai&rao"
                                  className="rounded-md h-64 object-cover w-full"
                                />
                                <div className="text-[#273238] p-2 flex flex-col justify-between gap-2">
                                  <p className="font-normal tracking-[0.5px] uppercase text-base">
                                    {/* {property.property_name} */}
                                    {property.property_name?.length > 25
                                      ? property.property_name.substring(
                                          0,
                                          25
                                        ) + "..."
                                      : property.property_name}
                                  </p>
                                  <span className="font-medium text-base">
                                    <span className="font-sans">₹</span>
                                    {property.price}
                                  </span>
                                  {property.property_type === "3" && (
                                    <div className="flex flex-wrap gap-3">
                                      <p className="flex font-medium text-[12px] gap-1">
                                        <FaRegBuilding className="w-5 h-5 text-[#858585]" />
                                        {property.label === "1"
                                          ? "Agricultural"
                                          : property.label === "2"
                                          ? "Commercial"
                                          : property.label === "3"
                                          ? "Residential"
                                          : ""}
                                      </p>
                                      <p className="flex gap-2 font-medium text-[12px]">
                                        <TbRulerMeasure className="w-5 h-5 text-[#858585]" />
                                        {property.sqft}{" "}
                                        <span className="font-normal">
                                          {property.area_type === "1"
                                            ? "Acres"
                                            : property.area_type === "2"
                                            ? "Cents"
                                            : property.area_type === "3"
                                            ? "Sqft"
                                            : ""}
                                        </span>
                                      </p>
                                    </div>
                                  )}
                                </div>

                                <div className="absolute top-2 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                                  <p>
                                    {property.category === "1"
                                      ? "Buy"
                                      : property.category === "2"
                                      ? "Rent/Lease"
                                      : ""}
                                  </p>
                                </div>
                              </Link>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    ) : (
                      <Slider
                        {...{
                          ...settings,
                          infinite: false,
                        }}
                        className="my-slider gap-3 prop-slider"
                      >
                        {plotData.map((property, index) => {
                          const imageFiles = property.file?.filter(
                            (file) =>
                              file.endsWith(".jpg") ||
                              file.endsWith(".jpeg") ||
                              file.endsWith(".png") ||
                              file.endsWith(".gif") ||
                              file.endsWith(".webp") ||
                              file.endsWith(".svg") ||
                              file.endsWith(".heif") ||
                              file.endsWith(".heic") ||
                              file.endsWith(".avif")
                          );

                          return (
                            <Link
                              to={`/property_details/${property.property_code}`}
                              key={index}
                              className="flex flex-col md:flex-row justify-center bg-[#eff2ef] hover:bg-[#e3e3e3] rounded-xl gap-6 outline-none border transition-all relative"
                            >
                              <img
                                src={imageFiles[0]}
                                alt="Rai&rao"
                                className="rounded-md h-64 object-cover w-full"
                              />
                              <div className="text-[#273238] p-2 flex flex-col justify-between gap-2">
                                <p className="font-normal tracking-[0.5px] uppercase text-base">
                                  {/* {property.property_name} */}
                                  {property.property_name?.length > 25
                                    ? property.property_name.substring(0, 25) +
                                      "..."
                                    : property.property_name}
                                </p>
                                <span className="font-medium text-base">
                                  <span className="font-sans">₹</span>
                                  {property.price}
                                </span>
                                {property.property_type === "3" && (
                                  <div className="flex flex-wrap gap-3">
                                    <p className="flex font-medium text-[12px] gap-1">
                                      <FaRegBuilding className="w-5 h-5 text-[#858585]" />
                                      {property.label === "1"
                                        ? "Agricultural"
                                        : property.label === "2"
                                        ? "Commercial"
                                        : property.label === "3"
                                        ? "Residential"
                                        : ""}
                                    </p>
                                    <p className="flex gap-2 font-medium text-[12px]">
                                      <TbRulerMeasure className="w-5 h-5 text-[#858585]" />
                                      {property.sqft}{" "}
                                      <span className="font-normal">
                                        {property.area_type === "1"
                                          ? "Acres"
                                          : property.area_type === "2"
                                          ? "Cents"
                                          : property.area_type === "3"
                                          ? "Sqft"
                                          : ""}
                                      </span>
                                    </p>
                                  </div>
                                )}
                              </div>

                              <div className="absolute top-2 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                                <p>
                                  {property.category === "1"
                                    ? "Buy"
                                    : property.category === "2"
                                    ? "Rent/Lease"
                                    : ""}
                                </p>
                              </div>
                            </Link>
                          );
                        })}
                      </Slider>
                    )}
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center min-h-40 px-2">
                  <p>
                    No properties found. Don't worry, new listings are added
                    regularly. Stay tuned!
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* commercial */}
          <div className="w-full mb-10">
            <div className="w-full flex justify-between items-center mb-5 px-4">
              <h1 className="text-2xl md:text-2xl font-normal uppercase tracking-widest text-[#3C3450]">
                Commercial
              </h1>
              <Link to="/commercial">
                <button className="text-[#224c8f] hover:rounded-3xl px-4 py-1 hover:bg-gray-100 h-fit flex items-center gap-2">
                  Explore <FaArrowRightLong />
                </button>
              </Link>
            </div>

            <div className="px-4 sm:px-0 py-10">
              {/* <ComingSoonPage /> */}
              <div className="text-center p-3 font-afacad flex justify-center">
                <img src={ComingsoonImg} alt="" className="w-full md:w-1/2" />
                {/* <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-[#f59d2b] pb-5">
                  Coming Soon
                </h1>
                <p className="text-lg sm:text-2xl md:text-3xl font-medium text-[#263238]">
                  We're working hard to bring you something amazing. Stay tuned!
                </p> */}
              </div>
            </div>
            {/* <div className="px-4 sm:px-0">
              {commercialData.length > 0 ? (
                <Slider
                  {...{
                    ...settings,
                    infinite: false,
                  }}
                  className="gap-3"
                >
                  {commercialData.map((property, index) => {
                    const imageFiles = property.file?.filter(
                      (file) =>
                        file.endsWith(".jpg") ||
                        file.endsWith(".jpeg") ||
                        file.endsWith(".png") ||
                        file.endsWith(".gif") ||
                        file.endsWith(".webp") ||
                        file.endsWith(".svg") ||
                        file.endsWith(".heif") ||
                        file.endsWith(".heic") ||
                        file.endsWith(".avif")
                    );

                    return (
                      <Link
                        to={`/property_details/${property.property_code}`}
                        key={index}
                        className="flex justify-center bg-[#eff2ef] hover:bg-[#e3e3e3] rounded-xl gap-6 outline-none border transition-all relative"
                      >
                        <img
                          src={imageFiles[0]}
                          alt="Rai&rao"
                          className="rounded-md h-64 object-cover w-full"
                        />
                        <div className="text-[#273238] p-2 flex flex-col gap-2">
                          <p className="font-normal tracking-[0.5px] uppercase text-base">
                            {property.property_name?.length > 25
                              ? property.property_name.substring(0, 25) + "..."
                              : property.property_name}
                          </p>
                          <span className="font-medium text-base">
                            <span className="font-sans">₹</span>
                            {property.price}
                          </span>

                          <div className="flex gap-3">
                            <p className="flex font-medium text-[12px] gap-1">
                              <FaRegBuilding className="w-5 h-5 text-[#858585]" />
                              {property.label === "1"
                                ? "Industrial Building"
                                : property.label === "2"
                                ? "Industrial Shed"
                                : property.label === "3"
                                ? "Office Space"
                                : property.label === "4"
                                ? "Shop/Showroom"
                                : property.label === "5"
                                ? "Warehouse/Godown"
                                : ""}
                            </p>

                            <p className="flex font-medium text-[12px] gap-1">
                              <TbRulerMeasure className="w-5 h-5 text-[#858585]" />
                              {property.sqft}{" "}
                              <span className="font-normal text-[12px]">
                                {property.area_type === "1"
                                  ? "Acres"
                                  : property.area_type === "2"
                                  ? "Cents"
                                  : property.area_type === "3"
                                  ? "Sqft"
                                  : ""}
                              </span>
                            </p>

                            {property.commercialInfo.terrace !== null &&
                              property.commercialInfo.terrace !== "" &&
                              property.commercialInfo?.terrace !== "null" && (
                                <p className="flex font-medium text-[12px] gap-1">
                                  <img
                                    src={Garden}
                                    className="w-5 h-5 text-[#dadada]"
                                  />
                                  {property.commercialInfo.terrace !== null &&
                                  property.commercialInfo.terrace !== "" &&
                                  property.commercialInfo?.terrace !== "null"
                                    ? property.commercialInfo.terrace + " Sqft"
                                    : ""}
                                </p>
                              )}
                          </div>
                        </div>

                        <div className="absolute top-2 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                          <p>
                            {property.category === "1"
                              ? "Buy"
                              : property.category === "2"
                              ? "Rent/Lease"
                              : ""}
                          </p>
                        </div>
                      </Link>
                    );
                  })}
                </Slider>
              ) : (
                <div className="flex justify-center items-center min-h-40 px-2">
                  <p>
                    No properties found. Don't worry, new listings are added
                    regularly. Stay tuned!
                  </p>
                </div>
              )}
            </div> */}
          </div>

          <div className="w-full mb-10">
            <div className="w-full flex justify-between items-center mb-5 px-4">
              <h1 className="text-lg md:text-2xl font-normal uppercase tracking-wide md:tracking-widest text-[#3C3450]">
                we are looking for
              </h1>
              <Link to="/looking_for/1">
                <button className="text-[#224c8f] hover:rounded-3xl px-4 py-1 hover:bg-gray-100 h-fit flex items-center gap-2">
                  Explore <FaArrowRightLong />
                </button>
              </Link>
            </div>

            <div className="px-4 sm:px-0">
              {buyersData.data?.length > 0 ? (
                <>
                  <Slider
                    {...{
                      ...lookup_settings,
                    }}
                    className="gap-3 prop-slider lookup-slider"
                  >
                    {buyersData.data?.map((look_up, i) => {
                      return (
                        <div
                          key={i}
                          className="bg-[#f5f5f5] hover:bg-[#eeeeee] p-3 rounded-xl gap-2 justify-center md:items-center sm:h-[280px] md:h-[250px] lg:h-[207px] xl:h-[180px] relative"
                        >
                          <div className="w-full flex flex-col sm:h-[243px] md:h-[213px] lg:h-[175px] xl:h-[148px]">
                            <p className="text-right text-xs text-blue-gray-300 absolute top-3 right-3">
                              {moment(
                                look_up.publish_date,
                                "MMM DD, YYYY h:mm a"
                              ).format("DD/MM/YYYY h.mmA")}
                            </p>
                            <p
                              className="text-sm sm:text-lg font-afacad py-6"
                              style={{ wordBreak: "break-word" }}
                            >
                              {look_up.description}
                            </p>
                            <div className="flex justify-start items-center gap-2 absolute bottom-3">
                              <p className="text-xs bg-green-50 text-green-600 px-3 py-[2px] rounded mt-1">
                                {look_up.property_type == 1
                                  ? "Residential"
                                  : look_up.property_type == 2
                                  ? "Commercial"
                                  : look_up.property_type == 3
                                  ? "Land/Plot"
                                  : ""}
                              </p>
                              <p className="text-xs bg-green-50 text-green-600 px-3 py-[2px] rounded mt-1">
                                {look_up.category == 1
                                  ? "Buy"
                                  : look_up.category == 2
                                  ? "Rent/Lease"
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>

                  <div className="block sm:hidden">
                    {buyersData.data.length > 2 ? (
                      <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={"auto"}
                        autoplay={true}
                        initialSlide={1}
                        coverflowEffect={{
                          rotate: 50,
                          stretch: 0,
                          depth: 100,
                          modifier: 1,
                          slideShadows: true,
                        }}
                        modules={[EffectCoverflow, Pagination]}
                        className="mySwiper"
                      >
                        {buyersData.data?.map((look_up, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <div
                                key={index}
                                className="bg-[#f5f5f5] hover:bg-[#eeeeee] p-3 rounded-xl gap-2 justify-center md:items-center sm:h-[unset] relative"
                              >
                                <div className="w-full flex flex-col items-cente justify-center h-[220px] sm:h-[unset]">
                                  <p className="text-right text-xs text-blue-gray-300 absolute top-3 right-3">
                                    {moment(
                                      look_up.publish_date,
                                      "MMM DD, YYYY h:mm a"
                                    ).format("DD/MM/YYYY h.mmA")}
                                  </p>
                                  <p
                                    className="text-sm sm:text-lg font-afacad"
                                    style={{ wordBreak: "break-word" }}
                                  >
                                    {look_up.description}
                                  </p>
                                  <div className="flex justify-start items-center gap-2 absolute bottom-3">
                                    <p className="text-xs bg-green-50 text-green-600 px-3 py-[2px] rounded mt-1">
                                      {look_up.property_type == 1
                                        ? "Residential"
                                        : look_up.property_type == 2
                                        ? "Commercial"
                                        : look_up.property_type == 3
                                        ? "Land/Plot"
                                        : ""}
                                    </p>
                                    <p className="text-xs bg-green-50 text-green-600 px-3 py-[2px] rounded mt-1">
                                      {look_up.category == 1
                                        ? "Buy"
                                        : look_up.category == 2
                                        ? "Rent/Lease"
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    ) : (
                      <Slider
                        {...{
                          ...settings,
                          infinite: false,
                        }}
                        className="my-slider gap-3 prop-slider"
                      >
                        {buyersData.data?.map((look_up, i) => {
                          return (
                            <div
                              key={i}
                              className="bg-[#f5f5f5] hover:bg-[#eeeeee] p-3 rounded-xl gap-2 justify-center md:items-center sm:h-[unset] relative"
                            >
                              <div className="w-full flex flex-col h-[240px] sm:h-[unset] card-size">
                                <p className="text-right text-xs text-blue-gray-300 absolute top-3 right-3">
                                  {moment(
                                    look_up.publish_date,
                                    "MMM DD, YYYY h:mm a"
                                  ).format("DD/MM/YYYY h.mmA")}
                                </p>
                                <p
                                  className="text-sm sm:text-lg font-afacad py-6"
                                  style={{ wordBreak: "break-word" }}
                                >
                                  {look_up.description}
                                </p>
                                <div className="flex justify-start items-center gap-2 absolute bottom-3">
                                  <p className="text-xs bg-green-50 text-green-600 px-3 py-[2px] rounded mt-1">
                                    {look_up.property_type == 1
                                      ? "Residential"
                                      : look_up.property_type == 2
                                      ? "Commercial"
                                      : look_up.property_type == 3
                                      ? "Land/Plot"
                                      : ""}
                                  </p>
                                  <p className="text-xs bg-green-50 text-green-600 px-3 py-[2px] rounded mt-1">
                                    {look_up.category == 1
                                      ? "Buy"
                                      : look_up.category == 2
                                      ? "Rent/Lease"
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    )}
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center min-h-40 px-2 text-center">
                  <p>
                    No data found. Don't worry, new listings are added
                    regularly. Stay tuned!
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* home page overview */}
          {overviewData?.description && (
            <>
              <div className="flex items-center pt-0 px-4 mt-3">
                <h1 className="text-[16px] md:text-[25px] font-normal text-[#7a7c7d] mr-3 font-afacad uppercase">
                  Rao and Rai Real Estate Mangalore
                </h1>
                <Divider
                  className="flex-1 text-[#b0bec5]"
                  style={{ borderBottomWidth: "2px" }}
                />
              </div>
              <div className="font-afacad description-count pt-2 px-4 pl-4">
                {/* <p className="font-afacad text-3xl font-semibold pb-2 sm:pb-4">
                  Overview
                </p> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: overviewData?.description,
                  }}
                  className="pb-4 inner-html-custom"
                ></div>
              </div>
            </>
          )}
        </div>
      </StickyNavbar>
      <UserFooter />

      <ContactModal
        open={modal.contact_modal}
        onClose={() => setModal({ ...modal, contact_modal: false })}
      />
    </div>
  );
}

export default Dashboard;
