import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { Card, Input, Button, Typography } from "@material-tailwind/react";
import { Textarea } from "@material-tailwind/react";
import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";
import { postServices } from "../../../apiServices/apiServices";

function ContactModal(props) {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
    width: "90%",
    maxWidth: "500px",
    "@media (max-width: 600px)": {
      width: "100%",
      // left: "0",
      // transform: "none",
      // padding: "15px",
    },
  };

  const validate = () => {
    let tempErrors = {};
    if (!name) {
      tempErrors.name = "Name is required";
    } else if (!/^[a-zA-Z\s]+$/.test(name)) {
      tempErrors.name = "Name can only contain letters and spaces";
    }
    if (!mobile) {
      tempErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(mobile)) {
      tempErrors.mobile = "Invalid mobile number";
    }
    if (email && !/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = "Email is not valid";
    }
    if (!message) {
      tempErrors.message = "Message is required";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setName(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      // form submission
      try {
        const res = await postServices(`/api/contact/admin`, {
          name: name,
          phone: mobile,
          email: email,
          body: message,
        });
        if (res.responseCode === 200) {
          props.onClose();
          setName("");
          setMessage("");
          setEmail("");
          setMobile("");
          toast.success("Your message sent...");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-4">
              <p className="font-semibold">
                {/* {props.modal?.edit ? "Update" : "Add"} HSN Code */}
              </p>
              {/* <IoClose
                className="cursor-pointer border border-[#000] rounded-full"
                onClick={props.onClose}
              /> */}
            </div>
            <Card color="transparent" shadow={false}>
              <Typography
                variant="h4"
                color="blue-gray"
                className="font-poppins"
              >
                Hello!
              </Typography>
              <Typography
                color="gray"
                className="mt-1 font-normal font-poppins"
              >
                Our team is happy to help. Drop us a message and we will get
                back to you soon.
              </Typography>

              <form
                className="mt-5 mb-2 w-full max-w-screen-lg sm:w-full"
                onSubmit={handleSubmit}
              >
                <div className="mb-1 flex flex-col gap-4">
                  <Input
                    size="lg"
                    label="Your Name"
                    style={{ fontFamily: "Poppins" }}
                    value={name}
                    onChange={handleNameChange}
                    error={errors.name ? true : false}
                    required
                  />
                  <Input
                    size="lg"
                    label="Your Mobile Number"
                    type="number"
                    style={{ fontFamily: "Poppins" }}
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    error={errors.mobile ? true : false}
                    required
                  />
                  <Input
                    size="lg"
                    label="Email (optional)"
                    style={{ fontFamily: "Poppins" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={errors.email ? true : false}
                  />
                  <Textarea
                    label="Message"
                    style={{ fontFamily: "Poppins" }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    error={errors.message ? true : false}
                    // required
                  />

                  <ReCAPTCHA
                    sitekey="6LeOogkqAAAAANu2z5lU7UCu8gkbXekxVSSvL8by"
                    // onChange={(e) => console.log(e)}
                    className="w-36"
                  />
                </div>

                <div className="flex items-center gap-4 mt-2">
                  <Button
                    type="button"
                    className="w-full font-poppins tracking-wider bg-white text-black"
                    onClick={props.onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="w-full font-poppins tracking-wider"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ContactModal;
