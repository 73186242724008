import React, { useEffect, useState } from "react";
import { StickyNavbar } from "../../components/UserNavbar";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import LoadingGif from "../../assets/loader-0.02.gif";
import moment from "moment";
import { Pagination } from "@mui/material";
import toast from "react-hot-toast";
import { getServices } from "../../apiServices/apiServices";
import UserFooter from "../../components/UserFooter";
import { Option, Select } from "@material-tailwind/react";
import { MdPhone } from "react-icons/md";

const property_type = [
  { label: "None", value: "" },
  { label: "Residential", value: "1" },
  { label: "Commercial", value: "2" },
  { label: "Land/Plot", value: "3" },
];
const property_category = [
  { label: "None", value: "" },
  { label: "Buy", value: "1" },
  { label: "Rent/Lease", value: "2" },
];
const sort = [
  { label: "Oldest", value: "1" },
  { label: "Newest", value: "2" },
];

function LookingForList() {
  const [buyersData, setBuyersData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState({
    p_type: "",
    p_category: "",
    sort: "1",
  });
  const { page } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchBuyersData(true);
  }, [page, selectedItems]);

  const fetchBuyersData = async (load_bool) => {
    setLoading(load_bool);

    const type =
      selectedItems.p_type === "1"
        ? "&property_type=1"
        : selectedItems.p_type === "2"
        ? "&property_type=2"
        : selectedItems.p_type === "3"
        ? "&property_type=3"
        : "";
    const category =
      selectedItems.p_category === "1"
        ? "&category=1"
        : selectedItems.p_category === "2"
        ? "&category=2"
        : "";
    const sortt =
      selectedItems.sort === "1"
        ? "&sort=1"
        : selectedItems.sort === "2"
        ? "&sort=2"
        : "";

    const url = `/api/lookup/list_lookup?limit=10&page=${page}${category}${type}${sortt}`;

    try {
      const res = await getServices(url);
      setLoading(false);
      if (res.responseCode === 200) {
        setBuyersData(res.responseData);
      } else {
        setBuyersData([]);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Looking For - Rao and Rai Real Estate Mangalore</title>
      </Helmet>
      <StickyNavbar>
        <div className="mt-6 md:mt-2 max-w-[1300px] mx-auto px-3">
          <p className="text-2xl sm:text-3xl font-afacad">We are looking for</p>

          <div className="flex flex-col md:flex-row justify-end md:items-center gap-2 mb-3">
            <div className="flex flex-col sm:flex-row gap-2 mt-2">
              <Select
                label="Property Type"
                value={property_type[0].value}
                style={{ fontFamily: "Poppins" }}
                onChange={(e) => {
                  setSelectedItems({ ...selectedItems, p_type: e });
                }}
              >
                {property_type.map((item, i) => (
                  <Option
                    value={item.value}
                    style={{ fontFamily: "Poppins" }}
                    key={i}
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
              <Select
                label="Property Category"
                value={property_category[0].value}
                style={{ fontFamily: "Poppins" }}
                onChange={(e) => {
                  setSelectedItems({ ...selectedItems, p_category: e });
                }}
              >
                {property_category.map((item, i) => (
                  <Option
                    value={item.value}
                    style={{ fontFamily: "Poppins" }}
                    key={i}
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
              <Select
                label="Sort"
                value={sort[0].value}
                style={{ fontFamily: "Poppins" }}
                onChange={(e) => {
                  setSelectedItems({ ...selectedItems, sort: e });
                }}
              >
                {sort.map((item, i) => (
                  <Option
                    value={item.value}
                    style={{ fontFamily: "Poppins" }}
                    key={i}
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          {!loading ? (
            <>
              {buyersData.data?.length > 0 ? (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                  {buyersData.data?.map((item, i) => (
                    <div
                      key={i}
                      className="bg-white p-3 rounded-xl flex gap-2 md:items-cente drop-shadow-xl relative"
                    >
                      {/* <img
                        src={item.image ?? LookingDefault}
                        alt=""
                        className="w-[100px] min-w-[100px] h-[100px] object-cover rounded-full"
                      /> */}
                      <div className="w-full">
                        <p className="text-right text-xs text-blue-gray-300 absolute top-3 right-3">
                          {moment(
                            item.publish_date,
                            "MMM DD, YYYY h:mm a"
                          ).format("DD/MM/YYYY h.mmA")}
                        </p>
                        <p
                          className="text-sm sm:text-lg font-afacad py-6"
                          style={{ wordBreak: "break-word" }}
                        >
                          {item.description}
                        </p>
                        <div className="flex justify-start items-center gap-2 absolute bottom-3">
                          <p className="text-xs bg-green-50 text-green-600 px-3 py-[2px] rounded mt-1">
                            {item.property_type == 1
                              ? "Residential"
                              : item.property_type == 2
                              ? "Commercial"
                              : item.property_type == 3
                              ? "Land/Plot"
                              : ""}
                          </p>
                          <p className="text-xs bg-green-50 text-green-600 px-3 py-[2px] rounded mt-1">
                            {item.category == 1
                              ? "Buy"
                              : item.category == 2
                              ? "Rent/Lease"
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full flex flex-col justify-center items-center gap-2 min-h-80 text-center">
                  <p className="text-xl font-semibold">Oops! No Data Found</p>
                  <p>
                    Looks like we couldn't find any data that match your search
                    right now. But don't worry, we're here to help you find it!
                  </p>
                  <a
                    href="tel:+918277268334"
                    className="flex items-center bg-[#5e78a3] text-white px-3 py-1 rounded-full font-semibold"
                  >
                    <MdPhone className="mr-2" />
                    Call Now
                  </a>
                  <p>
                    In the meantime, you can{" "}
                    <span className="font-semibold">tweak your filters</span>{" "}
                    and give it another shot.
                  </p>
                </div>
              )}
            </>
          ) : (
            <div className="flex justify-center items-center h-[50vh]">
              <img src={LoadingGif} alt="" className="h-20" />
            </div>
          )}

          {buyersData.data?.length > 0 && (
            <div className="flex items-center justify-center gap-4 my-4">
              <div className="flex items-center justify-center gap-2">
                <Pagination
                  count={buyersData.totalPages}
                  defaultPage={Number(page)}
                  siblingCount={0}
                  boundaryCount={1}
                  //   onChange={(e, page) => handlePaginationChange(e, page)}
                  sx={{
                    fontFamily: "Poppins",
                    ".MuiPaginationItem-root": {
                      fontFamily: "Poppins",
                      borderRadius: "8px",
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </StickyNavbar>
      <UserFooter />
    </div>
  );
}

export default LookingForList;
