import React from "react";
import Comingsoongif from "../assets/Telescope transitioning to a rocket.gif";
import ComingsoonImg from "../assets/coming soon.jpg";

function ComingSoonPage() {
  return (
    <div className="flex items-center justify-center p-4 sm:p-6 md:p-8">
      <div className="grid grid-cols-1 gap-4 items-center font-afacad">
        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center font-afacad"> */}
        <div className="flex justify-center">
          <img
            src={ComingsoonImg}
            alt="Ra0&rai"
            className="w-full h-auto"
          />
        </div>
        {/* <div className="text-center md:text-left p-3">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-[#f59d2b] pb-5">
            Coming Soon
          </h1>
          <p className="text-lg sm:text-2xl md:text-3xl font-medium text-[#263238]">
            We're working hard to bring you something amazing. Stay tuned!
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default ComingSoonPage;
