import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { MdDelete, MdOutlineEdit } from "react-icons/md";
import BannerModal from "../../components/modal/admin/BannerModal";
import toast from "react-hot-toast";
import {
  deleteServices,
  getServices,
  postServices,
  putServices,
} from "../../apiServices/apiServices";
import CountUp from "react-countup";
import StatsModal from "../../components/modal/admin/StatsModal";

function AdminBannerCounterPage() {
  const [modal, setModal] = useState({
    add_banner: false,
    update_stats: false,
  });
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [bannerList, setBannerList] = useState([]);
  const [counterValue, setCounterValue] = useState([]);
  const [fieldStatsData, setFieldStatsData] = useState({
    count: "",
    count_id: "",
    count_desc: "",
  });
  const access_token = sessionStorage.getItem("land-trandes-access-token");

  useEffect(() => {
    fetchBannerList();
    fetchCounterData();
  }, []);

  const fetchBannerList = async () => {
    try {
      const res = await getServices(`/api/banner/list`);
      if (res.responseCode === 200) {
        setBannerList(res.responseData);
      } else {
        toast.error(res.responseMessage);
        setBannerList([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchCounterData = async () => {
    try {
      const res = await getServices(`/api/stats/list`);
      if (res.responseCode === 200) {
        setCounterValue(res.responseData);
      } else {
        setCounterValue([]);
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleAddBannerModal = () => {
    setModal({ ...modal, add_banner: true });
    setSelectedImage(null);
    setPreview(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (img.width !== 1920 || img.height !== 960) {
            alert("The image must have a resolution of 1920x960 pixels.");
            setSelectedImage(null);
            setPreview(null);
          } else {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = 1920;
            canvas.height = 960;
            ctx.drawImage(img, 0, 0, 1920, 960);
            const resizedImage = canvas.toDataURL("image/png");
            setPreview(resizedImage);
            setSelectedImage(file); // Keep the original image file
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (selectedImage) {
      const formData = new FormData();

      formData.append("image", selectedImage);

      try {
        const res = await postServices(
          `/api/banner/add`,
          formData,
          access_token
        );
        setLoading(false);
        if (res.responseCode === 200) {
          setModal({ ...modal, add_banner: false });
          fetchBannerList();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    } else {
      alert("Please select a Image...");
      setLoading(false);
    }
  };

  const handleDeleteBanner = async (banner_id) => {
    if (window.confirm("Do you want to delete this banner?")) {
      try {
        const res = await deleteServices(
          `/api/banner/delete?imageid=${banner_id}`
        );
        if (res.responseCode === 200) {
          fetchBannerList();
          toast.success("Banner deleted...");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleUpdateStats = async (stats_data) => {
    setModal({ ...modal, update_stats: true });
    setFieldStatsData({
      ...fieldStatsData,
      count: stats_data.count,
      count_id: stats_data.stats_id,
      count_desc: stats_data.desc,
    });
  };

  const handleUpdateStatsSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await putServices(
        `/api/stats/update?statsid=${fieldStatsData.count_id}`,
        { count: fieldStatsData.count },
        access_token
      );
      if (res.responseCode === 200) {
        fetchCounterData();
        setModal({ ...modal, update_stats: false });
        setLoading(false);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-2xl sm:text-4xl">Manage Counter</p>
        </div>
        {counterValue.length > 0 ? (
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-3 mb-6">
            {counterValue.map((counter, i) => (
              <div
                key={i}
                className="w-full relative hover:drop-shadow-xl transition-all bg-white p-4 rounded-xl"
              >
                <CountUp
                  className="text-3xl md:text-6xl font-semibold flex justify-center items-center"
                  end={counter.count}
                  decimals={i === counterValue.length - 1 ? 2 : 0}
                />
                <p className="text-xs md:text-base text-[#857d98] text-center">
                  {counter.desc}
                </p>
                <div
                  className="absolute top-2 right-3 bg-blue-gray-50 rounded-full hover:drop-shadow-lg cursor-pointer"
                  onClick={() => handleUpdateStats(counter)}
                >
                  <MdOutlineEdit className="text-2xl p-1" />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full h-[30vh] flex justify-center items-center font-semibold">
            <p>No Counter Found...</p>
          </div>
        )}

        <div className="flex justify-between mb-3">
          <p className="font-semibold text-2xl sm:text-4xl">Manage Banner</p>
          <Button
            onClick={handleAddBannerModal}
            className="font-semibold"
            style={{ fontFamily: "Poppins" }}
          >
            Add Banner
          </Button>
        </div>
        {bannerList.length > 0 ? (
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-3">
            {bannerList.map((banner, i) => (
              <div
                key={i}
                className="w-full relative hover:drop-shadow-xl transition-all"
              >
                <img
                  src={banner.image}
                  alt=""
                  className="h-52 w-full rounded-lg object-cover"
                />
                <div
                  className="absolute top-2 right-3 bg-white rounded-full hover:drop-shadow-lg cursor-pointer"
                  onClick={() => handleDeleteBanner(banner.banner_id)}
                >
                  <MdDelete className="text-2xl p-1" />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full h-[30vh] flex justify-center items-center font-semibold">
            <p>No Banner Found...</p>
          </div>
        )}
      </div>

      <BannerModal
        open={modal.add_banner}
        onClose={() => setModal({ ...modal, add_banner: false })}
        handleFileChange={handleFileChange}
        preview={preview}
        handleBannerSubmit={handleBannerSubmit}
        loading={loading}
      />
      <StatsModal
        open={modal.update_stats}
        onClose={() => setModal({ ...modal, update_stats: false })}
        fieldStatsData={fieldStatsData}
        setFieldStatsData={setFieldStatsData}
        handleUpdateStatsSubmit={handleUpdateStatsSubmit}
        loading={loading}
      />
    </>
  );
}

export default AdminBannerCounterPage;
