import { Button, Input } from "@material-tailwind/react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React from "react";
import { IoClose } from "react-icons/io5";
import WarningModal from "./WarningModal";

function ImageModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <p className="font-semibold">Add Image/Video</p>
              {!props.loading && (
                <IoClose
                  className="cursor-pointer border border-[#000] rounded-full"
                  onClick={props.onClose}
                />
              )}
            </div>
            <form
              onSubmit={props.handleAddFileSubmit}
              className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3"
            >
              <Input
                label="Choose image"
                type="file"
                style={{ fontFamily: "Poppins" }}
                multiple
                onChange={props.handleFileChange}
                accept="image/png, image/jpeg, image/jpg, image/tiff, image/tif, image/svg+xml, image/webp, image/heif, image/heic, image/avif, video/mp4"
                required
              />
              <p className="text-red-500 text-center text-sm mt-2">
                .jpg, .jpeg, .png, .heic, .heif, .tiff, .tif, .svg, .webp,
                .avif, .mp4.Maximum file size is 5MB for images and 200MB for
                videos. *
              </p>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {props.fieldSelectedFiles.map((file, index) => {
                  const url = URL.createObjectURL(file);
                  return (
                    <div
                      key={index}
                      style={{ margin: "10px", position: "relative" }}
                    >
                      {file.type.startsWith("image/") ? (
                        <img
                          src={url}
                          alt={`preview ${index}`}
                          style={{
                            width: "120px",
                            height: "80px",
                            objectFit: "cover",
                          }}
                          className="border border-gray-500 rounded-md"
                        />
                      ) : (
                        <video
                          src={url}
                          //   controls
                          style={{
                            width: "120px",
                            height: "80px",
                            objectFit: "cover",
                          }}
                          className="border border-gray-500 rounded-md"
                        />
                      )}
                      <button
                        type="button"
                        onClick={() => props.handleRemoveUpdateFile(index)}
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          cursor: "pointer",
                          fontSize: "12px",
                          padding: "0 5px",
                        }}
                      >
                        X
                      </button>
                    </div>
                  );
                })}
              </div>
              {/* {props.preview && (
                <div>
                  <img
                    src={props.preview}
                    alt=""
                    className="w-24 h-24 rounded-lg object-cover"
                  />
                </div>
              )} */}
              <Button type="submit" disabled={props.loading}>
                {props.loading ? "Loading" : "Save"}
              </Button>
            </form>
          </Box>
        </Fade>
      </Modal>

      <WarningModal
        open={props.warning_modal_open}
        title="Abort Operation"
        description="Are you sure you want to cancel this operation?"
        onClose={props.warning_modal_close}
        yesBtnClick={props.warning_modal_yes_btn}
      />
    </div>
  );
}

export default ImageModal;
