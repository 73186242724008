import { Button, Input } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { postServices } from "../../apiServices/apiServices";
import { LuEye, LuEyeOff } from "react-icons/lu";

function AdminLogin() {
  const navigate = useNavigate();
  const [fieldData, setFieldData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("land-trandes-access-token")) {
      navigate("/admin/dashboard");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await postServices(`/api/auth/login`, fieldData);
      setLoading(false);
      if (res.responseCode === 200) {
        sessionStorage.setItem(
          "land-trandes-access-token",
          res.responseData.access_token
        );
        navigate("/admin/dashboard");
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="flex justify-center items-center w-screen h-screen bg-blue-gray-50">
      <div className="bg-white rounded-lg p-5" onSubmit={handleSubmit}>
        <form className="w-[300px] sm:w-[400px] flex flex-col gap-4">
          <h1 className="text-3xl font-semibold">Login...</h1>
          <Input
            label="Username"
            type="email"
            value={fieldData.email}
            onChange={(e) =>
              setFieldData({ ...fieldData, email: e.target.value })
            }
            required
            style={{ fontFamily: "Poppins" }}
          />
          <Input
            label="Password"
            type={isPasswordVisible ? "text" : "password"}
            value={fieldData.password}
            onChange={(e) =>
              setFieldData({ ...fieldData, password: e.target.value })
            }
            style={{ fontFamily: "Poppins" }}
            icon={
              isPasswordVisible ? (
                <LuEyeOff onClick={togglePasswordVisibility} />
              ) : (
                <LuEye onClick={togglePasswordVisibility} />
              )
            }
            required
          />
          <Button
            type="submit"
            style={{ fontFamily: "Poppins" }}
            className="font-semibold disabled:cursor-not-allowed"
            disabled={loading ? true : false}
          >
            {!loading ? "Submit" : "Loading..."}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default AdminLogin;
