import {
  Button,
  IconButton,
  Input,
  Option,
  Select,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { FaRegBuilding } from "react-icons/fa6";
import { TbRulerMeasure } from "react-icons/tb";
import { SlPencil } from "react-icons/sl";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { deleteServices, getServices } from "../../apiServices/apiServices";
import Garden from "../../assets/garden.svg";

const property_type = [
  { label: "None", value: "" },
  { label: "Residential", value: "1" },
  { label: "Commercial", value: "2" },
  { label: "Land/Plot", value: "3" },
];
const property_category = [
  { label: "None", value: "" },
  { label: "Buy", value: "1" },
  { label: "Rent/Lease", value: "2" },
];

function AdminDashboard() {
  const [propertyList, setPropertyList] = useState([]);
  const [selectedItems, setSelectedItems] = useState({
    p_type: "0",
    p_category: "0",
  });
  const [fieldData, setFieldData] = useState({ search: "" });
  const navigate = useNavigate();
  const access_token = sessionStorage.getItem("land-trandes-access-token");

  useEffect(() => {
    fetchPropertyList();
  }, [selectedItems]);

  const fetchPropertyList = async () => {
    const type =
      selectedItems.p_type === "1"
        ? "&property_type=1"
        : selectedItems.p_type === "2"
        ? "&property_type=2"
        : selectedItems.p_type === "3"
        ? "&property_type=3"
        : "";
    const category =
      selectedItems.p_category === "1"
        ? "&category=1"
        : selectedItems.p_category === "2"
        ? "&category=2"
        : "";

    const url = `/api/property/list_property?${type}${category}`;

    try {
      const res = await getServices(url);
      if (res.responseCode === 200) {
        setPropertyList(res.responseData);
      } else {
        toast.error(res.responseMessage);
        setPropertyList([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleAddProperty = () => {
    navigate("/admin/add_property");
  };

  const handleDeleteProperty = async (id) => {
    if (window.confirm("Do you want delete this property?")) {
      try {
        const res = await deleteServices(
          `/api/property/delete_property?property_id=${id}`,
          access_token
        );
        if (res.responseCode === 200) {
          fetchPropertyList();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handlePropertySearch = async (e) => {
    setFieldData({ ...fieldData, search: e.target.value });
    if (e.target.value.length > 0) {
      try {
        const res = await getServices(
          `/api/property/search_property/${e.target.value}`,
          access_token
        );
        if (res.responseCode === 200) {
          setPropertyList(res.responseData);
        } else if (res.responseCode === 205) {
          toast.error("No Result Found...");
          setPropertyList([]);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      fetchPropertyList();
    }
  };

  const handleUpdateProperty = (property_data) => {
    navigate("/admin/update_property", {
      state: { property_data: property_data },
    });
  };

  return (
    <div className="p-4">
      <div className="flex justify-between mb-3">
        <p className="font-semibold text-4xl">Manage Properties</p>
        <Button
          onClick={handleAddProperty}
          className="font-semibold"
          style={{ fontFamily: "Poppins" }}
        >
          Add Property
        </Button>
      </div>
      <div className="flex flex-col md:flex-row justify-between md:items-center gap-2">
        <div>
          <Input
            label="Search Property"
            className="w-fit"
            onChange={handlePropertySearch}
            style={{ fontFamily: "Poppins" }}
            value={fieldData.search}
          />
        </div>
        <div className="flex flex-col sm:flex-row gap-2">
          <Select
            label="Property Type"
            value={property_type[0].value}
            style={{ fontFamily: "Poppins" }}
            onChange={(e) => {
              setFieldData({ ...fieldData, search: "" });
              setSelectedItems({ ...selectedItems, p_type: e });
            }}
          >
            {property_type.map((item, i) => (
              <Option
                value={item.value}
                style={{ fontFamily: "Poppins" }}
                key={i}
              >
                {item.label}
              </Option>
            ))}
          </Select>
          <Select
            label="Property Category"
            value={property_category[0].value}
            style={{ fontFamily: "Poppins" }}
            onChange={(e) => {
              setFieldData({ ...fieldData, search: "" });
              setSelectedItems({ ...selectedItems, p_category: e });
            }}
          >
            {property_category.map((item, i) => (
              <Option
                value={item.value}
                style={{ fontFamily: "Poppins" }}
                key={i}
              >
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      {propertyList.length > 0 ? (
        <div className="mt-3 grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
          {propertyList?.map((card, index) => {
            const imageFiles = card.file?.filter(
              (file) =>
                file.endsWith(".jpg") ||
                file.endsWith(".jpeg") ||
                file.endsWith(".png") ||
                file.endsWith(".gif") ||
                file.endsWith(".webp") ||
                file.endsWith(".svg") ||
                file.endsWith(".heif") ||
                file.endsWith(".heic") ||
                file.endsWith(".avif")
            );

            return (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer relative"
              >
                <img
                  src={imageFiles[0]}
                  alt="Rai&rao"
                  className="w-full h-64 object-cover rounded-lg"
                />
                <div
                  className="p-4"
                  onClick={() =>
                    navigate(`/admin/property_details/${card.property_code}`)
                  }
                >
                  <h3 className="text-[16px] font-normal tracking-[0.5px] uppercase mb-2 text-[#263238]">
                    {card.property_name?.length > 32
                      ? card.property_name.substring(0, 32) + "..."
                      : card.property_name}
                  </h3>
                  <h3 className="text-[16px] font-medium mb-2">
                    <span className="font-sans">₹</span>
                    {card.price}
                  </h3>
                  {card.residentialInfo && (
                    <div className="flex gap-4">
                      <p className="flex font-medium text-[12px] gap-1">
                        <FaRegBuilding className="w-5 h-5 text-[#dadada]" />
                        {card.label === "1"
                          ? "1BHK"
                          : card.label === "2"
                          ? "2BHK"
                          : card.label === "3"
                          ? "3BHK"
                          : card.label === "4"
                          ? "3BHK+"
                          : ""}
                      </p>
                      <p className="flex font-medium text-[12px] gap-1">
                        <TbRulerMeasure className="w-5 h-5 text-[#dadada]" />
                        {card.sqft}{" "}
                        <span className="font-normal text-[12px]">
                          {card.area_type === "1"
                            ? "Acres"
                            : card.area_type === "2"
                            ? "Cents"
                            : card.area_type === "3"
                            ? "Sqft"
                            : ""}
                        </span>
                      </p>
                      {card.residentialInfo.terrace !== null &&
                        card.residentialInfo.terrace !== "" &&
                        card.residentialInfo?.terrace !== "null" && (
                          <p className="flex font-medium text-[12px] gap-1">
                            <img
                              src={Garden}
                              className="w-5 h-5 text-[#dadada]"
                            />
                            {/* {card.residentialInfo.terrace}{" "} */}
                            {/* {card.residentialInfo.terrace !== null &&
                            card.residentialInfo.terrace !== "" &&
                            card.residentialInfo?.terrace !== "null"
                              ? card.residentialInfo.terrace + " Sqft"
                              : ""} */}
                            {card.residentialInfo.terrace !== null &&
                            card.residentialInfo.terrace !== "" &&
                            card.residentialInfo?.terrace !== "null"
                              ? card.residentialInfo.terrace +
                                " " +
                                (card.residentialInfo.terrace_type === "1"
                                  ? "Acres"
                                  : card.residentialInfo.terrace_type === "2"
                                  ? "Cents"
                                  : card.residentialInfo.terrace_type === "3"
                                  ? "Sqft"
                                  : "")
                              : ""}
                            {/* <span className="font-normal text-[12px]">Sqft</span> */}
                          </p>
                        )}
                    </div>
                  )}
                  {card.commercialInfo && (
                    <div className="flex gap-4">
                      <p className="flex font-medium text-[12px] gap-1">
                        <FaRegBuilding className="w-5 h-5 text-[#dadada]" />
                        {card.label === "1"
                          ? "Industrial Building"
                          : card.label === "2"
                          ? "Industrial Shed"
                          : card.label === "3"
                          ? "Office Space"
                          : card.label === "4"
                          ? "Shop/Showroom"
                          : card.label === "5"
                          ? "Warehouse/Godown"
                          : ""}
                      </p>
                      <p className="flex font-medium text-[12px] gap-1">
                        <TbRulerMeasure className="w-5 h-5 text-[#dadada]" />
                        {card.sqft}{" "}
                        <span className="font-normal text-[12px]">
                          {card.area_type === "1"
                            ? "Acres"
                            : card.area_type === "2"
                            ? "Cents"
                            : card.area_type === "3"
                            ? "Sqft"
                            : ""}
                        </span>
                      </p>
                      {card.commercialInfo.terrace !== null &&
                        card.commercialInfo.terrace !== "" &&
                        card.commercialInfo?.terrace !== "null" && (
                          <p className="flex font-medium text-[12px] gap-1">
                            <img
                              src={Garden}
                              className="w-5 h-5 text-[#dadada]"
                            />
                            {/* {card.commercialInfo.terrace}{" "} */}
                            {/* {card.commercialInfo.terrace !== null &&
                            card.commercialInfo.terrace !== "" &&
                            card.commercialInfo?.terrace !== "null"
                              ? card.commercialInfo.terrace + " Sqft"
                              : ""} */}
                            {card.commercialInfo.terrace !== null &&
                            card.commercialInfo.terrace !== "" &&
                            card.commercialInfo?.terrace !== "null"
                              ? card.commercialInfo.terrace +
                                " " +
                                (card.commercialInfo.terrace_type === "1"
                                  ? "Acres"
                                  : card.commercialInfo.terrace_type === "2"
                                  ? "Cents"
                                  : card.commercialInfo.terrace_type === "3"
                                  ? "Sqft"
                                  : "")
                              : ""}
                            {/* <span className="font-normal text-[12px]">Sqft</span> */}
                          </p>
                        )}
                    </div>
                  )}
                  {card.property_type === "3" && (
                    <div className="flex gap-4">
                      <p className="flex font-medium text-[12px] gap-1">
                        <FaRegBuilding className="w-5 h-5 text-[#dadada]" />
                        {card.label === "1"
                          ? "Agricultural"
                          : card.label === "2"
                          ? "Commercial"
                          : card.label === "3"
                          ? "Residential"
                          : ""}
                      </p>
                      <p className="flex gap-2 font-medium text-[12px]">
                        <TbRulerMeasure className="w-5 h-5 text-[#dadada]" />
                        {card.sqft}{" "}
                        <span className="font-normal">
                          {card.area_type === "1"
                            ? "Acres"
                            : card.area_type === "2"
                            ? "Cents"
                            : card.area_type === "3"
                            ? "Sqft"
                            : ""}
                        </span>
                      </p>
                    </div>
                  )}
                </div>

                <div className="absolute right-2 top-2 z-10">
                  <IconButton
                    size="sm"
                    className="bg-white"
                    onClick={() => handleUpdateProperty(card)}
                  >
                    <SlPencil className="font-semibold text-black text-[17px]" />
                  </IconButton>
                  <IconButton
                    size="sm"
                    className="bg-white ml-2"
                    onClick={() => handleDeleteProperty(card.property_id)}
                  >
                    <MdDeleteOutline className="font-semibold text-black text-[17px]" />
                  </IconButton>
                </div>

                <div className="absolute top-2 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                  <p>
                    {card.category === "1"
                      ? "Buy"
                      : card.category === "2"
                      ? "Rent/Lease"
                      : ""}
                  </p>
                </div>
                {card.residentialInfo?.interior_status && (
                  <div className="absolute top-10 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                    <p>
                      {card.residentialInfo?.interior_status == "1"
                        ? "Furnished"
                        : card.residentialInfo?.interior_status == "2"
                        ? "Semifurnished"
                        : card.residentialInfo?.interior_status == "3"
                        ? "Unfurnished"
                        : ""}
                    </p>
                  </div>
                )}
                {card.commercialInfo?.interior_status && (
                  <div className="absolute top-10 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                    <p>
                      {card.commercialInfo?.interior_status == "1"
                        ? "Furnished"
                        : card.commercialInfo?.interior_status == "2"
                        ? "Semifurnished"
                        : card.commercialInfo?.interior_status == "3"
                        ? "Unfurnished"
                        : ""}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="w-full h-[75vh] flex justify-center items-center font-semibold">
          <p>
            No properties found. Don’t worry, new listings are added regularly.
            Stay tuned!
          </p>
        </div>
      )}
    </div>
  );
}

export default AdminDashboard;
