import { Button, IconButton, Option, Select } from "@material-tailwind/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MdDeleteOutline, MdPhone } from "react-icons/md";
import { SlPencil } from "react-icons/sl";
import { useNavigate, useParams } from "react-router-dom";
import LoadingGif from "../../assets/loader-0.02.gif";
import { Pagination } from "@mui/material";
import toast from "react-hot-toast";
import { deleteServices, getServices } from "../../apiServices/apiServices";

const property_type = [
  { label: "None", value: "" },
  { label: "Residential", value: "1" },
  { label: "Commercial", value: "2" },
  { label: "Land/Plot", value: "3" },
];
const property_category = [
  { label: "None", value: "" },
  { label: "Buy", value: "1" },
  { label: "Rent/Lease", value: "2" },
];
const sort = [
  { label: "None", value: "" },
  { label: "Oldest", value: "1" },
  { label: "Newest", value: "2" },
];

function AdminListLookingFor() {
  const [buyersData, setBuyersData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState({
    p_type: "",
    p_category: "",
    sort: "",
  });
  const navigate = useNavigate();
  const { page } = useParams();
  const access_token = sessionStorage.getItem("land-trandes-access-token");

  useEffect(() => {
    fetchBuyersData(true);
  }, [page, selectedItems]);

  const fetchBuyersData = async (load_bool) => {
    setLoading(load_bool);

    const type =
      selectedItems.p_type === "1"
        ? "&property_type=1"
        : selectedItems.p_type === "2"
        ? "&property_type=2"
        : selectedItems.p_type === "3"
        ? "&property_type=3"
        : "";
    const category =
      selectedItems.p_category === "1"
        ? "&category=1"
        : selectedItems.p_category === "2"
        ? "&category=2"
        : "";
    const sortt =
      selectedItems.sort === "1"
        ? "&sort=1"
        : selectedItems.sort === "2"
        ? "&sort=2"
        : "";

    const url = `/api/lookup/list_lookup?limit=10&page=${page}${category}${type}${sortt}`;

    try {
      const res = await getServices(url);
      setLoading(false);
      if (res.responseCode === 200) {
        setBuyersData(res.responseData);
      } else {
        setBuyersData([]);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleAddBuyersDataFunc = () => {
    navigate("/admin/manage_buyers_data", {
      state: {
        buyerDesc: "",
        prop_type: null,
        prop_category: null,
        update: false,
      },
    });
  };

  const handleUpdateBuyersFunc = (e, data) => {
    e.stopPropagation();

    navigate("/admin/manage_buyers_data", {
      state: {
        buyer_id: data.lookup_id,
        buyerDesc: data.description,
        prop_type: data.property_type,
        prop_category: data.category,
        update: true,
      },
    });
  };

  const handleDeleteBuyers = async (e, data) => {
    e.stopPropagation();

    if (window.confirm("Do you want delete this buyer data?")) {
      try {
        const res = await deleteServices(
          `/api/lookup/delete_lookup?lookup_id=${data.lookup_id}`,
          access_token
        );
        if (res.responseCode === 200) {
          toast.success("Buyer's data deleted...");

          if (buyersData.data?.length > 1) {
            fetchBuyersData(false);
          } else {
            navigate(`/admin/manage_looking_for/${page - 1}`);
            fetchBuyersData(false);
          }
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handlePaginationChange = (e, page) => {
    navigate(`/admin/manage_looking_for/${page}`);
  };

  return (
    <>
      <div className="p-4">
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-2xl sm:text-4xl">Manage Buyers</p>
          <Button
            className="font-semibold"
            style={{ fontFamily: "Poppins" }}
            onClick={handleAddBuyersDataFunc}
          >
            Add Buyers
          </Button>
        </div>

        <div className="flex flex-col md:flex-row justify-end md:items-center gap-2 mb-3">
          <div className="flex flex-col sm:flex-row gap-2">
            <Select
              label="Property Type"
              value={property_type[0].value}
              style={{ fontFamily: "Poppins" }}
              onChange={(e) => {
                setSelectedItems({ ...selectedItems, p_type: e });
              }}
            >
              {property_type.map((item, i) => (
                <Option
                  value={item.value}
                  style={{ fontFamily: "Poppins" }}
                  key={i}
                >
                  {item.label}
                </Option>
              ))}
            </Select>
            <Select
              label="Property Category"
              value={property_category[0].value}
              style={{ fontFamily: "Poppins" }}
              onChange={(e) => {
                setSelectedItems({ ...selectedItems, p_category: e });
              }}
            >
              {property_category.map((item, i) => (
                <Option
                  value={item.value}
                  style={{ fontFamily: "Poppins" }}
                  key={i}
                >
                  {item.label}
                </Option>
              ))}
            </Select>
            <Select
              label="Sort"
              value={sort[0].value}
              style={{ fontFamily: "Poppins" }}
              onChange={(e) => {
                setSelectedItems({ ...selectedItems, sort: e });
              }}
            >
              {sort.map((item, i) => (
                <Option
                  value={item.value}
                  style={{ fontFamily: "Poppins" }}
                  key={i}
                >
                  {item.label}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        {!loading ? (
          <>
            {buyersData.data?.length > 0 ? (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                {buyersData.data?.map((item, i) => (
                  <div
                    key={i}
                    className="bg-white p-3 rounded-xl flex gap-2 md:items-center relative"
                  >
                    <div className="w-full">
                      <p className="text-right text-xs text-blue-gray-300 absolute right-4 top-3">
                        {moment(
                          item.publish_date,
                          "MMM DD, YYYY h:mm a"
                        ).format("DD/MM/YYYY h.mmA")}
                      </p>
                      <p
                        className="text-sm sm:text-lg font-afacad py-6"
                        style={{ wordBreak: "break-word" }}
                      >
                        {item.description}
                      </p>
                      <div className="flex justify-start items-center gap-2 absolute bottom-3">
                        <p className="text-xs bg-green-50 text-green-600 px-3 py-[2px] rounded mt-1">
                          {item.property_type == 1
                            ? "Residential"
                            : item.property_type == 2
                            ? "Commercial"
                            : item.property_type == 3
                            ? "Land/Plot"
                            : ""}
                        </p>
                        <p className="text-xs bg-green-50 text-green-600 px-3 py-[2px] rounded mt-1">
                          {item.category == 1
                            ? "Buy"
                            : item.category == 2
                            ? "Rent/Lease"
                            : ""}
                        </p>
                      </div>
                      <div className="flex justify-end items-center gap-2">
                        <IconButton
                          size="sm"
                          className="bg-white"
                          onClick={(e) => handleUpdateBuyersFunc(e, item)}
                        >
                          <SlPencil className="font-semibold text-black text-[17px]" />
                        </IconButton>
                        <IconButton
                          size="sm"
                          className="bg-white"
                          onClick={(e) => handleDeleteBuyers(e, item)}
                        >
                          <MdDeleteOutline className="font-semibold text-black text-[17px]" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="w-full flex flex-col justify-center items-center gap-2 min-h-80 text-center">
                <p className="text-xl font-semibold">
                  Oops! No Buyer's Data Found
                </p>
                <p>
                  Looks like we couldn't find any buyer's data that match your
                  search right now. But don't worry, we're here to help you find
                  it!
                </p>
                <a
                  href="tel:+918277268334"
                  className="flex items-center bg-[#ff0000] text-white px-3 py-1 rounded-full font-semibold"
                >
                  <MdPhone className="mr-2" />
                  Call Now
                </a>
                <p>
                  In the meantime, you can{" "}
                  <span className="font-semibold">tweak your filters</span> and
                  give it another shot.
                </p>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center items-center h-[50vh]">
            <img src={LoadingGif} alt="" className="h-20" />
          </div>
        )}

        {buyersData.data?.length > 0 && (
          <div className="flex items-center justify-center gap-4 mt-4">
            <div className="flex items-center justify-center gap-2">
              <Pagination
                count={buyersData.totalPages}
                defaultPage={Number(page)}
                siblingCount={0}
                boundaryCount={1}
                onChange={(e, page) => handlePaginationChange(e, page)}
                sx={{
                  fontFamily: "Poppins",
                  ".MuiPaginationItem-root": {
                    fontFamily: "Poppins",
                    borderRadius: "8px",
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AdminListLookingFor;
