import { Button, IconButton, Input } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { SlPencil } from "react-icons/sl";
import AmanityModal from "../../components/modal/admin/AmanityModal";
import toast from "react-hot-toast";
import {
  deleteServices,
  getServices,
  postServices,
  putServices,
} from "../../apiServices/apiServices";

function AdminManageAmenities() {
  const [modal, setModal] = useState({ add_modal: false, update_modal: false });
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [fieldData, setFieldData] = useState({ amenity_name: "" });
  const [amenityList, setAmenityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const access_token = sessionStorage.getItem("land-trandes-access-token");

  useEffect(() => {
    fetchAmenitiesList();
  }, []);

  const fetchAmenitiesList = async () => {
    try {
      const res = await getServices(
        `/api/amenities/list_amenity`,
        access_token
      );
      if (res.responseCode === 200) {
        setAmenityList(res.responseData);
      } else {
        setAmenityList([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleAddAmebityModal = () => {
    setModal({ ...modal, add_modal: true });
    setSelectedImage(null);
    setFieldData({ ...fieldData, amenity_name: "" });
    setPreview(null);
  };

  const handleUpdateAmebityModal = (data) => {
    setModal({ ...modal, update_modal: true });
    setFieldData({
      ...fieldData,
      amenity_name: data.amenity_name,
      amenity_id: data.amenity_id,
    });
    setPreview(data.image[0]);
    setSelectedImage(data.image[0]);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (img.width !== 512 || img.height !== 512) {
            alert("The image must have a resolution of 512x512 pixels.");
            setSelectedImage(null);
            setPreview(null);
          } else {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = 512;
            canvas.height = 512;
            ctx.drawImage(img, 0, 0, 512, 512);
            const resizedImage = canvas.toDataURL("image/png");
            setPreview(resizedImage);
            setSelectedImage(file); // Keep the original image file
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (selectedImage) {
      // Handle image upload here
      const formData = new FormData();

      formData.append("image", selectedImage);
      formData.append("amenity_name", fieldData.amenity_name);

      try {
        const res = await postServices(
          `/api/amenities/add_amenity`,
          formData,
          access_token
        );
        setLoading(false);
        if (res.responseCode === 200) {
          setModal({ ...modal, add_modal: false });
          fetchAmenitiesList();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    } else {
      alert("Please select a Image...");
      setLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    if (selectedImage) {
      // Handle image upload here
      formData.append("image", selectedImage);
    }
    formData.append("amenity_name", fieldData.amenity_name);

    try {
      const res = await putServices(
        `/api/amenities/edit_amenity?amenity_id=${fieldData.amenity_id}`,
        formData,
        access_token
      );
      setLoading(false);
      if (res.responseCode === 200) {
        setModal({ ...modal, update_modal: false });
        fetchAmenitiesList();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleDeleteAmenity = async (data) => {
    if (window.confirm("Do you want delete this item?")) {
      try {
        const res = await deleteServices(
          `/api/amenities/delete_amenity?amenity_id=${data.amenity_id}`,
          access_token
        );
        if (res.responseCode === 200) {
          fetchAmenitiesList();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleAmenitySearch = async (e) => {
    if (e.target.value.length > 0) {
      try {
        const res = await getServices(
          `/api/amenities/search_amenity/${e.target.value}`,
          access_token
        );
        if (res.responseCode === 200) {
          setAmenityList(res.responseData);
        } else if (res.responseCode === 205) {
          toast.error("No Result Found...");
          setAmenityList([]);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      fetchAmenitiesList();
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-4xl">Manage Amenities</p>
          <Button
            className="font-semibold"
            style={{ fontFamily: "Poppins" }}
            onClick={handleAddAmebityModal}
          >
            Add Amenities
          </Button>
        </div>
        <div className="flex justify-end items-center w-fit">
          <Input
            label="Search Amenities"
            className="w-fit"
            autoFocus
            onChange={handleAmenitySearch}
            style={{ fontFamily: "Poppins" }}
          />
        </div>
        {amenityList.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 mt-4">
            {amenityList.map((item, i) => (
              <div
                key={i}
                className="flex justify-between items-center bg-white p-3 rounded-md hover:drop-shadow-md transition-all"
              >
                <div className="flex items-center gap-1 rounded">
                  <img
                    src={item.image[0]}
                    alt=""
                    className="w-10 h-10 object-cover"
                  />
                  <p className="font-semibold">{item.amenity_name}</p>
                </div>
                <div className="flex items-center gap-2">
                  <IconButton
                    size="sm"
                    className="bg-white"
                    onClick={() => handleUpdateAmebityModal(item)}
                  >
                    <SlPencil className="font-semibold text-black text-[17px]" />
                  </IconButton>
                  <IconButton
                    size="sm"
                    className="bg-white"
                    onClick={() => handleDeleteAmenity(item)}
                  >
                    <MdDeleteOutline className="font-semibold text-black text-[17px]" />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full h-[75vh] flex justify-center items-center font-semibold">
            <p>No Amenity Found...</p>
          </div>
        )}
      </div>

      <AmanityModal
        open={modal.add_modal}
        onClose={() => setModal({ ...modal, add_modal: false })}
        modal={modal}
        handleImageChange={handleImageChange}
        preview={preview}
        setFieldData={setFieldData}
        fieldData={fieldData}
        handleSubmit={handleSubmit}
        loading={loading}
      />
      <AmanityModal
        open={modal.update_modal}
        onClose={() => setModal({ ...modal, update_modal: false })}
        modal={modal}
        handleImageChange={handleImageChange}
        preview={preview}
        setFieldData={setFieldData}
        fieldData={fieldData}
        handleUpdate={handleUpdate}
        loading={loading}
      />
    </>
  );
}

export default AdminManageAmenities;
