import React, { useEffect, useState } from "react";
import { StickyNavbar } from "../../components/UserNavbar";
import { Divider } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import UserFooter from "../../components/UserFooter";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { getServices } from "../../apiServices/apiServices";
import { GoArrowRight } from "react-icons/go";
import { Helmet } from "react-helmet-async";

function PropertyDetailsPage() {
  const [propertyData, setPropertyData] = useState({});
  const [seoData, setSeoData] = useState({});
  const { prop_code } = useParams();

  useEffect(() => {
    document.title = "Rao and Rai Real Estate Mangalore";

    window.scrollTo(0, 0);
    fetchPropertyDetails();
    fetchSEOData();
  }, []);

  const descriptionData = propertyData.description;

  const fetchPropertyDetails = async () => {
    try {
      const res = await getServices(
        `/api/property/list_property?property_code=${prop_code}`
      );
      if (res.responseCode === 200) {
        setPropertyData(res.responseData[0]);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchSEOData = async () => {
    try {
      const res = await getServices(`/api/seo/list?seo_type=5`);
      if (res.responseCode === 200) {
        setSeoData(res.responseData[0]);
      } else {
        setSeoData({});
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const settings = {
    showDots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const imageFiles = propertyData.file?.filter(
    (file) =>
      file.endsWith(".jpg") ||
      file.endsWith(".jpeg") ||
      file.endsWith(".png") ||
      file.endsWith(".gif") ||
      file.endsWith(".webp") ||
      file.endsWith(".svg") ||
      file.endsWith(".heif") ||
      file.endsWith(".heic") ||
      file.endsWith(".avif") ||
      file.endsWith(".JPG") ||
      file.endsWith(".JPEG") ||
      file.endsWith(".PNG") ||
      file.endsWith(".GIF") ||
      file.endsWith(".WEBP") ||
      file.endsWith(".SVG") ||
      file.endsWith(".HEIF") ||
      file.endsWith(".HEIC") ||
      file.endsWith(".AVIF")
  );
  const videoFiles = propertyData.file?.filter(
    (file) => file.endsWith(".mp4") || file.endsWith(".MP4")
  );

  return (
    <div>
      <Helmet>
        {/* <title>{propertyData.property_name}</title> */}
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        {seoData.og_title !== "" &&
          seoData.og_title !== null &&
          seoData.og_title !== "null" && (
            <meta property="og:title" content={seoData.og_title} />
          )}
        {seoData.og_description !== "" &&
          seoData.og_description !== null &&
          seoData.og_description !== "null" && (
            <meta property="og:description" content={seoData.og_description} />
          )}
      </Helmet>
      <StickyNavbar>
        <div className="p-4 max-w-[1300px] mx-auto">
          <div className={`flex justify-between items-start`}>
            <h1 className="text-2xl font-bold mt-4 uppercase">
              {propertyData.property_name}
            </h1>

            <h1 className="hidden sm:block text-2xl font-semibold mt-4">
              <span className="font-sans">₹</span>
              {propertyData.price}
            </h1>
          </div>
          <div
            className="sm:hidden flex-col text-gray-600 pt-1 flex text-xl"
            style={{ fontFamily: "Afacad" }}
          >
            <p>{propertyData.location}</p>
          </div>
          <Carousel
            {...settings}
            infiniteLoop={true} // Enable infinite looping
            swipeable={false}
            autoPlay={true}
            emulateTouch={true}
            interval={5000}
            showIndicators={false}
            showThumbs={true}
            thumbWidth={120}
            transitionTime={2000}
            animationHandler={"fade"}
            className="my-5 custom-carousel"
          >
            {imageFiles?.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`${index + 1}`}
                  className="w-full h-[200px] sm:h-[250px] md:h-[350px] lg:h-[500px] object-cover rounded-m"
                />
              </div>
            ))}
          </Carousel>
          {/* <div className="sm:flex justify-between pb-2 gap-4 sm:gap-0 hidden uppercase"> */}
          <div className="sm:flex justify-between pb-2 gap-4 sm:gap-0 hidden">
            {/* <h1 className="text-2xl font-bold">{propertyData.property_name}</h1> */}

            <div
              className="sm:flex flex-col text-gray-600 pt-2 hidden text-xl"
              style={{ fontFamily: "Afacad" }}
            >
              <p>{propertyData.location}</p>
            </div>
          </div>

          <h1 className="text-2xl font-semibold block sm:hidden">
            <span className="font-sans">₹</span>
            {propertyData.price}
          </h1>

          {/* <div
            className="sm:flex flex-col text-gray-600 pt-2 hidden text-xl"
            style={{ fontFamily: "Afacad" }}
          >
            <p>{propertyData.location}</p>
          </div> */}

          {/* divider */}
          <div className="flex items-center pt-5 pb-5">
            <h1 className="text-[16px] md:text-[25px] font-normal text-[#7a7c7d] mr-3 font-afacad">
              Basics
            </h1>
            <Divider
              className="flex-1 text-[#b0bec5]"
              style={{ borderBottomWidth: "2px" }}
            />
          </div>

          <div className="grid justify-start items-start grid-cols-2 w-full md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-8 gap-4">
            {propertyData.property_type === "1" && (
              <div className="text-[14px] grid">
                <h1 className="text-[#676767] grid justify-center font-afacad text-lg">
                  Type
                </h1>
                <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                  {propertyData.label === "1"
                    ? "1BHK"
                    : propertyData.label === "2"
                    ? "2BHK"
                    : propertyData.label === "3"
                    ? "3BHK"
                    : propertyData.label === "4"
                    ? "3BHK+"
                    : ""}
                </h1>
              </div>
            )}
            {propertyData.property_type === "2" && (
              <div className="text-[14px] grid">
                <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                  Type
                </h1>
                <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                  {propertyData.label === "1"
                    ? "Industrial Building"
                    : propertyData.label === "2"
                    ? "Industrial Shed"
                    : propertyData.label === "3"
                    ? "Office Space"
                    : propertyData.label === "4"
                    ? "Shop/Showroom"
                    : propertyData.label === "5"
                    ? "Warehouse/Godown"
                    : ""}
                </h1>
              </div>
            )}
            {propertyData.property_type === "3" && (
              <div className="text-[14px] grid">
                <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                  Type
                </h1>
                <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                  {propertyData.label === "1"
                    ? "Agricultural"
                    : propertyData.label === "2"
                    ? "Commercial"
                    : propertyData.label === "3"
                    ? "Residential"
                    : ""}
                </h1>
              </div>
            )}

            {propertyData.residentialInfo && (
              <div className="text-[14px] ">
                <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                  Bedrooms
                </h1>
                <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                  {propertyData.residentialInfo?.bedroom}
                </h1>
              </div>
            )}

            {propertyData.residentialInfo && (
              <div className="text-[14px] ">
                <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                  Bathrooms
                </h1>
                <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                  {propertyData.residentialInfo?.bathroom}
                </h1>
              </div>
            )}
            {propertyData.residentialInfo && (
              <div className="text-[14px] ">
                <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                  Balconies
                </h1>
                <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                  {/* {propertyData.residentialInfo?.balcony} */}
                  {propertyData.residentialInfo?.balcony !== null &&
                  propertyData.residentialInfo.balcony !== "" &&
                  propertyData.residentialInfo?.balcony !== "null"
                    ? propertyData.residentialInfo.balcony
                    : "N/A"}
                </h1>
              </div>
            )}

            {propertyData.residentialInfo &&
              propertyData.residentialInfo?.interior_status !== null &&
              propertyData.residentialInfo?.interior_status !== "" &&
              propertyData.residentialInfo?.interior_status !== "null" && (
                <div className="text-[14px] ">
                  <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                    Interior Status
                  </h1>
                  <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                    {propertyData.residentialInfo?.interior_status == 1
                      ? "Furnished"
                      : propertyData.residentialInfo?.interior_status == 2
                      ? "Semi-Furnished"
                      : propertyData.residentialInfo?.interior_status == 3
                      ? "Unfurnished"
                      : ""}
                  </h1>
                </div>
              )}
            {propertyData.commercialInfo &&
              propertyData.commercialInfo?.interior_status !== null &&
              propertyData.commercialInfo?.interior_status !== "" &&
              propertyData.commercialInfo?.interior_status !== "null" && (
                <div className="text-[14px] ">
                  <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                    Interior Status
                  </h1>
                  <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                    {propertyData.commercialInfo?.interior_status == 1
                      ? "Furnished"
                      : propertyData.commercialInfo?.interior_status == 2
                      ? "Semi-Furnished"
                      : propertyData.commercialInfo?.interior_status == 3
                      ? "Unfurnished"
                      : ""}
                  </h1>
                </div>
              )}

            {/* {propertyData.property_type === "1" && (
              <div className="text-[14px] grid">
                <h1 className="text-[#676767] grid justify-center font-afacad text-lg">
                  Interior Status
                </h1>
                <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                  {propertyData.interior_status === "1"
                    ? "Furnished"
                    : propertyData.interior_status === "2"
                    ? "Semifurnished"
                    : propertyData.interior_status === "3"
                    ? "Unfurnished"
                    : ""}
                </h1>
              </div>
            )} */}
            {/* {propertyData.property_type === "2" && (
              <div className="text-[14px] grid">
                <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                  Interior Status
                </h1>
                <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                  {propertyData.interior_status === "1"
                    ? "Furnished"
                    : propertyData.interior_status === "2"
                    ? "Semifurnished"
                    : propertyData.interior_status === "3"
                    ? "Unfurnished"
                    : ""}
                </h1>
              </div>
            )} */}

            {propertyData.commercialInfo && (
              <div className="text-[14px] ">
                <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                  Balconies
                </h1>
                <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                  {/* {propertyData.commercialInfo?.balcony} */}
                  {propertyData.commercialInfo?.balcony !== null &&
                  propertyData.commercialInfo.balcony !== "" &&
                  propertyData.commercialInfo?.balcony !== "null"
                    ? propertyData.commercialInfo.balcony
                    : "N/A"}
                </h1>
              </div>
            )}

            <div className="text-[14px] grid ">
              <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                Area
              </h1>
              <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                {propertyData.sqft?.toLocaleString("en-In")}{" "}
                {/* {propertyData.property_type === "3" ? "Acres" : "Sqft"} */}
                {propertyData.area_type === "1"
                  ? "Acres"
                  : propertyData.area_type === "2"
                  ? "Cents"
                  : propertyData.area_type === "3"
                  ? "Sqft"
                  : ""}
              </h1>
            </div>

            {propertyData.commercialInfo &&
              propertyData.commercialInfo.terrace !== null &&
              propertyData.commercialInfo.terrace !== "" &&
              propertyData.commercialInfo.terrace !== "null" && (
                <div className="text-[14px]">
                  <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg text-center">
                    Open Garden/Terrace
                  </h1>
                  <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                    {propertyData.commercialInfo.terrace +
                      (propertyData.commercialInfo.terrace_type === "1"
                        ? " Acres"
                        : propertyData.commercialInfo.terrace_type === "2"
                        ? " Cents"
                        : propertyData.commercialInfo.terrace_type === "3"
                        ? " Sqft"
                        : "")}
                  </h1>
                </div>
              )}

            {propertyData.commercialInfo && (
              <div className="text-[14px] grid">
                <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                  Year Built
                </h1>
                <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                  {propertyData.commercialInfo?.year_built}
                </h1>
              </div>
            )}

            {propertyData.residentialInfo &&
              propertyData.residentialInfo.terrace !== null &&
              propertyData.residentialInfo.terrace !== "" &&
              propertyData.residentialInfo.terrace !== "null" && (
                <div className="text-[14px]">
                  <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg text-center">
                    Open Garden/Terrace
                  </h1>
                  <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                    {propertyData.residentialInfo.terrace +
                      (propertyData.residentialInfo.terrace_type === "1"
                        ? " Acres"
                        : propertyData.residentialInfo.terrace_type === "2"
                        ? " Cents"
                        : propertyData.residentialInfo.terrace_type === "3"
                        ? " Sqft"
                        : "")}
                  </h1>
                </div>
              )}

            {propertyData.residentialInfo && (
              <div className="text-[14px] grid">
                <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                  Year Built
                </h1>
                <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                  {propertyData.residentialInfo?.year_built}
                </h1>
              </div>
            )}

            {propertyData.residentialInfo && (
              <div className="text-[14px] ">
                <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                  Parking
                </h1>
                <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                  {propertyData.residentialInfo?.parking === "1"
                    ? "Carport"
                    : propertyData.residentialInfo?.parking === "2"
                    ? "Garage - attached"
                    : propertyData.residentialInfo?.parking === "3"
                    ? "Garage - detached"
                    : propertyData.residentialInfo?.parking === "4"
                    ? "Off-street"
                    : propertyData.residentialInfo?.parking === "5"
                    ? "On-street"
                    : ""}
                </h1>
              </div>
            )}

            {propertyData.commercialInfo && (
              <div className="text-[14px] ">
                <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                  Parking
                </h1>
                <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                  {propertyData.commercialInfo?.parking === "1"
                    ? "Carport"
                    : propertyData.commercialInfo?.parking === "2"
                    ? "Garage - attached"
                    : propertyData.commercialInfo?.parking === "3"
                    ? "Garage - detached"
                    : propertyData.commercialInfo?.parking === "4"
                    ? "Off-street"
                    : propertyData.commercialInfo?.parking === "5"
                    ? "On-street"
                    : ""}
                </h1>
              </div>
            )}
          </div>

          {propertyData.amenityInfo?.length > 0 && (
            <>
              <div className="flex items-center pt-5 pb-5">
                <h1 className="text-[16px] md:text-[25px] font-normal text-[#7a7c7d] mr-3 font-afacad">
                  Amenities
                </h1>
                <Divider
                  className="flex-1 text-[#b0bec5]"
                  style={{ borderBottomWidth: "2px" }}
                />
              </div>
              <div className="grid grid-cols-2 md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-8 gap-5 justify-center items-stretch">
                {propertyData.amenityInfo?.map((item, i) => (
                  <div
                    className="flex flex-col items-center gap-3 font-afacad text-lg text-center"
                    key={i}
                  >
                    <img
                      src={item.image[0]}
                      alt=""
                      className="w-7 h-7 mx-auto"
                    />
                    <p>{item.amenity_name}</p>
                  </div>
                ))}
              </div>
            </>
          )}

          {videoFiles?.length > 0 && (
            <>
              <div className="flex items-center pt-5 pb-5">
                <h1 className="text-[16px] md:text-[22px] font-normal text-[#7a7c7d] mr-3">
                  Video Tour
                </h1>
                <Divider
                  className="flex-1 text-[#b0bec5]"
                  style={{ borderBottomWidth: "2px" }}
                />
              </div>
              <div
                className={`grid gap-3 justify-center items-center ${
                  videoFiles.length > 1 ? "md:grid-cols-2" : "md:grid-cols-1"
                }`}
              >
                {videoFiles?.map((item, i) => (
                  <div
                    key={i}
                    className="w-full flex justify-center items-center"
                  >
                    <video
                      src={item}
                      controls
                      autoPlay
                      muted
                      className="w-full max-w-[500px]"
                      controlsList="nodownload"
                    />
                  </div>
                ))}
              </div>
            </>
          )}

          <div className="flex items-center pt-5 pb-5">
            <h1 className="text-[16px] md:text-[25px] font-normal text-[#7a7c7d] mr-3 font-afacad">
              About the Property
            </h1>
            <Divider
              className="flex-1 text-[#b0bec5]"
              style={{ borderBottomWidth: "2px" }}
            />
          </div>
          <div className="font-afacad text-lg pl-2 description-count inner-html-custom">
            <div dangerouslySetInnerHTML={{ __html: descriptionData }}></div>
          </div>
          {propertyData.url && propertyData.url.length > 0 && (
            <div className="font-afacad text-lg flex justify-center lg:justify-end">
              <a
                href={propertyData.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex px-4 py-2 text-center font-semibold text-[#5e78a3] hover:bg-gray-100 rounded-full"
              >
                Watch on YouTube
                <GoArrowRight className="flex text-lg ml-2 m-1" />
              </a>
            </div>
          )}
        </div>
      </StickyNavbar>
      <UserFooter />
    </div>
  );
}

export default PropertyDetailsPage;
