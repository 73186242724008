import { Button, Input } from "@material-tailwind/react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React from "react";
import { IoClose } from "react-icons/io5";

function AmanityModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <p className="font-semibold">
                {props.modal?.update_modal
                  ? "Update"
                  : props.modal?.add_modal
                  ? "Add"
                  : ""}{" "}
                Amenities
              </p>
              <IoClose
                className="cursor-pointer border border-[#000] rounded-full"
                onClick={props.onClose}
              />
            </div>
            <form
              onSubmit={
                props.modal?.update_modal
                  ? props.handleUpdate
                  : props.handleSubmit
              }
              className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3"
            >
              <Input
                label="Amenity Name"
                type="text"
                style={{ fontFamily: "Poppins" }}
                value={props.fieldData?.amenity_name}
                onChange={(e) =>
                  props.setFieldData({
                    ...props.fieldData,
                    amenity_name: e.target.value,
                  })
                }
                required
              />
              <Input
                label="Choose image"
                type="file"
                style={{ fontFamily: "Poppins" }}
                onChange={props.handleImageChange}
                accept="image/png, image/jpg, image/jpeg, image/heic"
                required={props.modal?.update_modal ? false : true}
              />
              <p className="text-red-500 text-center text-sm">
                Supported formats: .jpg, .jpeg, .png, .heic. Maximum size: 5MB.
                Required resolution: 512x512 pixels *
              </p>
              {props.preview && (
                <div>
                  <img
                    src={props.preview}
                    alt=""
                    className="w-24 h-24 rounded-lg object-cover"
                  />
                </div>
              )}
              <Button type="submit" disabled={props.loading}>
                {!props.loading
                  ? props.modal?.update_modal
                    ? "Update"
                    : props.modal?.add_modal
                    ? "Save"
                    : ""
                  : "Loading..."}
              </Button>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default AmanityModal;
