import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { getServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import { GoArrowRight } from "react-icons/go";

function AdminPropertyDetailsPage() {
  const [propertyData, setPropertyData] = useState({});
  const { prop_code } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPropertyDetails();
  }, []);

  const fetchPropertyDetails = async () => {
    try {
      const res = await getServices(
        `/api/property/list_property?property_code=${prop_code}`
      );
      if (res.responseCode === 200) {
        setPropertyData(res.responseData[0]);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const imageFiles = propertyData.file?.filter(
    (file) =>
      file.endsWith(".jpg") ||
      file.endsWith(".jpeg") ||
      file.endsWith(".png") ||
      file.endsWith(".gif") ||
      file.endsWith(".webp") ||
      file.endsWith(".svg") ||
      file.endsWith(".heif") ||
      file.endsWith(".heic") ||
      file.endsWith(".avif") ||
      file.endsWith(".JPG") ||
      file.endsWith(".JPEG") ||
      file.endsWith(".PNG") ||
      file.endsWith(".GIF") ||
      file.endsWith(".WEBP") ||
      file.endsWith(".SVG") ||
      file.endsWith(".HEIF") ||
      file.endsWith(".HEIC") ||
      file.endsWith(".AVIF")
  );
  const videoFiles = propertyData.file?.filter(
    (file) => file.endsWith(".mp4") || file.endsWith(".MP4")
  );

  return (
    <div className="p-4">
      <div className={`flex justify-between items-start`}>
        <h1 className="text-2xl font-bold mt-4 uppercase">
          {propertyData.property_name}
        </h1>

        <h1 className="hidden sm:block text-2xl font-semibold mt-4">
          <span className="font-sans">₹</span>
          {propertyData.price}
        </h1>
      </div>
      <Carousel
        {...settings}
        swipeable={true}
        autoPlay={true}
        emulateTouch={true}
        interval={5000}
        showThumbs={true}
        thumbWidth={120}
        transitionTime={2000}
        className="mt-5 custom-carousel"
      >
        {imageFiles?.map((file, index) => (
          <div key={index}>
            <img
              src={file}
              alt={`${index + 1}`}
              className="h-[200px] sm:h-[250px] md:h-[350px] lg:h-[500px] object-cover rounded-md"
            />
          </div>
        ))}
      </Carousel>
      <div className="flex justify-between">
        {/* <h1 className="text-2xl font-bold">{propertyData.property_name}</h1> */}
        <div className="flex flex-col text-gray-600 font-afacad text-xl">
          <p>{propertyData.location}</p>
        </div>
        {/* <h1 className="text-2xl font-semibold">
          <span className="font-sans">₹</span>
          {propertyData.price}
        </h1> */}
        <h1 className="text-2xl font-semibold inline-block sm:hidden">
          <span className="font-sans">₹</span>
          {propertyData.price}
        </h1>
      </div>

      {/* divider */}
      <div className="flex items-center pt-5 pb-5">
        <h1 className="text-[16px] md:text-[25px] font-normal text-[#b1bec5] mr-3 font-afacad">
          Basics
        </h1>
        <Divider
          className="flex-1 text-[#b0bec5]"
          style={{ borderBottomWidth: "2px" }}
        />
      </div>

      <div className="grid justify-start items-start grid-cols-2 w-full md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-8 gap-4">
        {propertyData.property_type === "1" && (
          <div className="text-[14px] grid">
            <h1 className="text-[#676767] grid justify-center font-afacad text-lg">
              Type
            </h1>
            <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
              {propertyData.label === "1"
                ? "1BHK"
                : propertyData.label === "2"
                ? "2BHK"
                : propertyData.label === "3"
                ? "3BHK"
                : propertyData.label === "4"
                ? "3BHK+"
                : ""}
            </h1>
          </div>
        )}
        {propertyData.property_type === "2" && (
          <div className="text-[14px] grid">
            <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
              Type
            </h1>
            <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
              {propertyData.label === "1"
                ? "Industrial Building"
                : propertyData.label === "2"
                ? "Industrial Shed"
                : propertyData.label === "3"
                ? "Office Space"
                : propertyData.label === "4"
                ? "Shop/Showroom"
                : propertyData.label === "5"
                ? "Warehouse/Godown"
                : ""}
            </h1>
          </div>
        )}
        {propertyData.property_type === "3" && (
          <div className="text-[14px] grid">
            <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
              Type
            </h1>
            <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
              {propertyData.label === "1"
                ? "Agricultural"
                : propertyData.label === "2"
                ? "Commercial"
                : propertyData.label === "3"
                ? "Residential"
                : ""}
            </h1>
          </div>
        )}

        {propertyData.residentialInfo && (
          <div className="text-[14px] ">
            <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
              Bedrooms
            </h1>
            <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
              {propertyData.residentialInfo?.bedroom}
            </h1>
          </div>
        )}

        {propertyData.residentialInfo && (
          <div className="text-[14px] ">
            <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
              Bathrooms
            </h1>
            <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
              {propertyData.residentialInfo?.bathroom}
            </h1>
          </div>
        )}
        {propertyData.residentialInfo && (
          <div className="text-[14px] ">
            <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
              Balconies
            </h1>
            <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
              {/* {propertyData.residentialInfo?.balcony} */}
              {propertyData.residentialInfo?.balcony !== null &&
              propertyData.residentialInfo.balcony !== "" &&
              propertyData.residentialInfo?.balcony !== "null"
                ? propertyData.residentialInfo.balcony
                : "N/A"}
            </h1>
          </div>
        )}
        {propertyData.commercialInfo && (
          <div className="text-[14px] ">
            <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
              Balconies
            </h1>
            <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
              {/* {propertyData.commercialInfo?.balcony} */}
              {propertyData.commercialInfo?.balcony !== null &&
              propertyData.commercialInfo.balcony !== "" &&
              propertyData.commercialInfo?.balcony !== "null"
                ? propertyData.commercialInfo.balcony
                : "N/A"}
            </h1>
          </div>
        )}

        {propertyData.residentialInfo &&
          propertyData.residentialInfo?.interior_status !== null &&
          propertyData.residentialInfo?.interior_status !== "" &&
          propertyData.residentialInfo?.interior_status !== "null" && (
            <div className="text-[14px] ">
              <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                Interior Status
              </h1>
              <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                {propertyData.residentialInfo?.interior_status == 1
                  ? "Furnished"
                  : propertyData.residentialInfo?.interior_status == 2
                  ? "Semi-Furnished"
                  : propertyData.residentialInfo?.interior_status == 3
                  ? "Unfurnished"
                  : ""}
              </h1>
            </div>
          )}
        {propertyData.commercialInfo &&
          propertyData.commercialInfo?.interior_status !== null &&
          propertyData.commercialInfo?.interior_status !== "" &&
          propertyData.commercialInfo?.interior_status !== "null" && (
            <div className="text-[14px] ">
              <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
                Interior Status
              </h1>
              <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                {propertyData.commercialInfo?.interior_status == 1
                  ? "Furnished"
                  : propertyData.commercialInfo?.interior_status == 2
                  ? "Semi-Furnished"
                  : propertyData.commercialInfo?.interior_status == 3
                  ? "Unfurnished"
                  : ""}
              </h1>
            </div>
          )}

        <div className="text-[14px] grid ">
          <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
            Area
          </h1>
          <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
            {propertyData.sqft?.toLocaleString("en-In")}{" "}
            {/* {propertyData.property_type === "3" ? "Acres" : "Sqft"} */}
            {propertyData.area_type === "1"
              ? "Acres"
              : propertyData.area_type === "2"
              ? "Cents"
              : propertyData.area_type === "3"
              ? "Sqft"
              : ""}
          </h1>
        </div>

        {propertyData.commercialInfo &&
          propertyData.commercialInfo.terrace !== null &&
          propertyData.commercialInfo.terrace !== "" &&
          propertyData.commercialInfo.terrace !== "null" && (
            <div className="text-[14px]">
              <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg text-center">
                Open Garden/Terrace
              </h1>
              <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                {propertyData.commercialInfo.terrace +
                  (propertyData.commercialInfo.terrace_type === "1"
                    ? " Acres"
                    : propertyData.commercialInfo.terrace_type === "2"
                    ? " Cents"
                    : propertyData.commercialInfo.terrace_type === "3"
                    ? " Sqft"
                    : "")}
              </h1>
            </div>
          )}

        {propertyData.commercialInfo && (
          <div className="text-[14px] grid">
            <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
              Year Built
            </h1>
            <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
              {propertyData.commercialInfo?.year_built}
            </h1>
          </div>
        )}

        {propertyData.residentialInfo &&
          propertyData.residentialInfo.terrace !== null &&
          propertyData.residentialInfo.terrace !== "" &&
          propertyData.residentialInfo.terrace !== "null" && (
            <div className="text-[14px]">
              <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg text-center">
                Open Garden/Terrace
              </h1>
              <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
                {propertyData.residentialInfo.terrace +
                  (propertyData.residentialInfo.terrace_type === "1"
                    ? " Acres"
                    : propertyData.residentialInfo.terrace_type === "2"
                    ? " Cents"
                    : propertyData.residentialInfo.terrace_type === "3"
                    ? " Sqft"
                    : "")}
              </h1>
            </div>
          )}

        {propertyData.residentialInfo && (
          <div className="text-[14px] grid">
            <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
              Year Built
            </h1>
            <h1 className="grid justify-center items-center font-bold font-afacad text-lg">
              {propertyData.residentialInfo?.year_built}
            </h1>
          </div>
        )}

        {propertyData.residentialInfo && (
          <div className="text-[14px] ">
            <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
              Parking
            </h1>
            <h1 className="grid justify-center items-center font-bold font-afacad text-lg text-center">
              {propertyData.residentialInfo?.parking === "1"
                ? "Carport"
                : propertyData.residentialInfo?.parking === "2"
                ? "Garage - attached"
                : propertyData.residentialInfo?.parking === "3"
                ? "Garage - detached"
                : propertyData.residentialInfo?.parking === "4"
                ? "Off-street"
                : propertyData.residentialInfo?.parking === "5"
                ? "On-street"
                : ""}
            </h1>
          </div>
        )}

        {propertyData.commercialInfo && (
          <div className="text-[14px] ">
            <h1 className="text-[#676767] grid justify-center items-center font-afacad text-lg">
              Parking
            </h1>
            <h1 className="grid justify-center items-center font-bold font-afacad text-lg text-center">
              {propertyData.commercialInfo?.parking === "1"
                ? "Carport"
                : propertyData.commercialInfo?.parking === "2"
                ? "Garage - attached"
                : propertyData.commercialInfo?.parking === "3"
                ? "Garage - detached"
                : propertyData.commercialInfo?.parking === "4"
                ? "Off-street"
                : propertyData.commercialInfo?.parking === "5"
                ? "On-street"
                : ""}
            </h1>
          </div>
        )}
      </div>

      {propertyData.amenityInfo?.length > 0 && (
        <>
          <div className="flex items-center pt-5 pb-5">
            <h1 className="text-[16px] md:text-[25px] font-afacad font-normal text-[#b1bec5] mr-3">
              Amenities
            </h1>
            <Divider
              className="flex-1 text-[#b0bec5]"
              style={{ borderBottomWidth: "2px" }}
            />
          </div>
          <div className="grid grid-cols-2 md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-8 gap-5">
            {propertyData.amenityInfo?.map((item, i) => (
              <div
                className="flex flex-col items-center gap-3 text-lg font-afacad text-center"
                key={i}
              >
                <img src={item.image[0]} alt="" className="w-5 h-5" />
                <p>{item.amenity_name}</p>
              </div>
            ))}
          </div>
        </>
      )}

      {videoFiles?.length > 0 && (
        <>
          <div className="flex items-center pt-5 pb-5">
            <h1 className="text-[16px] md:text-[25px] font-afacad font-normal text-[#b1bec5] mr-3">
              Videos
            </h1>
            <Divider
              className="flex-1 text-[#b0bec5]"
              style={{ borderBottomWidth: "2px" }}
            />
          </div>
          <div
            className={`grid gap-3 justify-center items-center ${
              videoFiles.length > 1 ? "md:grid-cols-2" : "md:grid-cols-1"
            }`}
          >
            {videoFiles?.map((item, i) => (
              <div key={i} className="w-full flex justify-center items-center">
                <video
                  src={item}
                  controls
                  autoPlay
                  muted
                  className="w-full max-w-[500px]"
                  controlsList="nodownload"
                />
              </div>
            ))}
          </div>
        </>
      )}

      <div className="flex items-center pt-5 pb-5">
        <h1 className="text-[16px] md:text-[25px] font-afacad font-normal text-[#b1bec5] mr-3">
          About the Property
        </h1>
        <Divider
          className="flex-1 text-[#b0bec5]"
          style={{ borderBottomWidth: "2px" }}
        />
      </div>
      <div className="text-lg font-afacad">
        {/* <h1>{propertyData.description}</h1> */}
        <div
          className="text-justify pl-2 description-count inner-html-custom"
          dangerouslySetInnerHTML={{ __html: propertyData.description }}
        />
      </div>
      {propertyData.url && propertyData.url.length > 0 && (
        <div className="font-afacad text-lg flex justify-center lg:justify-end">
          <a
            href={propertyData.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex px-4 py-2 text-center font-semibold text-[#f2a242] hover:bg-[#f1e4c2] hover:text-[#f2a242] rounded-full"
          >
            Watch on YouTube
            <GoArrowRight className="flex text-lg ml-2 m-1" />
          </a>
        </div>
      )}
    </div>
  );
}

export default AdminPropertyDetailsPage;
