import { Button, Input, Textarea } from "@material-tailwind/react";
import {
  Autocomplete,
  Backdrop,
  Box,
  Chip,
  Fade,
  Modal,
  TextField,
} from "@mui/material";
import React from "react";
import { IoClose } from "react-icons/io5";

function SEOModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <p className="font-semibold">Update SEO</p>
              <IoClose
                className="cursor-pointer border border-[#000] rounded-full"
                onClick={props.onClose}
              />
            </div>
            <form
              onSubmit={props.handleUpdateSubmit}
              className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3 max-h-[500px] overflow-auto"
            >
              <div className="mt-[8px]">
                <Input
                  label="Title"
                  type="text"
                  style={{ fontFamily: "Poppins" }}
                  value={props.fieldData?.title || ""}
                  onChange={(e) =>
                    props.setFieldData({
                      ...props.fieldData,
                      title: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <Input
                label="OG Title"
                type="text"
                style={{ fontFamily: "Poppins" }}
                value={props.fieldData?.og_title || ""}
                onChange={(e) =>
                  props.setFieldData({
                    ...props.fieldData,
                    og_title: e.target.value,
                  })
                }
              />
              <Textarea
                label="Description"
                style={{ fontFamily: "Poppins" }}
                value={props.fieldData?.desc || ""}
                onChange={(e) =>
                  props.setFieldData({
                    ...props.fieldData,
                    desc: e.target.value,
                  })
                }
                required
              />
              <Textarea
                label="OG Description"
                style={{ fontFamily: "Poppins" }}
                value={props.fieldData?.og_desc || ""}
                onChange={(e) =>
                  props.setFieldData({
                    ...props.fieldData,
                    og_desc: e.target.value,
                  })
                }
              />
              <Autocomplete
                multiple
                id="tags-filled"
                // options={top100Films.map((option) => option.title)}
                options={[]}
                freeSolo
                defaultValue={props.fieldData?.keywords}
                onChange={(e, v, r) => {
                  props.setFieldData({
                    ...props.fieldData,
                    keywords: v,
                  });
                }}
                value={props.fieldData.keywords}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        variant="outlined"
                        label={option}
                        key={key}
                        {...tagProps}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Keywords *"
                    sx={{
                      fontFamily: "Poppins",
                      ".MuiAutocomplete-inputRoot": {
                        borderRadius: "8px",
                        fontFamily: "Poppins",
                      },
                      ".MuiInputLabel-root, .MuiChip-label": {
                        fontFamily: "Poppins",
                      },
                    }}
                  />
                )}
              />

              <button
                type="submit"
                className="py-3 font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs rounded-lg bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20"
                style={{ fontFamily: "Poppins" }}
                disabled={props.loading}
              >
                {!props.loading ? "Update" : "Loading..."}
              </button>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default SEOModal;
