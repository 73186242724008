import React, { useEffect, useState } from "react";
import { StickyNavbar } from "../../components/UserNavbar";
import UserFooter from "../../components/UserFooter";
import { Pagination } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import LoadingGif from "../../assets/loader-0.02.gif";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { MdPhone } from "react-icons/md";

function BlogListing() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [blogData, setBlogData] = useState([]);
  const [seoData, setSeoData] = useState({});
  const navigate = useNavigate();
  const { blog_page } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchBlogList();
    fetchSEOData();
  }, [page, blog_page]);

  const fetchBlogList = async () => {
    setLoading(true);
    try {
      const res = await getServices(
        `/api/blog/list_blog?limit=10&page=${blog_page}`
      );
      setLoading(false);
      if (res.responseCode === 200) {
        setBlogData(res.responseData);
      } else {
        setBlogData([]);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const fetchSEOData = async () => {
    try {
      const res = await getServices(`/api/seo/list?seo_type=6`);
      if (res.responseCode === 200) {
        setSeoData(res.responseData[0]);
      } else {
        setSeoData({});
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handlePaginationChange = (e, page) => {
    setPage(page);
    navigate(`/blogs/${page}`);
  };

  return (
    <div>
      <Helmet>
        {/* <title>Blogs - Rao and Rai Real Estate Mangalore</title> */}
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        {seoData.og_title !== "" &&
          seoData.og_title !== null &&
          seoData.og_title !== "null" && (
            <meta property="og:title" content={seoData.og_title} />
          )}
        {seoData.og_description !== "" &&
          seoData.og_description !== null &&
          seoData.og_description !== "null" && (
            <meta property="og:description" content={seoData.og_description} />
          )}
      </Helmet>
      <StickyNavbar>
        <div className="mt-6 md:mt-2 max-w-[1300px] mx-auto px-3">
          <p className="text-3xl font-afacad">Blogs</p>

          {!loading ? (
            <>
              {blogData.data?.length > 0 ? (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                  {blogData.data?.map((item, i) => (
                    <div
                      className="bg-white p-3 rounded-xl flex gap-2 md:items-center cursor-pointer drop-shadow-xl"
                      key={i}
                      onClick={() =>
                        navigate(`/blog_details/${item.blog_code}`)
                      }
                    >
                      <img
                        src={item.image}
                        alt=""
                        className="w-[120px] min-w-[120px] h-[120px] object-cover"
                      />
                      <div className="w-full">
                        <p className="text-right text-xs text-blue-gray-300">
                          {moment(
                            item.publish_date,
                            "MMM DD, YYYY h:mm a"
                          ).format("DD/MM/YYYY h.mmA")}
                        </p>
                        <p
                          className="text-sm sm:text-xl font-afacad"
                          style={{ wordBreak: "break-word" }}
                        >
                          {item.blog_title?.length > 65
                            ? item.blog_title.substring(0, 65) + "..."
                            : item.blog_title}
                        </p>
                        <p
                          className="text-xs md:text-sm text-blue-gray-300 font-afacad inner-html-custo"
                          style={{ wordBreak: "break-word" }}
                          dangerouslySetInnerHTML={{
                            __html:
                              item.description?.length > 65
                                ? item.description.substring(0, 65) + "..."
                                : item.description,
                          }}
                        ></p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full flex flex-col justify-center items-center gap-2 min-h-80 text-center">
                  <p className="text-xl font-semibold">Oops! No Blog Found</p>
                  <p>
                    Looks like we couldn't find any blog that match your search
                    right now. But don't worry, we're here to help you find it!
                  </p>
                  <a
                    href="tel:+918277268334"
                    className="flex items-center bg-[#5e78a3] text-white px-3 py-1 rounded-full font-semibold"
                  >
                    <MdPhone className="mr-2" />
                    Call Now
                  </a>
                  <p>
                    In the meantime, you can{" "}
                    <span className="font-semibold">tweak your filters</span>{" "}
                    and give it another shot.
                  </p>
                </div>
              )}
            </>
          ) : (
            <div className="flex justify-center items-center h-[50vh]">
              <img src={LoadingGif} alt="" className="h-20" />
            </div>
          )}

          {blogData.data?.length > 0 && (
            <div className="flex items-center justify-center gap-4 my-4">
              <div className="flex items-center justify-center gap-2">
                <Pagination
                  count={blogData.totalPages}
                  defaultPage={Number(blog_page)}
                  siblingCount={0}
                  boundaryCount={1}
                  onChange={(e, page) => handlePaginationChange(e, page)}
                  sx={{
                    fontFamily: "Poppins",
                    ".MuiPaginationItem-root": {
                      fontFamily: "Poppins",
                      borderRadius: "8px",
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </StickyNavbar>
      <UserFooter />
    </div>
  );
}

export default BlogListing;
