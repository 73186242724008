import { Button, IconButton } from "@material-tailwind/react";
import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { SlPencil } from "react-icons/sl";
import { useNavigate, useParams } from "react-router-dom";
import { deleteServices, getServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import LoadingGif from "../../assets/loader-0.02.gif";
import moment from "moment/moment";

function AdminListBlogs() {
  const [page, setPage] = useState(1);
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const access_token = sessionStorage.getItem("land-trandes-access-token");
  const { blog_page } = useParams();

  useEffect(() => {
    fetchBolgList();
  }, [page, blog_page]);

  const fetchBolgList = async () => {
    setLoading(true);
    try {
      const res = await getServices(
        `/api/blog/list_blog?limit=10&page=${blog_page}`
      );
      setLoading(false);
      if (res.responseCode === 200) {
        setBlogData(res.responseData);
      } else {
        setBlogData([]);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handlePaginationChange = (e, page) => {
    setPage(page);
    navigate(`/admin/list_blogs/${page}`);
  };

  const handleAddBlogFunc = () => {
    navigate("/admin/manage_blogs", {
      state: { title: "", desc: "", preview_image: null, update: false },
    });
  };

  const handleUpdateBlogFunc = (e, data) => {
    e.stopPropagation();

    navigate("/admin/manage_blogs", {
      state: {
        blog_id: data.blog_id,
        title: data.blog_title,
        desc: data.description,
        preview_image: data.image,
        update: true,
      },
    });
  };

  const handleDeleteBlog = async (e, data) => {
    e.stopPropagation();

    if (window.confirm("Do you want delete this blog?")) {
      try {
        const res = await deleteServices(
          `/api/blog/delete_blog?blog_id=${data.blog_id}`,
          access_token
        );
        if (res.responseCode === 200) {
          toast.success("Blog deleted...");

          if (blogData.data?.length > 1) {
            fetchBolgList();
          } else {
            setPage(page - 1);
            fetchBolgList();
          }
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-2xl sm:text-4xl">Manage Blogs</p>
          <Button
            className="font-semibold"
            style={{ fontFamily: "Poppins" }}
            onClick={handleAddBlogFunc}
          >
            Add Blog
          </Button>
        </div>

        {!loading ? (
          <>
            {blogData.data?.length > 0 ? (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                {blogData.data?.map((item, i) => (
                  <div
                    className="bg-white p-3 rounded-xl flex gap-2 md:items-center cursor-pointer"
                    key={i}
                    onClick={() =>
                      navigate(`/admin/blog_details/${item.blog_code}`)
                    }
                  >
                    <img
                      src={item.image}
                      alt=""
                      className="w-[120px] min-w-[120px] h-[120px] object-cover"
                    />
                    <div className="w-full">
                      <p className="text-right text-xs text-blue-gray-300">
                        {moment(
                          item.publish_date,
                          "MMM DD, YYYY h:mm a"
                        ).format("DD/MM/YYYY h.mmA")}
                      </p>
                      <p
                        className="text-sm sm:text-xl font-afacad"
                        style={{ wordBreak: "break-word" }}
                      >
                        {item.blog_title?.length > 65
                          ? item.blog_title.substring(0, 65) + "..."
                          : item.blog_title}
                      </p>
                      <p
                        className="text-xs md:text-sm text-blue-gray-200 font-afacad inner-html-custo"
                        style={{ wordBreak: "break-word" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            item.description?.length > 65
                              ? item.description.substring(0, 65) + "..."
                              : item.description,
                        }}
                      ></p>
                      <div className="flex justify-end items-center gap-2">
                        <IconButton
                          size="sm"
                          className="bg-white"
                          onClick={(e) => handleUpdateBlogFunc(e, item)}
                        >
                          <SlPencil className="font-semibold text-black text-[17px]" />
                        </IconButton>
                        <IconButton
                          size="sm"
                          className="bg-white"
                          onClick={(e) => handleDeleteBlog(e, item)}
                        >
                          <MdDeleteOutline className="font-semibold text-black text-[17px]" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center flex flex-col justify-center items-center h-[75vh]">
                <p className="font-afacad text-9xl font-semibold">404</p>
                <p className="font-afacad text-3xl font-semibold">
                  There's nothing here!
                </p>
                <p className="font-afacad text-xl">
                  Sorry, the page you were looking for in this blog does not
                  exist.
                </p>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center items-center h-[50vh]">
            <img src={LoadingGif} alt="" className="h-20" />
          </div>
        )}

        {blogData.data?.length > 0 && (
          <div className="flex items-center justify-center gap-4 mt-4">
            <div className="flex items-center justify-center gap-2">
              <Pagination
                count={blogData.totalPages}
                defaultPage={Number(blog_page)}
                siblingCount={0}
                boundaryCount={1}
                onChange={(e, page) => handlePaginationChange(e, page)}
                sx={{
                  fontFamily: "Poppins",
                  ".MuiPaginationItem-root": {
                    fontFamily: "Poppins",
                    borderRadius: "8px",
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AdminListBlogs;
