import {
  Button,
  Input,
  Option,
  Select,
  Textarea,
} from "@material-tailwind/react";
import { Autocomplete, Divider, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getServices, postServices } from "../../apiServices/apiServices";
import { useNavigate } from "react-router-dom";
import { IoIosPlay } from "react-icons/io";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function AddPropertyPage() {
  const [selectedItem, setSelectedItems] = useState({
    property_type: null,
    property_category: null,
    property_label: null,
    parking: null,
    interior_status: null,
    area_type: null,
    terrace_type: null,
  });
  const [fieldData, setFieldData] = useState({
    property_name: "",
    property_desc: "",
    url: "",
    sqrt: "",
    price_sqrt: "",
    bedrrom: "",
    bathrooms: "",
    balcony: "",
    year_built: "",
    garden: "",
    city: "",
    location: "",
    pin_code: "",
    total_price: "",
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fieldAmenities, setFieldAmenities] = useState([]);
  const [amenitiesOptions, setAmenitiesOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const access_token = sessionStorage.getItem("land-trandes-access-token");
  const navigate = useNavigate();
  const [characterLimit] = useState(100000);
  const [descCount, setDescCount] = useState("");

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        // { indent: "-1" },
        // { indent: "+1" },
      ],
      [{ align: [] }],
      // ["link", "image"],
      // ["clean"],
    ],
  };

  useEffect(() => {
    fetchAmenitiesData();
  }, []);

  const fetchAmenitiesData = async () => {
    try {
      const res = await getServices(
        `/api/amenities/list_amenity`,
        access_token
      );
      if (res.responseCode === 200) {
        setAmenitiesOptions(res.responseData);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   const maxImageSize = 5 * 1024 * 1024; // 5MB in bytes
  //   const maxVideoSize = 210 * 1024 * 1024; // 210MB in bytes

  //   const filteredFiles = files.filter((file) => {
  //     if (file.type.startsWith("image/")) {
  //       return file.size <= maxImageSize;
  //     } else if (file.type.startsWith("video/")) {
  //       return file.size <= maxVideoSize;
  //     }
  //     return false; // Exclude other file types
  //   });

  //   setSelectedFiles((prevFiles) => [...prevFiles, ...filteredFiles]);

  //   // Optionally, you can notify the user if some files were too large
  //   const rejectedFiles = files.filter((file) => {
  //     if (file.type.startsWith("image/")) {
  //       return file.size > maxImageSize;
  //     } else if (file.type.startsWith("video/")) {
  //       return file.size > maxVideoSize;
  //     }
  //     return true; // Reject other file types
  //   });

  //   if (rejectedFiles.length > 0) {
  //     alert(
  //       "Some files were too large and have not been added. Maximum file size is 5MB for images and 200MB for videos."
  //     );
  //   }
  // };

  // const handleFileChange = (event) => {
  //   const allowedTypes = [
  //     "image/jpeg",
  //     "image/jpg",
  //     "image/png",
  //     "image/tiff",
  //     "image/tif",
  //     "image/svg+xml",
  //     "image/webp",
  //     "image/heif",
  //     "image/heic",
  //     "image/avif",
  //   ];
  //   const maxSize = 2 * 1024 * 1024; // 2MB in bytes

  //   const files = Array.from(event.target.files);
  //   const validFiles = files.filter((file) => {
  //     if (!allowedTypes.includes(file.type)) {
  //       alert(`File type ${file.type} is not supported.`);
  //       return false;
  //     }
  //     if (file.size > maxSize) {
  //       alert(`File size ${file.name} exceeds 2MB.`);
  //       return false;
  //     }
  //     return true;
  //   });

  //   setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
  // };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const maxImageSize = 5 * 1024 * 1024; // 5MB in bytes
    const maxVideoSize = 210 * 1024 * 1024; // 210MB in bytes

    const filteredFiles = files.filter((file) => {
      if (file.type.startsWith("image/")) {
        return file.size <= maxImageSize;
      } else if (file.type.startsWith("video/")) {
        return file.size <= maxVideoSize;
      }
      return false; // Exclude other file types
    });

    setSelectedFiles((prevFiles) => [...prevFiles, ...filteredFiles]);

    // Optionally, you can notify the user if some files were too large
    const rejectedFiles = files.filter((file) => {
      if (file.type.startsWith("image/")) {
        return file.size > maxImageSize;
      } else if (file.type.startsWith("video/")) {
        return file.size > maxVideoSize;
      }
      return true; // Reject other file types
    });

    if (rejectedFiles.length > 0) {
      alert(
        "Some files were too large and have not been added. Maximum file size is 5MB for images and 200MB for videos."
      );
    }
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const hasImage = selectedFiles.some((file) =>
      file.type.startsWith("image/")
    );

    if (!hasImage) {
      setLoading(false);
      alert("You must upload at least one image.");
      return;
    }

    const formData = new FormData();

    formData.append("property_type", selectedItem.property_type);
    formData.append("property_name", fieldData.property_name);
    formData.append("description", fieldData.property_desc);
    formData.append("url", fieldData.url);
    formData.append("category", selectedItem.property_category);
    formData.append("sqft", fieldData.sqrt);
    formData.append("location", fieldData.location);
    formData.append("price_sqft", fieldData.price_sqrt);
    formData.append("label", selectedItem.property_label);
    // formData.append("city", fieldData.city);
    // formData.append("pincode", fieldData.pin_code);
    formData.append("price", fieldData.total_price);
    formData.append("area_type", selectedItem.area_type ?? "");
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("file", selectedFiles[i]);
    }
    for (let i = 0; i < fieldAmenities.length; i++) {
      formData.append("amenity_id", fieldAmenities[i].amenity_id);
    }

    if (selectedItem.property_type === "1") {
      formData.append("bedroom", fieldData.bedrrom);
      formData.append("bathroom", fieldData.bathrooms);
      formData.append("balcony", fieldData.balcony);
      formData.append("interior_status", selectedItem.interior_status ?? "");
      formData.append("parking", selectedItem.parking);
      formData.append("year_built", fieldData.year_built);
      formData.append("terrace", fieldData.garden);
      formData.append("terrace_type", selectedItem.terrace_type ?? "");
    } else if (selectedItem.property_type === "2") {
      formData.append("balcony", fieldData.balcony);
      formData.append("interior_status", selectedItem.interior_status);
      formData.append("parking", selectedItem.parking);
      formData.append("year_built", fieldData.year_built);
      formData.append("terrace", fieldData.garden);
      formData.append("terrace_type", selectedItem.terrace_type ?? "");
    }

    if (
      selectedItem.property_type === "1" ||
      selectedItem.property_type === "2"
    ) {
      if (selectedItem.property_label && selectedItem.parking) {
        try {
          const res = await postServices(
            `/api/property/add_property`,
            formData,
            access_token
          );
          setLoading(false);
          if (res.responseCode === 200) {
            navigate("/admin/dashboard");
            toast.success("Property added...");
          } else {
            toast.error(res.responseMessage);
          }
        } catch (error) {
          setLoading(false);
          toast.error(error);
        }
      } else {
        setLoading(false);
        alert("Please select a Type and Parking.");
      }
    } else {
      if (selectedItem.property_label) {
        try {
          const res = await postServices(
            `/api/property/add_property`,
            formData,
            access_token
          );
          setLoading(false);
          if (res.responseCode === 200) {
            navigate("/admin/dashboard");
            toast.success("Property added...");
          } else {
            toast.error(res.responseMessage);
          }
        } catch (error) {
          setLoading(false);
          toast.error(error);
        }
      } else {
        setLoading(false);
        alert("Please select a Type.");
      }
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between mb-3">
        <p className="font-semibold text-4xl">Add Property</p>
      </div>

      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3"
      >
        <Select
          label="Property Type"
          style={{ fontFamily: "Poppins" }}
          onChange={(e) => {
            setSelectedItems({
              ...selectedItem,
              property_type: e,
              property_label: null,
              parking: null,
              interior_status: null,
            });
            setFieldData({
              ...fieldData,
              sqrt: "",
              price_sqrt: "",
              bedrrom: "",
              bathrooms: "",
              balcony: "",
              year_built: "",
              garden: "",
              total_price: "",
            });
          }}
        >
          <Option value="1" style={{ fontFamily: "Poppins" }}>
            Residential
          </Option>
          <Option value="2" style={{ fontFamily: "Poppins" }}>
            Commercial
          </Option>
          <Option value="3" style={{ fontFamily: "Poppins" }}>
            Land/Plots
          </Option>
        </Select>
        <Select
          label="Property Category"
          style={{ fontFamily: "Poppins" }}
          onChange={(e) =>
            setSelectedItems({ ...selectedItem, property_category: e })
          }
        >
          <Option value="1" style={{ fontFamily: "Poppins" }}>
            Buy
          </Option>
          <Option value="2" style={{ fontFamily: "Poppins" }}>
            Rent / Lease
          </Option>
        </Select>
        <Input
          label="Property Name"
          value={fieldData.property_name}
          onChange={(e) =>
            setFieldData({ ...fieldData, property_name: e.target.value })
          }
          style={{ fontFamily: "Poppins" }}
          required
        />
        <div className="col-span-1 sm:col-span-2 lg:col-span-3">
          {/* <div className="md:mb-12 p-4 rounded-lg border border-gray-300 h-60">
            <div className="relative h-full"> */}
          <ReactQuill
            onChange={(content) => {
              const sanitizedContent = content === "<p><br></p>" ? "" : content;
              setFieldData({
                ...fieldData,
                property_desc: sanitizedContent,
              });
              setDescCount(sanitizedContent);
            }}
            value={fieldData.property_desc}
            placeholder="Write a description..."
            modules={modules}
            className="mb-12 rounded-lg h-32"
          />
          {/* </div>
        </div> */}
        </div>
        <div className="flex justify-end -mt-2 col-span-1 sm:col-span-2 lg:col-span-3">
          <p className="text-sm">
            {descCount.length} / {characterLimit}
          </p>
        </div>

        <Input
          type="url"
          label="YouTube Video URL"
          value={fieldData.url}
          onChange={(e) => setFieldData({ ...fieldData, url: e.target.value })}
          style={{ fontFamily: "Poppins" }}
          required={false}
        />

        {selectedItem.property_type && selectedItem.property_category && (
          <>
            {/* divider */}
            <div className="flex items-center col-span-1 sm:col-span-2 lg:col-span-3">
              <h1 className="text-[16px] md:text-[22px] font-normal text-[#b1bec5] mr-3">
                Basics
              </h1>
              <Divider
                className="flex-1 text-[#b0bec5]"
                style={{ borderBottomWidth: "2px" }}
              />
            </div>

            {selectedItem.property_type === "1" && (
              <Select
                label="Type"
                style={{ fontFamily: "Poppins" }}
                onChange={(e) =>
                  setSelectedItems({ ...selectedItem, property_label: e })
                }
              >
                <Option value="1" style={{ fontFamily: "Poppins" }}>
                  1BHK
                </Option>
                <Option value="2" style={{ fontFamily: "Poppins" }}>
                  2BHK
                </Option>
                <Option value="3" style={{ fontFamily: "Poppins" }}>
                  3BHK
                </Option>
                <Option value="4" style={{ fontFamily: "Poppins" }}>
                  3BHK+
                </Option>
              </Select>
            )}

            {selectedItem.property_type === "2" && (
              <Select
                label="Type"
                style={{ fontFamily: "Poppins" }}
                onChange={(e) =>
                  setSelectedItems({ ...selectedItem, property_label: e })
                }
              >
                <Option value="1" style={{ fontFamily: "Poppins" }}>
                  Industrial Building
                </Option>
                <Option value="2" style={{ fontFamily: "Poppins" }}>
                  Industrial Shed
                </Option>
                <Option value="3" style={{ fontFamily: "Poppins" }}>
                  Office Space
                </Option>
                <Option value="4" style={{ fontFamily: "Poppins" }}>
                  Shop/Showroom
                </Option>
                <Option value="5" style={{ fontFamily: "Poppins" }}>
                  Warehouse/Godown
                </Option>
              </Select>
            )}

            {selectedItem.property_type === "3" && (
              <Select
                label="Type"
                style={{ fontFamily: "Poppins" }}
                onChange={(e) =>
                  setSelectedItems({ ...selectedItem, property_label: e })
                }
              >
                <Option value="1" style={{ fontFamily: "Poppins" }}>
                  Agricultural
                </Option>
                <Option value="2" style={{ fontFamily: "Poppins" }}>
                  Commercial
                </Option>
                <Option value="3" style={{ fontFamily: "Poppins" }}>
                  Residential
                </Option>
              </Select>
            )}

            <div className="flex custom-input-area">
              <Input
                label="Area"
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                value={fieldData.sqrt}
                onChange={(e) =>
                  setFieldData({ ...fieldData, sqrt: e.target.value })
                }
                style={{ fontFamily: "Poppins" }}
                className="rounded-r-none"
                required
              />
              <Select
                label="Area Type"
                style={{ fontFamily: "Poppins" }}
                className="rounded-l-none"
                onChange={(e) =>
                  setSelectedItems({ ...selectedItem, area_type: e })
                }
              >
                <Option value="1" style={{ fontFamily: "Poppins" }}>
                  Acres
                </Option>
                <Option value="2" style={{ fontFamily: "Poppins" }}>
                  Cents
                </Option>
                <Option value="3" style={{ fontFamily: "Poppins" }}>
                  Sqft
                </Option>
              </Select>
            </div>
            {/* <Input
              label="Price per Sqft"
              type="number"
              value={fieldData.price_sqrt}
              onChange={(e) =>
                setFieldData({ ...fieldData, price_sqrt: e.target.value })
              }
              style={{ fontFamily: "Poppins" }}
              required={false}
            /> */}
            <Input
              label="Total Price"
              type="text"
              value={fieldData.total_price}
              onChange={(e) =>
                setFieldData({ ...fieldData, total_price: e.target.value })
              }
              style={{ fontFamily: "Poppins" }}
              required={true}
            />

            {selectedItem.property_type === "1" && (
              <>
                <Input
                  label="Bedrooms"
                  type="number"
                  value={fieldData.bedrrom}
                  onChange={(e) =>
                    setFieldData({ ...fieldData, bedrrom: e.target.value })
                  }
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  style={{ fontFamily: "Poppins" }}
                  required
                />
                <Input
                  label="Bathrooms"
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  value={fieldData.bathrooms}
                  onChange={(e) =>
                    setFieldData({ ...fieldData, bathrooms: e.target.value })
                  }
                  style={{ fontFamily: "Poppins" }}
                  required
                />
              </>
            )}

            {(selectedItem.property_type === "1" ||
              selectedItem.property_type === "2") && (
              <>
                <Input
                  label="Balconies"
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  value={fieldData.balcony}
                  onChange={(e) =>
                    setFieldData({ ...fieldData, balcony: e.target.value })
                  }
                  style={{ fontFamily: "Poppins" }}
                  required={selectedItem.property_type === "1" ? true : false}
                />

                <Select
                  label="Interior Status"
                  style={{ fontFamily: "Poppins" }}
                  onChange={(e) =>
                    setSelectedItems({ ...selectedItem, interior_status: e })
                  }
                >
                  <Option value="1" style={{ fontFamily: "Poppins" }}>
                    Furnished
                  </Option>
                  <Option value="2" style={{ fontFamily: "Poppins" }}>
                    Semi furnished
                  </Option>
                  <Option value="3" style={{ fontFamily: "Poppins" }}>
                    Unfurnished
                  </Option>
                </Select>

                <Input
                  label="Year Built"
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  value={fieldData.year_built}
                  onChange={(e) =>
                    setFieldData({ ...fieldData, year_built: e.target.value })
                  }
                  style={{ fontFamily: "Poppins" }}
                  required
                />

                <div className="flex custom-input-area">
                  <Input
                    label="Open Garden/Terrace"
                    type="number"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    value={fieldData.garden}
                    onChange={(e) =>
                      setFieldData({
                        ...fieldData,
                        garden: e.target.value,
                      })
                    }
                    style={{ fontFamily: "Poppins" }}
                    className="rounded-r-none"
                    required={selectedItem.property_type === "1" ? true : false}
                  />
                  <Select
                    label="Area Type"
                    style={{ fontFamily: "Poppins" }}
                    className="rounded-l-none"
                    onChange={(e) =>
                      setSelectedItems({ ...selectedItem, terrace_type: e })
                    }
                  >
                    <Option value="1" style={{ fontFamily: "Poppins" }}>
                      Acres
                    </Option>
                    <Option value="2" style={{ fontFamily: "Poppins" }}>
                      Cents
                    </Option>
                    <Option value="3" style={{ fontFamily: "Poppins" }}>
                      Sqft
                    </Option>
                  </Select>
                </div>
              </>
            )}

            {(selectedItem.property_type === "1" ||
              selectedItem.property_type === "2") && (
              <Select
                label="Parking"
                style={{ fontFamily: "Poppins" }}
                onChange={(e) =>
                  setSelectedItems({ ...selectedItem, parking: e })
                }
                value={selectedItem.parking}
              >
                <Option value="1" style={{ fontFamily: "Poppins" }}>
                  Carport
                </Option>
                <Option value="2" style={{ fontFamily: "Poppins" }}>
                  Garage - attached
                </Option>
                <Option value="3" style={{ fontFamily: "Poppins" }}>
                  Garage - detached
                </Option>
                <Option value="4" style={{ fontFamily: "Poppins" }}>
                  Off-street
                </Option>
                <Option value="5" style={{ fontFamily: "Poppins" }}>
                  On-street
                </Option>
              </Select>
            )}

            {/* divider */}
            {/* {(selectedItem.property_type === "1" ||
              selectedItem.property_type === "2") && (
              <> */}
            <div className="flex items-center col-span-1 sm:col-span-2 lg:col-span-3">
              <h1 className="text-[16px] md:text-[22px] font-normal text-[#b1bec5] mr-3">
                Amenities
              </h1>
              <Divider
                className="flex-1 text-[#b0bec5]"
                style={{ borderBottomWidth: "2px" }}
              />
            </div>

            <div className="col-span-1 sm:col-span-2 lg:col-span-3">
              <Autocomplete
                multiple
                id="multiple-limit-tags"
                options={amenitiesOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => option.amenity_name}
                onChange={(e, v) => {
                  setFieldAmenities(v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Amenities"
                    placeholder="Amenities"
                  />
                )}
                sx={{
                  fontFamily: "Poppins",
                  ".MuiAutocomplete-inputRoot": {
                    borderRadius: "8px",
                    fontFamily: "Poppins",
                  },
                  ".MuiInputLabel-root, .MuiChip-label": {
                    fontFamily: "Poppins",
                  },
                }}
              />
            </div>
            {/* </>
            )} */}

            {/* divider */}
            <div className="flex items-center col-span-1 sm:col-span-2 lg:col-span-3">
              <h1 className="text-[16px] md:text-[22px] font-normal text-[#b1bec5] mr-3">
                Location
              </h1>
              <Divider
                className="flex-1 text-[#b0bec5]"
                style={{ borderBottomWidth: "2px" }}
              />
            </div>
            <Textarea
              label="Location"
              style={{ fontFamily: "Poppins" }}
              value={fieldData.location}
              onChange={(e) =>
                setFieldData({ ...fieldData, location: e.target.value })
              }
              required
            />
            {/* <Input
              label="City"
              type="text"
              value={fieldData.city}
              onChange={(e) =>
                setFieldData({ ...fieldData, city: e.target.value })
              }
              style={{ fontFamily: "Poppins" }}
              required
            /> */}
            {/* <Input
              label="Pin code"
              type="number"
              value={fieldData.pin_code}
              onChange={(e) =>
                setFieldData({ ...fieldData, pin_code: e.target.value })
              }
              style={{ fontFamily: "Poppins" }}
              required
            /> */}

            {/* divider */}
            <div className="flex items-center col-span-1 sm:col-span-2 lg:col-span-3">
              <h1 className="text-[16px] md:text-[22px] font-normal text-[#b1bec5] mr-3">
                Images
              </h1>
              <Divider
                className="flex-1 text-[#b0bec5]"
                style={{ borderBottomWidth: "2px" }}
              />
            </div>
            <div className="col-span-1 sm:col-span-2 lg:col-span-3">
              <Input
                label="Images"
                type="file"
                onChange={handleFileChange}
                accept="image/png, image/jpeg, image/jpg, image/tiff, image/tif, image/svg+xml, image/webp, image/heif, image/heic, image/avif, video/mp4"
                multiple
                style={{ fontFamily: "Poppins" }}
                required
              />
              <p className="text-red-500 text-center text-sm mt-2">
                .jpg, .jpeg, .png, .heic, .heif, .tiff, .tif, .svg, .webp,
                .avif, .mp4. Maximum file size is 5MB for images and 200MB for
                videos. *
              </p>
              {/* <p className="text-red-500 text-center text-sm mt-2">
                Supported formats: .jpg, .jpeg, .png, .heic Maximum size: 2MB. *
              </p> */}
              <div
                style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}
              >
                {selectedFiles.map((file, index) => {
                  const url = URL.createObjectURL(file);
                  return (
                    <div
                      key={index}
                      style={{ margin: "10px", position: "relative" }}
                    >
                      {file.type.startsWith("image/") ? (
                        <img
                          src={url}
                          alt={`preview ${index}`}
                          style={{
                            width: "120px",
                            height: "80px",
                            objectFit: "cover",
                          }}
                          className="border border-gray-500 rounded-md"
                        />
                      ) : (
                        <div className="relative">
                          <video
                            src={url}
                            // controls
                            style={{
                              width: "120px",
                              height: "80px",
                              objectFit: "cover",
                            }}
                            className="border border-gray-500 rounded-md"
                          />

                          <div className="absolute inset-0 flex items-center justify-center">
                            <IoIosPlay className="text-black p-1 text-2xl bg-white rounded-full" />
                          </div>
                        </div>
                      )}
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(index)}
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          cursor: "pointer",
                          fontSize: "12px",
                          padding: "0 5px",
                        }}
                      >
                        X
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            <Button
              type="submit"
              style={{ fontFamily: "Poppins" }}
              disabled={loading}
            >
              {!loading ? "Save" : "Loading..."}
            </Button>
          </>
        )}
      </form>
    </div>
  );
}

export default AddPropertyPage;
