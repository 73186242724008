import { Button, Input } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import { postServices, putServices } from "../../apiServices/apiServices";

function AdminManageBlog() {
  const [blogTitle, setBlogTitle] = useState("");
  const [blogDesc, setBlogDesc] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [descCount, setDescCount] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation().state;
  const navigate = useNavigate();
  const access_token = sessionStorage.getItem("land-trandes-access-token");

  useEffect(() => {
    setBlogTitle(location.title);
    setBlogDesc(location.desc);
    setImagePreviewUrl(location.preview_image);
  }, [location]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
    ],
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    if (blogDesc.length > 0 && blogDesc.length <= 100000) {
      formData.append("blog_title", blogTitle);
      formData.append("description", blogDesc);
      formData.append("image", selectedImage);

      try {
        const res = await postServices(
          `/api/blog/add_blog`,
          formData,
          access_token
        );
        setLoading(false);
        if (res.responseCode === 200) {
          navigate(-1);
          toast.success("New blog added...");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    } else {
      if (blogDesc.length === 0) {
        alert("Description should not be empty.");
      } else if (blogDesc.length > 100000) {
        alert(
          "Description is too long. It should be less than 100000 characters."
        );
      } else {
        alert("Description is invalid.");
      }
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    if (blogDesc.length > 0 && blogDesc.length <= 100000) {
      formData.append("blog_title", blogTitle);
      formData.append("description", blogDesc);
      formData.append("image", selectedImage);

      try {
        const res = await putServices(
          `/api/blog/edit_blog?blog_id=${location.blog_id}`,
          formData,
          access_token
        );
        setLoading(false);
        if (res.responseCode === 200) {
          toast.success("Blog updated...");
          navigate(-1);
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    } else {
      if (blogDesc.length === 0) {
        alert("Description should not be empty.");
      } else if (blogDesc.length > 100000) {
        alert(
          "Description is too long. It should be less than 100000 characters."
        );
      } else {
        alert("Description is invalid.");
      }
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-2xl sm:text-4xl">Add Blog</p>
        </div>
        <form
          className="flex flex-col gap-4"
          onSubmit={location.update ? handleUpdateSubmit : handleAddSubmit}
        >
          <div>
            <Input
              label="Blog Title"
              style={{ fontFamily: "Poppins" }}
              maxLength={750}
              value={blogTitle}
              onChange={(e) => setBlogTitle(e.target.value)}
              required
            />
            <div className="flex justify-end">
              <p className="text-sm">
                {blogTitle.length} / {750}
              </p>
            </div>
          </div>
          <div>
            <ReactQuill
              onChange={(content) => {
                const sanitizedContent =
                  content === "<p><br></p>" ? "" : content;
                setBlogDesc(sanitizedContent);
                setDescCount(sanitizedContent);
              }}
              value={blogDesc}
              placeholder="Write a description..."
              modules={modules}
              className="sm:mb-12 rounded-lg sm:h-32"
            />
            <div className="flex justify-end">
              <p className="text-sm">
                {descCount.length} / {100000}
              </p>
            </div>
          </div>
          <div>
            <Input
              label="Blog Image"
              type="file"
              onChange={handleImageChange}
              accept="image/png, image/jpeg, image/jpg, image/tiff, image/tif, image/svg+xml, image/webp, image/heif, image/heic, image/avif"
              style={{ fontFamily: "Poppins" }}
              required={location.update ? false : true}
            />
            {imagePreviewUrl && (
              <img
                src={imagePreviewUrl}
                alt=""
                className="w-32 h-20 object-cover mt-2 rounded-md"
              />
            )}
          </div>
          <div className="flex justify-end">
            <Button
              type="submit"
              style={{ fontFamily: "Poppins" }}
              className="tracking-widest"
              disabled={loading}
            >
              {loading ? "Loading..." : location.update ? "Update" : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AdminManageBlog;
