import {
  IconButton,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { SlPencil } from "react-icons/sl";
import { getServices, postServices } from "../../apiServices/apiServices";
import SEOModal from "../../components/modal/admin/SEOModal";

const data = [
  {
    label: "Home",
    value: "4",
  },
  {
    label: "Residential",
    value: "1",
  },
  {
    label: "Commercial",
    value: "2",
  },
  {
    label: "Land/Plot",
    value: "3",
  },
  {
    label: "Property Details",
    value: "5",
  },
  {
    label: "Blog list",
    value: "6",
  },
  {
    label: "Blog details",
    value: "7",
  },
];

function ManageSEO() {
  const [activeTab, setActiveTab] = useState("4");
  const [seoData, setSeoData] = useState({});
  const [modal, setModal] = useState({ update_seo: false });
  const [fieldData, setFieldData] = useState({
    title: "",
    desc: "",
    og_title: "",
    og_desc: "",
    keywords: [],
    seo_type: "",
  });
  const [loading, setLoading] = useState(false);
  const access_token = sessionStorage.getItem("land-trandes-access-token");

  useEffect(() => {
    fetchSEOData();
  }, [activeTab]);

  const fetchSEOData = async () => {
    try {
      const res = await getServices(`/api/seo/list?seo_type=${activeTab}`);
      if (res.responseCode === 200) {
        setSeoData(res.responseData[0]);
      } else {
        setSeoData({});
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUpdateSEOModal = () => {
    setModal({ ...modal, update_seo: true });
    setFieldData({
      ...fieldData,
      title: seoData.title,
      desc: seoData.description,
      og_title: seoData.og_title,
      og_desc: seoData.og_description,
      keywords: seoData.keywords,
      seo_type: seoData.seo_type,
    });
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (fieldData.keywords?.length > 0) {
      try {
        const res = await postServices(
          `/api/seo/add`,
          {
            title: fieldData.title,
            description: fieldData.desc,
            og_title: fieldData.og_title,
            og_description: fieldData.og_desc,
            seo_type: fieldData.seo_type,
            keywords: fieldData.keywords,
          },
          access_token
        );

        setLoading(false);
        if (res.responseCode === 200) {
          setModal({ ...modal, update_seo: false });
          toast.success("SEO updated...");
          fetchSEOData();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    } else {
      setLoading(false);
      alert("Please enter any one keyword.");
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-2xl sm:text-4xl">Manage SEO</p>
        </div>
        <Tabs value={activeTab} onChange={setActiveTab}>
          <div className="flex flex-col seo-head-scroll">
            <TabsHeader className="flex justify-start items-center bg-gray-100 gap-2 sm:gap-4 sm:px-4">
              {data.map(({ label, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => {
                    setActiveTab(value);
                  }}
                  style={{ fontFamily: "Poppins" }}
                  className={`w-fit demo-class rounded-full px-5 sm:px-8 py-2 ${
                    activeTab === value
                      ? "bg-[#f59e2b27] text-[#f59d2b]"
                      : "bg-transparent"
                  }`}
                >
                  {label}
                </Tab>
              ))}
            </TabsHeader>

            <TabsBody>
              {data.map(({ value }) => (
                <TabPanel
                  key={value}
                  value={value}
                  style={{ fontFamily: "Poppins" }}
                >
                  <div className="flex justify-end items-center">
                    <IconButton
                      size="sm"
                      className="bg-white"
                      onClick={handleUpdateSEOModal}
                    >
                      <SlPencil className="font-semibold text-black text-[17px]" />
                    </IconButton>
                  </div>
                  <div className="font-afacad text-black md:text-lg">
                    {seoData && (
                      <p>
                        Page Title:{" "}
                        <span className="font-medium">{seoData.title}</span>
                      </p>
                    )}
                    {seoData && (
                      <p>
                        Description:{" "}
                        <span className="font-medium">
                          {seoData.description}
                        </span>
                      </p>
                    )}
                    {seoData && (
                      <p>
                        Keywords:{" "}
                        {seoData.keywords?.map((item, i) => (
                          <li className="font-medium pl-10" key={i}>
                            {item}
                          </li>
                        ))}
                      </p>
                    )}
                    {seoData.og_title !== "" &&
                      seoData.og_title !== null &&
                      seoData.og_title !== "null" && (
                        <p>
                          OG Title:{" "}
                          <span className="font-medium">
                            {seoData.og_title}
                          </span>
                        </p>
                      )}
                    {seoData.og_description !== "" &&
                      seoData.og_description !== null &&
                      seoData.og_description !== "null" && (
                        <p>
                          OG Description:{" "}
                          <span className="font-medium">
                            {seoData.og_description}
                          </span>
                        </p>
                      )}
                  </div>
                </TabPanel>
              ))}
            </TabsBody>
          </div>
        </Tabs>
      </div>

      <SEOModal
        open={modal.update_seo}
        onClose={() => setModal({ ...modal, update_seo: false })}
        fieldData={fieldData}
        setFieldData={setFieldData}
        handleUpdateSubmit={handleUpdateSubmit}
        loading={loading}
      />
    </>
  );
}

export default ManageSEO;
