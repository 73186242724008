import React from "react";
import Logo from "../assets/new_logo_rao&rai.svg";
import { TiSocialFacebook } from "react-icons/ti";
import { AiFillInstagram } from "react-icons/ai";
import { ImYoutube } from "react-icons/im";
import { MdCall } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";

function UserFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-[#e3f5fe]">
      <div className="max-w-[1300px] mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 pt-8 sm:pt-10 sm:py-10 px-2">
        <div className="py-5 pr-5 pl-5 md:pl-0">
          <img className="w-[300px] " src={Logo} alt="Rai&rao" />
          <p className="pt-4 text-[13px] text-[#857d98]">
            Experience the best of Mangalore's real estate with Rao and Rai Real
            Estate Mangalore - where your property journey begins.
          </p>
          <div className="hidden md:flex gap-3">
            <a
              href="https://www.instagram.com/raoandraireal"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillInstagram className="w-5 h-7 text-sm text-[#857d98]" />
            </a>

            <a
              href="https://www.youtube.com/@LandTrades-x3s"
              target="_blank"
              rel="noreferrer"
            >
              <ImYoutube className="w-5 h-7 text-sm text-[#857d98]" />
            </a>

            <a
              href="https://www.facebook.com/share/GYABzTN6GW1jP5J4/?mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
            >
              <TiSocialFacebook className="w-5 h-7 text-sm text-[#857d98]" />
            </a>
            {/* <FaXTwitter className="w-5 h-7 text-sm text-[#857d98]" /> */}
            {/* <SiTelegram className="w-5 h-7 text-sm text-[#857d98]" /> */}
          </div>
        </div>
        <div className="p-5 block">
          <h1 className="text-[#3c3454] font-semibold text-[16px]">
            Quick Links
          </h1>
          <div className="text-[#857d98] text-[13px]">
            <div>
              <span className="block pt-2">
                <Link to="/residential">Residential</Link>
              </span>
              <span className="block pt-2">
                <Link to="/plot">Land/Plot</Link>
              </span>
              <span className="block pt-2">
                <Link to="/commercial">Commercial</Link>
              </span>
              <span className="block pt-2">
                <Link to="/blogs/1">Blogs</Link>
              </span>
            </div>
          </div>
        </div>
        <div className="p-5">
          <h1 className="text-[#3c3454] hidden md:block font-semibold text-[16px]">
            Company Info
          </h1>
          <div className="text-[#857d98] text-[13px]">
            <a href="tel:+918277268334" className="flex items-center gap-2">
              <MdCall className="flex w-5 h-7 mt-1 text-[#5e78a3]" />
              +91 82772 68334
            </a>
            <a href="tel:+916364451967" className="flex items-center gap-2">
              <MdCall className="flex w-5 h-7 mt-1 text-[#5e78a3]" />
              +91 63644 51967
            </a>

            <a
              href="mailto:contactlandtrades@gmail.com"
              className="flex items-center gap-2"
            >
              <MdEmail className="flex w-5 h-7 mt-[3.5px] text-[#5e78a3]" />
              contactlandtrades@gmail.com
            </a>
            <div className="flex">
              <MdLocationOn className="flex w-5 h-7 mt-[3.5px] text-[#5e78a3]" />
              <p className="block p-2">Blueberry Hills Mangalore</p>
            </div>
          </div>

          <div className="flex md:hidden gap-3">
            <a
              href="https://www.instagram.com/raoandraireal"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillInstagram className="w-5 h-7 text-sm text-[#857d98]" />
            </a>

            <a
              href="https://www.youtube.com/@LandTrades-x3s"
              target="_blank"
              rel="noreferrer"
            >
              <ImYoutube className="w-5 h-7 text-sm text-[#857d98]" />
            </a>

            <a
              href="https://www.facebook.com/share/GYABzTN6GW1jP5J4/?mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
            >
              <TiSocialFacebook className="w-5 h-7 text-sm text-[#857d98]" />
            </a>
          </div>
        </div>
      </div>
      <div className="p-5 flex justify-center items-center text-[#857d98] bg-[#d3effe] text-[12px]">
        <p>© {currentYear} Rao and Rai Real Estate</p>
      </div>
    </div>
  );
}

export default UserFooter;
