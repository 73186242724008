import { Button } from "@material-tailwind/react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React from "react";

function WarningModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <p className="font-semibold">{props.title}</p>
            </div>
            <div className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3">
              <p className="text-center text-red-600">{props.description}</p>
              <div className="flex justify-end items-center gap-2">
                <Button
                  color="green"
                  style={{ fontFamily: "Poppins" }}
                  onClick={props.onClose}
                >
                  No
                </Button>
                <Button
                  color="red"
                  style={{ fontFamily: "Poppins" }}
                  onClick={props.yesBtnClick}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default WarningModal;
