import React from "react";
import Loader from "../assets/loader-0.02.gif"

function Preloader() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="animate-fade-in mb-4">
        <img className="w-36 h-20" src={Loader} alt="Rai&rao"/>
      </div>
      <div className="h-1 w-16 rounded-full animate-loader-line"></div>
    </div>
  );
}

export default Preloader;
