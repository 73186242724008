import { Button, Input } from "@material-tailwind/react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React from "react";
import { IoClose } from "react-icons/io5";

function StatsModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <p className="font-semibold">Update Stats</p>
              <IoClose
                className="cursor-pointer border border-[#000] rounded-full"
                onClick={props.onClose}
              />
            </div>
            <form
              onSubmit={props.handleUpdateStatsSubmit}
              className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3"
            >
              <Input
                label={props.fieldStatsData?.count_desc}
                type="number"
                style={{ fontFamily: "Poppins" }}
                value={props.fieldStatsData?.count}
                onChange={(e) =>
                  props.setFieldStatsData({
                    ...props.fieldStatsData,
                    count: e.target.value,
                  })
                }
                required
              />
              <Button
                type="submit"
                style={{ fontFamily: "Poppins" }}
                disabled={props.loading}
              >
                {!props.loading ? "Update" : "Loading..."}
              </Button>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default StatsModal;
