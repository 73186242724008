import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/user/Dashboard";
import Plots from "./pages/user/Plots";
import Residential from "./pages/user/Residential";
import Commercial from "./pages/user/Commercial";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AdminLayout from "./components/layouts/AdminLayout";
import AdminLogin from "./pages/admin/AdminLogin";
import "./styles.scss";
import AdminDashboard from "./pages/admin/AdminDashboard";
import AdminManageAmenities from "./pages/admin/AdminManageAmenities";
import PropertyDetailsPage from "./pages/user/PropertyDetailsPage";
import { Toaster } from "react-hot-toast";
import AdminPropertyDetailsPage from "./pages/admin/AdminPropertyDetailsPage";
import AddPropertyPage from "./pages/admin/AddPropertyPage";
import UpdatePropertyPage from "./pages/admin/UpdatePropertyPage";
import AdminBannerCounterPage from "./pages/admin/AdminBannerCounterPage";
import AdminListBlogs from "./pages/admin/AdminListBlogs";
import AdminManageBlog from "./pages/admin/AdminManageBlog";
import AdminBlogDetailPage from "./pages/admin/AdminBlogDetailPage";
import BlogListing from "./pages/user/BlogListing";
import BlogDetails from "./pages/user/BlogDetails";
import AdminListDynamicOverview from "./pages/admin/AdminListDynamicOverview";
import ManageSEO from "./pages/admin/ManageSEO";
import AdminListLookingFor from "./pages/admin/AdminListLookingFor";
import AdminManageLookingFor from "./pages/admin/AdminManageLookingFor";
import LookingForList from "./pages/user/LookingForList";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/plot" element={<Plots />} />
          <Route path="/residential" element={<Residential />} />
          <Route path="/commercial" element={<Commercial />} />
          <Route
            path="/property_details/:prop_code"
            element={<PropertyDetailsPage />}
          />
          <Route path="/blogs/:blog_page" element={<BlogListing />} />
          <Route path="/blog_details/:blog_code" element={<BlogDetails />} />
          <Route path="/looking_for/:page" element={<LookingForList />} />

          {/* admin route */}
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route
            path="/admin/dashboard"
            element={
              <AdminLayout>
                <AdminDashboard />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_amenities"
            element={
              <AdminLayout>
                <AdminManageAmenities />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/add_property"
            element={
              <AdminLayout>
                <AddPropertyPage />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/update_property"
            element={
              <AdminLayout>
                <UpdatePropertyPage />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/property_details/:prop_code"
            element={
              <AdminLayout>
                <AdminPropertyDetailsPage />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_banner_and_counter"
            element={
              <AdminLayout>
                <AdminBannerCounterPage />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/list_blogs/:blog_page"
            element={
              <AdminLayout>
                <AdminListBlogs />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_blogs"
            element={
              <AdminLayout>
                <AdminManageBlog />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/blog_details/:blog_code"
            element={
              <AdminLayout>
                <AdminBlogDetailPage />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_looking_for/:page"
            element={
              <AdminLayout>
                <AdminListLookingFor />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_buyers_data"
            element={
              <AdminLayout>
                <AdminManageLookingFor />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/list_dynamic_overview"
            element={
              <AdminLayout>
                <AdminListDynamicOverview />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_seo"
            element={
              <AdminLayout>
                <ManageSEO />
              </AdminLayout>
            }
          />

          {/* not found page */}
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </BrowserRouter>

      <Toaster position="bottom-right" />
    </div>
  );
}

export default App;
