import React, { useState, useEffect } from "react";
import { StickyNavbar } from "../../components/UserNavbar";
import { TbRulerMeasure } from "react-icons/tb";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import Preloader from "../../components/Preloader";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import UserFooter from "../../components/UserFooter";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { getServices } from "../../apiServices/apiServices";
import LoadingGif from "../../assets/loader-0.02.gif";
import { MdPhone } from "react-icons/md";
import { FaRegBuilding } from "react-icons/fa6";
import { Helmet } from "react-helmet-async";
import Garden from "../../assets/garden.svg";

const data = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Buy",
    value: "Buy",
  },
  {
    label: "Rent/Lease",
    value: "Rent/Lease",
  },
];

function Residential() {
  const [activeTab, setActiveTab] = useState("All");
  const [loading, setLoading] = useState(true);
  const [contentLoading, setContentLoading] = useState(false);
  const [residentialList, setResidentialData] = useState([]);
  const [filterValue, setFilterValue] = useState(0);
  const [overviewData, setOverviewData] = useState({});
  const [seoData, setSeoData] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    fetchResidetialData();
    fetchOverviewData();
    fetchSEOData();
  }, [activeTab]);

  const fetchResidetialData = async () => {
    setContentLoading(true);
    const category =
      activeTab === "Buy"
        ? "&category=1"
        : activeTab === "Rent/Lease"
        ? "&category=2"
        : "";
    const url = `/api/property/list_property?property_type=1${category}`;

    try {
      const res = await getServices(url);
      setContentLoading(false);
      if (res.responseCode === 200) {
        setResidentialData(res.responseData);
      } else if (res.responseCode === 205) {
        setResidentialData([]);
      } else {
        setResidentialData([]);
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchOverviewData = async () => {
    try {
      const res = await getServices(`/api/overview/list`);
      if (res.responseCode === 200) {
        setOverviewData(res.responseData[1]);
      } else {
        setOverviewData([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchSEOData = async () => {
    try {
      const res = await getServices(`/api/seo/list?seo_type=1`);
      if (res.responseCode === 200) {
        setSeoData(res.responseData[0]);
      } else {
        setSeoData({});
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleFilterChange = async (e) => {
    setFilterValue(e.target.value);
    setContentLoading(true);
    const category =
      activeTab === "Buy"
        ? "&category=1"
        : activeTab === "Rent/Lease"
        ? "&category=2"
        : "";

    const filter =
      e.target.value === 1
        ? "&label=1"
        : e.target.value === 2
        ? "&label=2"
        : e.target.value === 3
        ? "&label=3"
        : e.target.value === 4
        ? "&label=4"
        : "";
    const url = `/api/property/list_property?property_type=1${category}${filter}`;

    try {
      const res = await getServices(url);
      setContentLoading(false);
      if (res.responseCode === 200) {
        setResidentialData(res.responseData);
      } else if (res.responseCode === 205) {
        setResidentialData([]);
      } else {
        setResidentialData([]);
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <div>
      <Helmet>
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        {seoData.og_title !== "" &&
          seoData.og_title !== null &&
          seoData.og_title !== "null" && (
            <meta property="og:title" content={seoData.og_title} />
          )}
        {seoData.og_description !== "" &&
          seoData.og_description !== null &&
          seoData.og_description !== "null" && (
            <meta property="og:description" content={seoData.og_description} />
          )}
      </Helmet>
      <StickyNavbar>
        <div className="max-w-[1300px] mx-auto">
          <Tabs value={activeTab} onChange={setActiveTab}>
            <div className="flex justify-between flex-col sm:flex-row">
              <TabsHeader className="bg-white flex justify-start items-center gap-2 sm:gap-4 mt-4 px-4">
                {data.map(({ label, value }) => (
                  <Tab
                    key={value}
                    value={value}
                    onClick={() => {
                      setActiveTab(value);
                      setFilterValue(0);
                    }}
                    style={{ fontFamily: "Poppins" }}
                    className={`w-fit demo-class rounded-full px-5 sm:px-8 py-2 ${
                      activeTab === value
                        ? "bg-gray-100 text-[#5e78a3]"
                        : "bg-transparent"
                    }`}
                  >
                    {label}
                    {/* {activeTab === value && (
                  <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-[#f77d6d] w-5 h-3 rounded-t-md rotate-45"></div>
                )} */}
                  </Tab>
                ))}
              </TabsHeader>
              <div className="flex justify-end items-end">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel
                    classes={{
                      root: "MuiFormLabel-root css-1jy569b-MuiFormLabel-root-MuiInputLabel-root",
                      focused: "Mui-focused",
                    }}
                    style={{
                      fontFamily: "Poppins",
                      color: "black",
                    }}
                    id="demo-select-small-label"
                  >
                    Filter by
                  </InputLabel>
                  <Select
                    classes={{
                      root: "css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root",
                      outlined: "MuiOutlinedInput-notchedOutline",
                    }}
                    sx={{
                      fontFamily: "Poppins",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        borderWidth: "2px",
                      },
                      "& .MuiMenuItem-root": {
                        fontFamily: "Poppins",
                      },
                      "&.Mui-focused .MuiMenuItem-root": {
                        color: "black",
                      },
                    }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={filterValue}
                    label="Filter by"
                    onChange={handleFilterChange}
                  >
                    <MenuItem style={{ fontFamily: "Poppins" }} value={1}>
                      1 BHK
                    </MenuItem>
                    <MenuItem style={{ fontFamily: "Poppins" }} value={2}>
                      2 BHK
                    </MenuItem>
                    <MenuItem style={{ fontFamily: "Poppins" }} value={3}>
                      3 BHK
                    </MenuItem>
                    <MenuItem style={{ fontFamily: "Poppins" }} value={4}>
                      3+ BHK
                    </MenuItem>
                    <MenuItem style={{ fontFamily: "Poppins" }} value={0}>
                      None
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <TabsBody>
              {!contentLoading ? (
                <>
                  {data.map(({ value }) => (
                    <TabPanel
                      key={value}
                      value={value}
                      style={{ fontFamily: "Poppins" }}
                    >
                      {residentialList.length > 0 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                          {residentialList.map((card, index) => {
                            const imageFiles = card.file?.filter(
                              (file) =>
                                file.endsWith(".jpg") ||
                                file.endsWith(".jpeg") ||
                                file.endsWith(".png") ||
                                file.endsWith(".gif") ||
                                file.endsWith(".webp") ||
                                file.endsWith(".svg") ||
                                file.endsWith(".heif") ||
                                file.endsWith(".heic") ||
                                file.endsWith(".avif")
                            );

                            return (
                              <Link
                                key={index}
                                to={`/property_details/${card.property_code}`}
                                className="shadow-md rounded-lg overflow-hidden relative bg-[#eff2ef] hover:bg-[#e3e3e3]"
                              >
                                <img
                                  src={imageFiles[0]}
                                  alt="Rai&rao"
                                  className="w-full h-64 object-cover rounded-md"
                                />
                                <div className="p-4">
                                  <h3 className="text-[16px] font-normal tracking-[0.5px] uppercase mb-2 text-[#263238]">
                                    {/* {card.property_name} */}
                                    {card.property_name?.length > 32
                                      ? card.property_name.substring(0, 32) +
                                        "..."
                                      : card.property_name}
                                  </h3>
                                  <h3 className="text-[16px] font-medium mb-2 text-[#263238]">
                                    <span className="font-sans">₹</span>
                                    {card.price}
                                    {/* ₹{Number(card.price).toLocaleString("en-IN")} */}
                                  </h3>
                                  <div className="flex gap-4  text-[#263238]">
                                    <p className="flex font-medium text-[12px] gap-1">
                                      <FaRegBuilding className="w-5 h-5 text-[#858585]" />
                                      {card.label === "1"
                                        ? "1BHK"
                                        : card.label === "2"
                                        ? "2BHK"
                                        : card.label === "3"
                                        ? "3BHK"
                                        : card.label === "4"
                                        ? "3BHK+"
                                        : ""}
                                    </p>
                                    <p className="flex font-medium text-[12px] gap-1">
                                      <TbRulerMeasure className="w-5 h-5 text-[#858585]" />
                                      {card.sqft}{" "}
                                      <span className="font-normal text-[12px]">
                                        {card.area_type === "1"
                                          ? "Acres"
                                          : card.area_type === "2"
                                          ? "Cents"
                                          : card.area_type === "3"
                                          ? "Sqft"
                                          : ""}
                                      </span>
                                    </p>
                                    {card.residentialInfo.terrace !== null &&
                                      card.residentialInfo.terrace !== "" &&
                                      card.residentialInfo?.terrace !==
                                        "null" && (
                                        <p className="flex font-medium text-[12px] gap-1">
                                          <img
                                            src={Garden}
                                            className="w-5 h-5 text-[#dadada]"
                                          />
                                          {card.residentialInfo.terrace !==
                                            null &&
                                          card.residentialInfo.terrace !== "" &&
                                          card.residentialInfo?.terrace !==
                                            "null"
                                            ? card.residentialInfo.terrace +
                                              " " +
                                              (card.residentialInfo
                                                .terrace_type === "1"
                                                ? "Acres"
                                                : card.residentialInfo
                                                    .terrace_type === "2"
                                                ? "Cents"
                                                : card.residentialInfo
                                                    .terrace_type === "3"
                                                ? "Sqft"
                                                : "")
                                            : ""}
                                        </p>
                                      )}
                                  </div>
                                </div>

                                <div className="absolute top-2 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                                  <p>
                                    {card.category === "1"
                                      ? "Buy"
                                      : card.category === "2"
                                      ? "Rent/Lease"
                                      : ""}
                                  </p>
                                </div>

                                {card.residentialInfo?.interior_status && (
                                  <div className="absolute top-10 left-2 bg-white px-3 py-1 text-sm rounded-md font-semibold">
                                    <p>
                                      {card.residentialInfo?.interior_status ==
                                      "1"
                                        ? "Furnished"
                                        : card.residentialInfo
                                            ?.interior_status == "2"
                                        ? "Semifurnished"
                                        : card.residentialInfo
                                            ?.interior_status == "3"
                                        ? "Unfurnished"
                                        : ""}
                                    </p>
                                  </div>
                                )}
                              </Link>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="w-full flex flex-col justify-center items-center gap-2 min-h-80 text-center">
                          <p className="text-xl font-semibold">
                            Oops! No Property Found
                          </p>
                          <p>
                            Looks like we couldn't find any properties that
                            match your search right now. But don't worry, we're
                            here to help you find it!
                          </p>
                          <a
                            href="tel:+918277268334"
                            className="flex items-center bg-[#5e78a3] text-white px-3 py-1 rounded-full font-semibold"
                          >
                            <MdPhone className="mr-2" />
                            Call Now
                          </a>
                          <p>
                            In the meantime, you can{" "}
                            <span className="font-semibold">
                              tweak your filters
                            </span>{" "}
                            and give it another shot.
                          </p>
                        </div>
                      )}
                    </TabPanel>
                  ))}
                </>
              ) : (
                <div className="w-full flex justify-center items-center min-h-80">
                  <img src={LoadingGif} alt="" className="h-20" />
                </div>
              )}
            </TabsBody>
          </Tabs>

          {/* home page overview */}
          {overviewData?.description && (
            <>
              <div className="flex items-center pt-0 px-4 mt-3">
                <h1 className="text-[16px] md:text-[25px] font-normal text-[#7a7c7d] mr-3 font-afacad uppercase">
                  Rao and Rai Real Estate - Residential Properties
                </h1>
                <Divider
                  className="flex-1 text-[#b0bec5]"
                  style={{ borderBottomWidth: "2px" }}
                />
              </div>
              <div className="font-afacad description-count pt-2 px-4 pl-4">
                {/* <p className="font-afacad text-3xl font-semibold pb-2 sm:pb-4">
                  Overview
                </p> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: overviewData?.description,
                  }}
                  className="pb-4 inner-html-custom"
                ></div>
              </div>
            </>
          )}
        </div>
      </StickyNavbar>
      <UserFooter />
    </div>
  );
}

export default Residential;
