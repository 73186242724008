import { Button, Input } from "@material-tailwind/react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React from "react";
import { IoClose } from "react-icons/io5";

function BannerModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <p className="font-semibold">Add Banner</p>
              <IoClose
                className="cursor-pointer border border-[#000] rounded-full"
                onClick={props.onClose}
              />
            </div>
            <form
              onSubmit={props.handleBannerSubmit}
              className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3"
            >
              <Input
                label="Choose image"
                type="file"
                style={{ fontFamily: "Poppins" }}
                onChange={props.handleFileChange}
                accept="image/png, image/jpeg, image/jpg, image/tiff, image/tif, image/svg+xml, image/webp, image/heif, image/heic, image/avif"
                required
              />
              <p className="text-red-500 text-center text-sm">
                Supported formats: .jpg, .jpeg, .png, .heic, .heif, .tiff, .tif,
                .svg, .webp, .avif. Maximum size: 5MB. <br />
                Required resolution: 1920x960 pixels *
              </p>
              {props.preview && (
                <div>
                  <img
                    src={props.preview}
                    alt=""
                    className="w-32 h-20 rounded-lg object-cover"
                  />
                </div>
              )}
              <Button type="submit" disabled={props.loading}>
                {!props.loading ? "Save" : "Loading..."}
              </Button>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default BannerModal;
