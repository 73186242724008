import {
  Button,
  Input,
  Option,
  Select,
  Textarea,
} from "@material-tailwind/react";
import { Autocomplete, Divider, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteServices,
  getServices,
  putServices,
} from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import ImageModal from "../../components/modal/admin/ImageModal";
import { IoIosPlay } from "react-icons/io";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function UpdatePropertyPage() {
  const location = useLocation().state;
  const [fieldData, setFieldData] = useState({
    property_name: location.property_data?.property_name || "",
    property_desc: location.property_data?.description || "",
    url: location.property_data?.url || "",
    sqrt: location.property_data?.sqft || "",
    // price_sqrt: location.property_data?.price_sqft || "",
    bedrrom: location.property_data?.residentialInfo
      ? location.property_data?.residentialInfo?.bedroom
      : "",
    bathrooms: location.property_data?.residentialInfo
      ? location.property_data?.residentialInfo?.bathroom
      : "",
    balcony: location.property_data?.commercialInfo
      ? location.property_data?.commercialInfo?.balcony
      : location.property_data?.residentialInfo
      ? location.property_data?.residentialInfo?.balcony
      : "",
    year_built: location.property_data?.commercialInfo
      ? location.property_data?.commercialInfo?.year_built
      : location.property_data?.residentialInfo
      ? location.property_data?.residentialInfo?.year_built
      : "",
    garden: location.property_data?.commercialInfo
      ? location.property_data?.commercialInfo?.terrace
      : location.property_data?.residentialInfo
      ? location.property_data?.residentialInfo?.terrace
      : "",
    // city: location.property_data?.city || "",
    location: location.property_data?.location || "",
    // pin_code: location.property_data?.pincode || "",
    total_price: location.property_data?.price || "",
  });
  const [selectedItem, setSelectedItems] = useState({
    property_type: location.property_data?.property_type || null,
    interior_status: location.property_data?.commercialInfo
      ? location.property_data?.commercialInfo?.interior_status
      : location.property_data?.residentialInfo
      ? location.property_data?.residentialInfo?.interior_status
      : null,
    property_category: location.property_data?.category || null,
    property_label: location.property_data?.label || null,
    area_type: location.property_data?.area_type ?? null,
    terrace_type: location.property_data?.commercialInfo
      ? location.property_data?.commercialInfo?.terrace_type
      : location.property_data?.residentialInfo
      ? location.property_data?.residentialInfo?.terrace_type
      : null,
    parking: location.property_data?.commercialInfo
      ? location.property_data?.commercialInfo?.parking
      : location.property_data?.residentialInfo
      ? location.property_data?.residentialInfo?.parking
      : null,
  });
  const [amenitiesOptions, setAmenitiesOptions] = useState([]);
  const [fieldAmenities, setFieldAmenities] = useState(
    location.property_data?.amenityInfo || []
  );
  const [fieldSelectedFiles, setFieldSelectedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(
    location.property_data?.file || []
  );
  const [modal, setModal] = useState({ add_file: false, warning_modal: false });
  const [loading, setLoading] = useState(false);
  const prevFieldAmenities = useRef(fieldAmenities);
  const access_token = sessionStorage.getItem("land-trandes-access-token");
  const navigate = useNavigate();
  const [characterLimit] = useState(100000);
  const [descCount, setDescCount] = useState(
    location.property_data?.description || ""
  );

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        // { indent: "-1" },
        // { indent: "+1" },
      ],
      [{ align: [] }],
      // ["link", "image"],
      // ["clean"],
    ],
  };

  useEffect(() => {
    fetchAmenitiesData();
    fetchPropertyDetails();

    prevFieldAmenities.current = fieldAmenities;
  }, [fieldAmenities]);

  const fetchAmenitiesData = async () => {
    try {
      const res = await getServices(
        `/api/amenities/list_amenity`,
        access_token
      );
      if (res.responseCode === 200) {
        setAmenitiesOptions(res.responseData);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchPropertyDetails = async () => {
    try {
      const res = await getServices(
        `/api/property/list_property?property_id=${location.property_data?.property_id}`
      );
      if (res.responseCode === 200) {
        setSelectedFiles(res.responseData[0]?.file);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const filteredOptions = amenitiesOptions.filter(
    (option) =>
      !fieldAmenities.some(
        (selected) => selected.amenity_id === option.amenity_id
      )
  );

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const maxImageSize = 5 * 1024 * 1024; // 5MB in bytes
    const maxVideoSize = 210 * 1024 * 1024; // 210MB in bytes

    const filteredFiles = files.filter((file) => {
      if (file.type.startsWith("image/")) {
        return file.size <= maxImageSize;
      } else if (file.type.startsWith("video/")) {
        return file.size <= maxVideoSize;
      }
      return false; // Exclude other file types
    });

    setFieldSelectedFiles((prevFiles) => [...prevFiles, ...filteredFiles]);

    // Optionally, you can notify the user if some files were too large
    const rejectedFiles = files.filter((file) => {
      if (file.type.startsWith("image/")) {
        return file.size > maxImageSize;
      } else if (file.type.startsWith("video/")) {
        return file.size > maxVideoSize;
      }
      return true; // Reject other file types
    });

    if (rejectedFiles.length > 0) {
      alert(
        "Some files were too large and have not been added. Maximum file size is 5MB for images and 200MB for videos."
      );
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const hasImage = (selectedFiles) => {
      const imageExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "webp",
        "svg",
        "tiff",
        "heif",
        "heic",
        "avif",
      ];
      return selectedFiles.some((url) => {
        const extension = url.split(".").pop().toLowerCase();
        return imageExtensions.includes(extension);
      });
    };

    if (hasImage(selectedFiles)) {
      const urlencoded = new URLSearchParams();

      urlencoded.append("property_name", fieldData.property_name);
      urlencoded.append("description", fieldData.property_desc);
      urlencoded.append("category", selectedItem.property_category);
      urlencoded.append("sqft", fieldData.sqrt);
      urlencoded.append("location", fieldData.location);
      // urlencoded.append("price_sqft", fieldData.price_sqrt);
      urlencoded.append("label", selectedItem.property_label);
      // urlencoded.append("city", fieldData.city);
      // urlencoded.append("pincode", fieldData.pin_code);
      urlencoded.append("price", fieldData.total_price);
      urlencoded.append("url", fieldData.url);
      urlencoded.append("area_type", selectedItem.area_type ?? "");
      for (let i = 0; i < fieldAmenities.length; i++) {
        urlencoded.append("amenity_id", fieldAmenities[i].amenity_id);
      }

      if (selectedItem.property_type === "1") {
        urlencoded.append("bedroom", fieldData.bedrrom);
        urlencoded.append("bathroom", fieldData.bathrooms);
        urlencoded.append("balcony", fieldData.balcony);
        urlencoded.append("interior_status", selectedItem.interior_status);
        urlencoded.append("year_built", fieldData.year_built);
        urlencoded.append("terrace", fieldData.garden);
        urlencoded.append("parking", selectedItem.parking);
        urlencoded.append("terrace_type", selectedItem.terrace_type ?? "");
      } else if (selectedItem.property_type === "2") {
        urlencoded.append("balcony", fieldData.balcony);
        urlencoded.append("interior_status", selectedItem.interior_status);
        urlencoded.append("year_built", fieldData.year_built);
        urlencoded.append("terrace", fieldData.garden);
        urlencoded.append("parking", selectedItem.parking);
        urlencoded.append("terrace_type", selectedItem.terrace_type ?? "");
      }

      try {
        const res = await putServices(
          `/api/property/edit_property?property_id=${location.property_data?.property_id}`,
          urlencoded,
          access_token
        );
        setLoading(false);
        if (res.responseCode === 200) {
          navigate("/admin/dashboard");
          toast.success("Property updated...");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    } else {
      setLoading(false);
      alert("You must upload at least one image.");
    }
  };

  const handleRemoveFile = async (file) => {
    const file_name = file.split("/");

    if (window.confirm("Do you want delete this image?")) {
      try {
        const res = await deleteServices(
          `/api/property/delete_image?property_id=${location.property_data?.property_id}`,
          access_token,
          { remove_image: file_name[file_name.length - 1] }
        );
        if (res.responseCode === 200) {
          toast.success("Image deleted...");
          fetchPropertyDetails();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleAddFileModal = () => {
    setModal({ ...modal, add_file: true });
    setFieldSelectedFiles([]);
  };

  const handleRemoveUpdateFile = (index) => {
    setFieldSelectedFiles((prevFiles) =>
      prevFiles.filter((_, i) => i !== index)
    );
  };

  const handleAddFileSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    for (let i = 0; i < fieldSelectedFiles.length; i++) {
      formData.append("file", fieldSelectedFiles[i]);
    }

    try {
      const res = await putServices(
        `/api/property/update_image?property_id=${location.property_data?.property_id}`,
        formData,
        access_token
      );
      setLoading(false);
      if (res.responseCode === 200) {
        fetchPropertyDetails();
        toast.success("Image added...");
        setModal({ ...modal, add_file: false });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleRemoveAmenities = async (removed_data) => {
    try {
      const res = await deleteServices(
        `/api/property/delete_amenity?property_id=${location.property_data?.property_id}&amenity_id=${removed_data.amenity_id}`,
        access_token
      );
      if (res.responseCode === 200) {
      } else if (res.responseCode === 202) {
        return;
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-4xl">Update Property</p>
        </div>

        <form
          onSubmit={handleUpdate}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3"
        >
          <Select
            label="Property Category"
            style={{ fontFamily: "Poppins" }}
            onChange={(e) =>
              setSelectedItems({ ...selectedItem, property_category: e })
            }
            value={selectedItem.property_category}
          >
            <Option value="1" style={{ fontFamily: "Poppins" }}>
              Buy
            </Option>
            <Option value="2" style={{ fontFamily: "Poppins" }}>
              Rent / Lease
            </Option>
          </Select>
          <Input
            label="Property Name"
            value={fieldData.property_name}
            onChange={(e) =>
              setFieldData({ ...fieldData, property_name: e.target.value })
            }
            style={{ fontFamily: "Poppins" }}
            required
          />

          <div className="col-span-1 sm:col-span-2 lg:col-span-3">
            {/* <div className="md:mb-12 p-4 rounded-lg border border-gray-300 h-60">
            <div className="relative h-full"> */}
            <ReactQuill
              onChange={(content) => {
                const sanitizedContent =
                  content === "<p><br></p>" ? "" : content;
                setFieldData({
                  ...fieldData,
                  property_desc: sanitizedContent,
                });
                setDescCount(sanitizedContent);
              }}
              value={fieldData.property_desc}
              placeholder="Write a description..."
              modules={modules}
              className="mb-12 rounded-lg h-32"
            />
            {/* </div>
        </div> */}
          </div>
          <div className="flex justify-end -mt-2 col-span-1 sm:col-span-2 lg:col-span-3">
            <p className="text-sm">
              {descCount.length} / {characterLimit}
            </p>
          </div>

          <Input
            type="url"
            label="YouTube Video URL"
            value={fieldData.url}
            onChange={(e) =>
              setFieldData({ ...fieldData, url: e.target.value })
            }
            style={{ fontFamily: "Poppins" }}
            required={false}
          />

          {/* {location.property_data.property_type && selectedItem.property_category && ( */}
          <>
            {/* divider */}
            <div className="flex items-center col-span-1 sm:col-span-2 lg:col-span-3">
              <h1 className="text-[16px] md:text-[22px] font-normal text-[#b1bec5] mr-3">
                Basics
              </h1>
              <Divider
                className="flex-1 text-[#b0bec5]"
                style={{ borderBottomWidth: "2px" }}
              />
            </div>

            {selectedItem?.property_type === "1" && (
              <Select
                label="Type"
                style={{ fontFamily: "Poppins" }}
                onChange={(e) =>
                  setSelectedItems({ ...selectedItem, property_label: e })
                }
                value={selectedItem.property_label}
              >
                <Option value="1" style={{ fontFamily: "Poppins" }}>
                  1BHK
                </Option>
                <Option value="2" style={{ fontFamily: "Poppins" }}>
                  2BHK
                </Option>
                <Option value="3" style={{ fontFamily: "Poppins" }}>
                  3BHK
                </Option>
                <Option value="4" style={{ fontFamily: "Poppins" }}>
                  3BHK+
                </Option>
              </Select>
            )}

            {selectedItem?.property_type === "2" && (
              <Select
                label="Type"
                style={{ fontFamily: "Poppins" }}
                onChange={(e) =>
                  setSelectedItems({ ...selectedItem, property_label: e })
                }
                value={selectedItem.property_label}
              >
                <Option value="1" style={{ fontFamily: "Poppins" }}>
                  Industrial Building
                </Option>
                <Option value="2" style={{ fontFamily: "Poppins" }}>
                  Industrial Shed
                </Option>
                <Option value="3" style={{ fontFamily: "Poppins" }}>
                  Office Space
                </Option>
                <Option value="4" style={{ fontFamily: "Poppins" }}>
                  Shop/Showroom
                </Option>
                <Option value="5" style={{ fontFamily: "Poppins" }}>
                  Warehouse/Godown
                </Option>
              </Select>
            )}

            {selectedItem?.property_type === "3" && (
              <Select
                label="Type"
                style={{ fontFamily: "Poppins" }}
                onChange={(e) =>
                  setSelectedItems({ ...selectedItem, property_label: e })
                }
                value={selectedItem.property_label}
              >
                <Option value="1" style={{ fontFamily: "Poppins" }}>
                  Agricultural
                </Option>
                <Option value="2" style={{ fontFamily: "Poppins" }}>
                  Commercial
                </Option>
                <Option value="3" style={{ fontFamily: "Poppins" }}>
                  Residential
                </Option>
              </Select>
            )}

            <div className="flex custom-input-area">
              <Input
                label="Area"
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                value={fieldData.sqrt}
                onChange={(e) =>
                  setFieldData({ ...fieldData, sqrt: e.target.value })
                }
                style={{ fontFamily: "Poppins" }}
                className="rounded-r-none"
                required
              />
              <Select
                label="Area Type"
                style={{ fontFamily: "Poppins" }}
                className="rounded-l-none"
                onChange={(e) =>
                  setSelectedItems({ ...selectedItem, area_type: e })
                }
                value={selectedItem.area_type}
              >
                <Option value="1" style={{ fontFamily: "Poppins" }}>
                  Acres
                </Option>
                <Option value="2" style={{ fontFamily: "Poppins" }}>
                  Cents
                </Option>
                <Option value="3" style={{ fontFamily: "Poppins" }}>
                  Sqft
                </Option>
              </Select>
            </div>
            {/* <Input
              label="Price per Sqft"
              type="number"
              value={fieldData.price_sqrt}
              onChange={(e) =>
                setFieldData({ ...fieldData, price_sqrt: e.target.value })
              }
              style={{ fontFamily: "Poppins" }}
              required={false}
            /> */}
            <Input
              label="Total Price"
              type="text"
              value={fieldData.total_price}
              onChange={(e) =>
                setFieldData({ ...fieldData, total_price: e.target.value })
              }
              style={{ fontFamily: "Poppins" }}
              required={true}
            />

            {selectedItem?.property_type === "1" && (
              <>
                <Input
                  label="Bedrooms"
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  value={fieldData.bedrrom}
                  onChange={(e) =>
                    setFieldData({ ...fieldData, bedrrom: e.target.value })
                  }
                  style={{ fontFamily: "Poppins" }}
                  required
                />
                <Input
                  label="Bathrooms"
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  value={fieldData.bathrooms}
                  onChange={(e) =>
                    setFieldData({ ...fieldData, bathrooms: e.target.value })
                  }
                  style={{ fontFamily: "Poppins" }}
                  required
                />
              </>
            )}

            {(selectedItem?.property_type === "1" ||
              selectedItem?.property_type === "2") && (
              <>
                <Input
                  label="Balconies"
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  value={fieldData.balcony}
                  onChange={(e) =>
                    setFieldData({ ...fieldData, balcony: e.target.value })
                  }
                  style={{ fontFamily: "Poppins" }}
                  required={selectedItem?.property_type === "1" ? true : false}
                />

                <Select
                  label="Interior Status"
                  style={{ fontFamily: "Poppins" }}
                  onChange={(e) =>
                    setSelectedItems({ ...selectedItem, interior_status: e })
                  }
                  value={selectedItem.interior_status}
                >
                  <Option value="1" style={{ fontFamily: "Poppins" }}>
                    Furnished
                  </Option>
                  <Option value="2" style={{ fontFamily: "Poppins" }}>
                    Semi furnished
                  </Option>
                  <Option value="3" style={{ fontFamily: "Poppins" }}>
                    Unfurnished
                  </Option>
                </Select>

                <Input
                  label="Year Built"
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  value={fieldData.year_built}
                  onChange={(e) =>
                    setFieldData({ ...fieldData, year_built: e.target.value })
                  }
                  style={{ fontFamily: "Poppins" }}
                  required
                />

                <div className="flex custom-input-area">
                  <Input
                    label="Open Garden/Terrace"
                    type="number"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    value={fieldData.garden}
                    onChange={(e) =>
                      setFieldData({
                        ...fieldData,
                        garden: e.target.value,
                      })
                    }
                    style={{ fontFamily: "Poppins" }}
                    className="rounded-r-none"
                    required={
                      selectedItem?.property_type === "1" ? true : false
                    }
                  />
                  <Select
                    label="Area Type"
                    style={{ fontFamily: "Poppins" }}
                    className="rounded-l-none"
                    onChange={(e) =>
                      setSelectedItems({ ...selectedItem, terrace_type: e })
                    }
                    value={selectedItem.terrace_type}
                  >
                    <Option value="1" style={{ fontFamily: "Poppins" }}>
                      Acres
                    </Option>
                    <Option value="2" style={{ fontFamily: "Poppins" }}>
                      Cents
                    </Option>
                    <Option value="3" style={{ fontFamily: "Poppins" }}>
                      Sqft
                    </Option>
                  </Select>
                </div>
              </>
            )}

            {(selectedItem?.property_type === "1" ||
              selectedItem?.property_type === "2") && (
              <Select
                label="Parking"
                style={{ fontFamily: "Poppins" }}
                onChange={(e) =>
                  setSelectedItems({ ...selectedItem, parking: e })
                }
                value={selectedItem.parking}
              >
                <Option value="1" style={{ fontFamily: "Poppins" }}>
                  Carport
                </Option>
                <Option value="2" style={{ fontFamily: "Poppins" }}>
                  Garage - attached
                </Option>
                <Option value="3" style={{ fontFamily: "Poppins" }}>
                  Garage - detached
                </Option>
                <Option value="4" style={{ fontFamily: "Poppins" }}>
                  Off-street
                </Option>
                <Option value="5" style={{ fontFamily: "Poppins" }}>
                  On-street
                </Option>
              </Select>
            )}

            {/* divider */}
            <div className="flex items-center col-span-1 sm:col-span-2 lg:col-span-3">
              <h1 className="text-[16px] md:text-[22px] font-normal text-[#b1bec5] mr-3">
                Amenities
              </h1>
              <Divider
                className="flex-1 text-[#b0bec5]"
                style={{ borderBottomWidth: "2px" }}
              />
            </div>

            <div className="col-span-1 sm:col-span-2 lg:col-span-3">
              <Autocomplete
                multiple
                id="multiple-limit-tags"
                options={filteredOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => option.amenity_name}
                onChange={(e, v, r) => {
                  setFieldAmenities(v);
                  if (r === "removeOption") {
                    const removedItem = prevFieldAmenities.current.find(
                      (item) => !v.includes(item)
                    );
                    handleRemoveAmenities(removedItem);
                  }
                }}
                defaultValue={fieldAmenities}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Amenities"
                    placeholder="Amenities"
                  />
                )}
                sx={{
                  fontFamily: "Poppins",
                  ".MuiAutocomplete-inputRoot": {
                    borderRadius: "8px",
                    fontFamily: "Poppins",
                  },
                  ".MuiInputLabel-root, .MuiChip-label": {
                    fontFamily: "Poppins",
                  },
                }}
              />
            </div>

            {/* divider */}
            <div className="flex items-center col-span-1 sm:col-span-2 lg:col-span-3">
              <h1 className="text-[16px] md:text-[22px] font-normal text-[#b1bec5] mr-3">
                Location
              </h1>
              <Divider
                className="flex-1 text-[#b0bec5]"
                style={{ borderBottomWidth: "2px" }}
              />
            </div>
            <Textarea
              label="Location"
              style={{ fontFamily: "Poppins" }}
              value={fieldData.location}
              onChange={(e) =>
                setFieldData({ ...fieldData, location: e.target.value })
              }
            />
            {/* <Input
              label="City"
              type="text"
              value={fieldData.city}
              onChange={(e) =>
                setFieldData({ ...fieldData, city: e.target.value })
              }
              style={{ fontFamily: "Poppins" }}
              required
            />
            <Input
              label="Pin code"
              type="number"
              value={fieldData.pin_code}
              onChange={(e) =>
                setFieldData({ ...fieldData, pin_code: e.target.value })
              }
              style={{ fontFamily: "Poppins" }}
              required
            /> */}

            {/* divider */}
            <div className="flex items-center col-span-1 sm:col-span-2 lg:col-span-3">
              <h1 className="text-[16px] md:text-[22px] font-normal text-[#b1bec5] mr-3">
                Images
              </h1>
              <Divider
                className="flex-1 text-[#b0bec5]"
                style={{ borderBottomWidth: "2px" }}
              />
            </div>
            <div className="col-span-1 sm:col-span-2 lg:col-span-3">
              {/* <Input
              label="Images"
              type="file"
              //   onChange={handleFileChange}
              accept="image/*"
              multiple
              style={{ fontFamily: "Poppins" }}
              required
            /> */}
              <div className="flex justify-end">
                <Button
                  onClick={handleAddFileModal}
                  style={{ fontFamily: "Poppins" }}
                  type="button"
                >
                  Add File
                </Button>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {selectedFiles.map((file, index) => {
                  // const url = URL.createObjectURL(file);
                  return (
                    <div
                      key={index}
                      style={{ margin: "10px", position: "relative" }}
                    >
                      {file.endsWith(".jpg") ||
                      file.endsWith(".jpeg") ||
                      file.endsWith(".png") ||
                      file.endsWith(".gif") ||
                      file.endsWith(".webp") ||
                      file.endsWith(".svg") ||
                      file.endsWith(".heif") ||
                      file.endsWith(".heic") ||
                      file.endsWith(".avif") ? (
                        <img
                          src={file}
                          alt={`preview ${index}`}
                          style={{
                            width: "120px",
                            height: "80px",
                            objectFit: "cover",
                          }}
                          className="border border-gray-500 rounded-md"
                        />
                      ) : (
                        <div className="relative">
                          <video
                            src={file}
                            //   controls
                            style={{
                              width: "120px",
                              height: "80px",
                              objectFit: "cover",
                            }}
                            className="border border-gray-500 rounded-md"
                          />
                          <div className="absolute inset-0 flex items-center justify-center">
                            <IoIosPlay className="text-black p-1 text-2xl bg-white rounded-full" />
                          </div>
                        </div>
                      )}
                      {selectedFiles.length > 1 && (
                        <button
                          type="button"
                          onClick={() => handleRemoveFile(file)}
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            cursor: "pointer",
                            fontSize: "12px",
                            padding: "0 5px",
                          }}
                        >
                          X
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <Button
              type="submit"
              style={{ fontFamily: "Poppins" }}
              disabled={loading}
            >
              {!loading ? "Update" : "Loading..."}
            </Button>
          </>
          {/* )} */}
        </form>
      </div>

      <ImageModal
        open={modal.add_file}
        onClose={() => setModal({ ...modal, warning_modal: true })}
        // onClose={() => setModal({ ...modal, add_file: false })}
        handleFileChange={handleFileChange}
        fieldSelectedFiles={fieldSelectedFiles}
        handleAddFileSubmit={handleAddFileSubmit}
        handleRemoveUpdateFile={handleRemoveUpdateFile}
        loading={loading}
        warning_modal_open={modal.warning_modal}
        warning_modal_close={() => setModal({ ...modal, warning_modal: false })}
        warning_modal_yes_btn={() =>
          setModal({ ...modal, warning_modal: false, add_file: false })
        }
      />
    </>
  );
}

export default UpdatePropertyPage;
