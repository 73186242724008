import { Button, Option, Select, Textarea } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { postServices, putServices } from "../../apiServices/apiServices";

function AdminManageLookingFor() {
  const [buyerDesc, setBuyerDesc] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation().state;
  const navigate = useNavigate();
  const access_token = sessionStorage.getItem("land-trandes-access-token");

  useEffect(() => {
    setBuyerDesc(location.buyerDesc);
    setSelectedType(location.prop_type);
    setSelectedCategory(location.prop_category);
  }, [location]);

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (selectedType && selectedCategory) {
      setLoading(false);

      try {
        const res = await postServices(
          `/api/lookup/add_lookup`,
          {
            category: selectedCategory,
            property_type: selectedType,
            description: buyerDesc,
          },
          access_token
        );
        if (res.responseCode === 200) {
          navigate(-1);
          toast.success("New buyer's data added...");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      alert("Please select a property type and category.");
      setLoading(false);
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await putServices(
        `/api/lookup/edit_lookup?lookup_id=${location.buyer_id}`,
        {
          category: selectedCategory,
          description: buyerDesc,
          property_type: selectedType,
        },
        access_token
      );
      setLoading(false);
      if (res.responseCode === 200) {
        toast.success("Buyer's data updated...");
        navigate(-1);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-2xl sm:text-4xl">Add Buyer Data</p>
        </div>
        <form
          className="grid grid-cols-1 md:grid-cols-2 gap-4"
          onSubmit={location.update ? handleUpdateSubmit : handleAddSubmit}
        >
          <Select
            label="Property Type"
            style={{ fontFamily: "Poppins" }}
            onChange={(e) => setSelectedType(e)}
            value={selectedType?.toString()}
          >
            <Option value="1" style={{ fontFamily: "Poppins" }}>
              Residential
            </Option>
            <Option value="2" style={{ fontFamily: "Poppins" }}>
              Commercial
            </Option>
            <Option value="3" style={{ fontFamily: "Poppins" }}>
              Land/Plots
            </Option>
          </Select>
          <Select
            label="Property Category"
            style={{ fontFamily: "Poppins" }}
            onChange={(e) => setSelectedCategory(e)}
            value={selectedCategory?.toString()}
          >
            <Option value="1" style={{ fontFamily: "Poppins" }}>
              Buy
            </Option>
            <Option value="2" style={{ fontFamily: "Poppins" }}>
              Rent/Lease
            </Option>
          </Select>
          <div className="md:col-span-2">
            <Textarea
              label="Description"
              style={{ fontFamily: "Poppins" }}
              value={buyerDesc}
              onChange={(e) => setBuyerDesc(e.target.value)}
              maxLength={300}
              required
            />
            <div className="flex justify-end">
              <p className="text-sm">
                {buyerDesc.length} / {300}
              </p>
            </div>
          </div>
          <div className="flex justify-end md:col-span-2">
            <Button
              type="submit"
              style={{ fontFamily: "Poppins" }}
              className="tracking-widest"
              disabled={loading}
            >
              {loading ? "Loading..." : location.update ? "Update" : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AdminManageLookingFor;
