import React, { useEffect, useState } from "react";
import {
  Navbar,
  Typography,
  IconButton,
  MobileNav,
} from "@material-tailwind/react";
import Logo from "../assets/new_logo_rao&rai.svg";
import ContactModal from "./modal/user/ContactModal";
import { Link, useLocation } from "react-router-dom";
import { MdPhone } from "react-icons/md";
import LookingForIcon from "../assets/looking-for-icon.png";

export function StickyNavbar(props) {
  const [openNav, setOpenNav] = React.useState(false);
  const [modal, setModal] = useState({ contact_modal: false });
  const location = useLocation();
  const [hasShadow, setHasShadow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const handleContactModal = () => {
    setModal({ ...modal, contact_modal: true });
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setHasShadow(true);
    } else {
      setHasShadow(false);
    }
  };

  const navList = (
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        color="blue-gray"
        className="p-1 font-normal tracking-[1px] mx-auto lg:mx-0"
      >
        <Link
          to="/residential"
          className={`flex items-center w-fit uppercase hover:text-[#224178] hover:border-b-[1px] hover:border-[#224178] ${
            location.pathname === "/residential"
              ? "text-[#5e78a3] border-b-[1px] border-[#5e78a3]"
              : ""
          }`}
          style={{ fontFamily: "Poppins" }}
        >
          Residential
        </Link>
      </Typography>
      <Typography
        as="li"
        color="blue-gray"
        className="p-1 font-normal tracking-[1px] mx-auto lg:mx-0"
      >
        <Link
          to="/plot"
          className={`flex items-centert w-fit uppercase hover:text-[#224178] hover:border-b-[1px] hover:border-[#224178] ${
            location.pathname === "/plot"
              ? "text-[#5e78a3] border-b-[1px] border-[#5e78a3]"
              : ""
          }`}
          style={{ fontFamily: "Poppins" }}
        >
          Land/Plot
        </Link>
      </Typography>
      <Typography
        as="li"
        color="blue-gray"
        className="p-1 font-normal tracking-[1px] mx-auto lg:mx-0"
      >
        <Link
          to="/commercial"
          className={`flex items-center w-fit uppercase hover:text-[#224178] hover:border-b-[1px] hover:border-[#224178] ${
            location.pathname === "/commercial"
              ? "text-[#5e78a3] border-b-[1px] border-[#5e78a3]"
              : ""
          }`}
          style={{ fontFamily: "Poppins" }}
        >
          Commercial
        </Link>
      </Typography>
      <Typography
        as="li"
        color="blue-gray"
        className="p-1 font-normal tracking-[1px] mx-auto lg:mx-0 hidden lg:block"
      >
        <Link
          to="/looking_for/1"
          className={`flex items-center w-fit uppercase hover:text-[#224178] hover:border-b-[1px] hover:border-[#224178] ${
            location.pathname === "/looking_for/1"
              ? "text-[#5e78a3] border-b-[1px] border-[#5e78a3]"
              : ""
          }`}
          style={{ fontFamily: "Poppins" }}
        >
          <img src={LookingForIcon} alt="" className="h-7 w-7" />
        </Link>
      </Typography>

      <Typography
        as="li"
        color="blue-gray"
        className="p-1 font-normal hidden lg:block"
      >
        <a
          href="tel:+918277268334"
          className="flex items-center bg-[#5e78a3] text-white px-3 py-1 rounded-full font-semibold"
          style={{ fontFamily: "Poppins" }}
        >
          <MdPhone className="mr-2" />
          (+91) 82772 68334
        </a>
      </Typography>
      {/* <Typography
        as="li"
        color="blue-gray"
        className="p-1 font-normal tracking-[1px] mx-auto lg:mx-0"
      >
        <Link
          to="/looking_for/1"
          className={`flex items-center w-fit uppercase hover:text-[#224178] hover:border-b-[1px] hover:border-[#224178] ${
            location.pathname === "/looking_for/1"
              ? "text-[#FB7B6A] border-b-[1px] border-[#c19000]"
              : ""
          }`}
          style={{ fontFamily: "Poppins" }}
        >
          <img src={LookingForIcon} alt="" className="h-7 w-7" />
        </Link>
      </Typography> */}
    </ul>
  );

  return (
    <div>
      <Navbar
        className="fixed top-0 left-0 right-0 shadow-none z-[99] h-max w-full text-black rounded-none py-2 lg:py-4 border-none px-2"
        style={{
          boxShadow: hasShadow ? "0px 20px 20px -20px rgba(0, 0, 0, 0.3)" : "",
          maxWidth: "unset",
        }}
      >
        <div className="flex items-center justify-between text-blue-gray-900 bg-whit px-3 max-w-[1300px] mx-auto">
          <Link to="/" className={`mr-4 cursor-pointer py-1.5 font-medium`}>
            <img className="w-36 md:w-[unset] md:h-12" src={Logo} alt="Logo" />
          </Link>
          <div className="flex items-center gap-3">
            <div className="hidden lg:block">{navList}</div>
            <Link
              to="/looking_for/1"
              className={`block lg:hidden`}
              style={{ fontFamily: "Poppins" }}
            >
              <img src={LookingForIcon} alt="" className="h-7 w-[1.8rem]" />
            </Link>
            <IconButton
              variant="text"
              className="ml-auto w-fit text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden res-nav"
              style={{ position: "unset" }}
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <div className="flex gap-2 text-[#555555]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </div>
              )}
            </IconButton>
            <a href="tel:+918272268334">
              <MdPhone className="block lg:hidden text-2xl md:text-3xl text-[#555555]" />
            </a>
          </div>
        </div>
        <MobileNav open={openNav}>{navList}</MobileNav>
      </Navbar>
      <div className="mx-auto pb-1 pt-16 md:pt-20 lg:pt-20">
        {props.children}
      </div>
      <div className="fixed -right-5 top-3/4 z-[99]">
        <button
          className="bg-[#5e78a3] text-sm md:text-base text-white py-1 md:py-2 px-3 md:px-4 -rotate-90 font-semibold border-2 border-white hover:border-[#5e78a3] hover:bg-white hover:text-[#5e78a3]"
          style={{ borderRadius: "8px 8px 0px 0px", transition: "all 0.7s" }}
          onClick={handleContactModal}
        >
          Say Hi
        </button>
      </div>

      <ContactModal
        open={modal.contact_modal}
        onClose={() => setModal({ ...modal, contact_modal: false })}
      />
    </div>
  );
}
