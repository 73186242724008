import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { getServices } from "../../apiServices/apiServices";
import moment from "moment";
import LoadingGif from "../../assets/loader-0.02.gif";

function AdminBlogDetailPage() {
  const [loading, sertLoading] = useState(false);
  const [blogData, setBlogData] = useState({});
  const { blog_code } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchBlogDetails();
  }, []);

  const fetchBlogDetails = async () => {
    sertLoading(true);
    try {
      const res = await getServices(
        `/api/blog/list_blog?blog_code=${blog_code}`
      );
      sertLoading(false);
      if (res.responseCode === 200) {
        setBlogData(res.responseData.data[0]);
      } else {
        setBlogData({});
      }
    } catch (error) {
      sertLoading(false);
      toast.error(error);
    }
  };

  return (
    <>
      {!loading ? (
        <div className="p-4">
          <div className="flex justify-between mb-1 sm:mb-3">
            <p className="font-semibold text-2xl sm:text-4xl font-afacad">
              {blogData.blog_title}
            </p>
          </div>
          <p className="text-sm text-gray-600 font-afacad">
            Published Time:{" "}
            {moment(blogData.publish_date, "MMM DD, YYYY h:mm a").format(
              "DD/MM/YYYY h.mmA"
            )}
          </p>
          <img
            src={blogData.image}
            alt=""
            className="h-[200px] sm:h-[250px] md:h-[350px] lg:h-[500px] object-cover w-full mt-2"
          />
          <p
            className="font-afacad pt-3 inner-html-custom"
            style={{ wordBreak: "break-word" }}
            dangerouslySetInnerHTML={{ __html: blogData.description }}
          ></p>
        </div>
      ) : (
        <div className="flex justify-center items-center h-[50vh]">
          <img src={LoadingGif} alt="" className="h-20" />
        </div>
      )}
    </>
  );
}

export default AdminBlogDetailPage;
