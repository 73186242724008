import { IconButton } from "@material-tailwind/react";
import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { SlPencil } from "react-icons/sl";
import { getServices, postServices } from "../../apiServices/apiServices";
import DynamicOverviewModal from "../../components/modal/admin/DynamicOverviewModal";

function AdminListDynamicOverview() {
  const [modal, setModal] = useState({
    update_overview: false,
  });
  const [fieldData, setFieldData] = useState({
    description: "",
    property_type: "",
  });
  const [overviewData, setOverviewData] = useState([]);
  const [descCount, setDescCount] = useState("");
  const access_token = sessionStorage.getItem("land-trandes-access-token");

  useEffect(() => {
    fetchOverviewData();
  }, []);

  const fetchOverviewData = async () => {
    try {
      const res = await getServices(`/api/overview/list`, access_token);
      if (res.responseCode === 200) {
        setOverviewData(res.responseData);
      } else {
        setOverviewData([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUpdateOverviewModal = (data) => {
    setModal({ ...modal, update_overview: true });
    setFieldData({
      ...fieldData,
      description: data.description,
      property_type: data.property_type,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      fieldData.description.length > 0 &&
      fieldData.description.length <= 100000
    ) {
      try {
        const res = await postServices(
          `/api/overview/add`,
          {
            description: fieldData.description,
            property_type: fieldData.property_type,
          },
          access_token
        );
        if (res.responseCode === 200) {
          setModal({ ...modal, update_overview: false });
          fetchOverviewData();
          toast.success("Overview updated...");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      if (fieldData.description.length === 0) {
        alert("Description should not be empty.");
      } else if (fieldData.description.length > 100000) {
        alert(
          "Description is too long. It should be less than 100000 characters."
        );
      } else {
        alert("Description is invalid.");
      }
    }
  };

  return (
    <>
      <div className="p-4">
        <p className="font-semibold text-2xl sm:text-4xl">
          Manage Dynamic Overview
        </p>

        {/* home page overview */}
        <div className="flex items-center pt-5">
          <h1 className="text-[16px] md:text-[25px] font-normal text-[#b1bec5] mr-3 font-afacad">
            Home Page
          </h1>
          <Divider
            className="flex-1 text-[#b0bec5]"
            style={{ borderBottomWidth: "2px" }}
          />
        </div>
        <div className="flex justify-end items-center">
          <IconButton
            size="sm"
            className="bg-white"
            onClick={() => handleUpdateOverviewModal(overviewData[0])}
          >
            <SlPencil className="font-semibold text-black text-[17px]" />
          </IconButton>
        </div>
        <div className="font-afacad description-count pt-2">
          <div
            className="inner-html-custom"
            dangerouslySetInnerHTML={{ __html: overviewData[0]?.description }}
          ></div>
        </div>

        {/* residential page overview */}
        <div className="flex items-center pt-5">
          <h1 className="text-[16px] md:text-[25px] font-normal text-[#b1bec5] mr-3 font-afacad">
            Residential Page
          </h1>
          <Divider
            className="flex-1 text-[#b0bec5]"
            style={{ borderBottomWidth: "2px" }}
          />
        </div>
        <div className="flex justify-end items-center">
          <IconButton
            size="sm"
            className="bg-white"
            onClick={() => handleUpdateOverviewModal(overviewData[1])}
          >
            <SlPencil className="font-semibold text-black text-[17px]" />
          </IconButton>
        </div>
        <div className="font-afacad description-count pt-2">
          <div
            className="inner-html-custom"
            dangerouslySetInnerHTML={{ __html: overviewData[1]?.description }}
          ></div>
        </div>

        {/* commercial page overview */}
        <div className="flex items-center pt-5">
          <h1 className="text-[16px] md:text-[25px] font-normal text-[#b1bec5] mr-3 font-afacad">
            Commercial Page
          </h1>
          <Divider
            className="flex-1 text-[#b0bec5]"
            style={{ borderBottomWidth: "2px" }}
          />
        </div>
        <div className="flex justify-end items-center">
          <IconButton
            size="sm"
            className="bg-white"
            onClick={() => handleUpdateOverviewModal(overviewData[2])}
          >
            <SlPencil className="font-semibold text-black text-[17px]" />
          </IconButton>
        </div>
        <div className="font-afacad description-count pt-2">
          <div
            className="inner-html-custom"
            dangerouslySetInnerHTML={{ __html: overviewData[2]?.description }}
          ></div>
        </div>

        {/* land/plots page overview */}
        <div className="flex items-center pt-5">
          <h1 className="text-[16px] md:text-[25px] font-normal text-[#b1bec5] mr-3 font-afacad">
            Land/Plot Page
          </h1>
          <Divider
            className="flex-1 text-[#b0bec5]"
            style={{ borderBottomWidth: "2px" }}
          />
        </div>
        <div className="flex justify-end items-center">
          <IconButton
            size="sm"
            className="bg-white"
            onClick={() => handleUpdateOverviewModal(overviewData[3])}
          >
            <SlPencil className="font-semibold text-black text-[17px]" />
          </IconButton>
        </div>
        <div className="font-afacad description-count pt-2">
          <div
            className="inner-html-custom"
            dangerouslySetInnerHTML={{ __html: overviewData[3]?.description }}
          ></div>
        </div>
      </div>

      <DynamicOverviewModal
        open={modal.update_overview}
        onClose={() => setModal({ ...modal, update_overview: false })}
        fieldData={fieldData}
        setFieldData={setFieldData}
        setDescCount={setDescCount}
        descCount={descCount}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default AdminListDynamicOverview;
