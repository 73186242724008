import React, { useEffect, useState } from "react";
import { StickyNavbar } from "../../components/UserNavbar";
import UserFooter from "../../components/UserFooter";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { getServices } from "../../apiServices/apiServices";
import LoadingGif from "../../assets/loader-0.02.gif";
import moment from "moment";
import { Helmet } from "react-helmet-async";

function BlogDetails() {
  const [loading, sertLoading] = useState(false);
  const [blogData, setBlogData] = useState({});
  const [seoData, setSeoData] = useState({});
  const { blog_code } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchBlogDetails();
    fetchSEOData();
  }, []);

  const fetchBlogDetails = async () => {
    sertLoading(true);
    try {
      const res = await getServices(
        `/api/blog/list_blog?blog_code=${blog_code}`
      );
      sertLoading(false);
      if (res.responseCode === 200) {
        setBlogData(res.responseData.data[0]);
      } else {
        setBlogData({});
      }
    } catch (error) {
      sertLoading(false);
      toast.error(error);
    }
  };

  const fetchSEOData = async () => {
    try {
      const res = await getServices(`/api/seo/list?seo_type=7`);
      if (res.responseCode === 200) {
        setSeoData(res.responseData[0]);
      } else {
        setSeoData({});
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div>
      <Helmet>
        {/* <title>{blogData.blog_title}</title> */}
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        {seoData.og_title !== "" &&
          seoData.og_title !== null &&
          seoData.og_title !== "null" && (
            <meta property="og:title" content={seoData.og_title} />
          )}
        {seoData.og_description !== "" &&
          seoData.og_description !== null &&
          seoData.og_description !== "null" && (
            <meta property="og:description" content={seoData.og_description} />
          )}
      </Helmet>
      <StickyNavbar>
        {!loading ? (
          <div className="mt-6 md:mt-2 max-w-[1300px] mx-auto px-3">
            <div className="flex justify-between mb-1 sm:mb-3">
              <p className="font-semibold text-2xl sm:text-4xl font-afacad">
                {blogData.blog_title}
              </p>
            </div>
            <p className="text-sm text-gray-600 font-afacad">
              Published Time:{" "}
              {moment(blogData.publish_date, "MMM DD, YYYY h:mm a").format(
                "DD/MM/YYYY h.mmA"
              )}
            </p>
            <img
              src={blogData.image}
              alt=""
              className="h-[200px] sm:h-[250px] md:h-[350px] lg:h-[500px] object-cover w-full mt-2"
            />
            <p
              className="font-afacad py-3 inner-html-custom"
              style={{ wordBreak: "break-word" }}
              dangerouslySetInnerHTML={{ __html: blogData.description }}
            ></p>
          </div>
        ) : (
          <div className="flex justify-center items-center h-[50vh]">
            <img src={LoadingGif} alt="" className="h-20" />
          </div>
        )}
      </StickyNavbar>
      <UserFooter />
    </div>
  );
}

export default BlogDetails;
