import { Button } from "@material-tailwind/react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React from "react";
import { IoClose } from "react-icons/io5";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function DynamicOverviewModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      // [{ align: [] }],
    ],
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <p className="font-semibold">
                {props.fieldData?.property_type === "1"
                  ? "Home"
                  : props.fieldData?.property_type === "2"
                  ? "Residential"
                  : props.fieldData?.property_type === "3"
                  ? "Commercial"
                  : props.fieldData?.property_type === "4"
                  ? "Land/Plot"
                  : ""}{" "}
                Overview
              </p>
              <IoClose
                className="cursor-pointer border border-[#000] rounded-full"
                onClick={props.onClose}
              />
            </div>
            <form
              onSubmit={props.handleSubmit}
              className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3"
            >
              <ReactQuill
                onChange={(content) => {
                  const sanitizedContent =
                    content === "<p><br></p>" ? "" : content;
                  props.setFieldData({
                    ...props.fieldData,
                    description: sanitizedContent,
                  });
                  props.setDescCount(sanitizedContent);
                }}
                value={props.fieldData?.description}
                placeholder="Write a description..."
                modules={modules}
                className="h-60 mb-16 sm:mb-10"
              />
              <div className="flex justify-end">
                <p className="text-sm font-afacad">
                  {props.descCount?.length} / {100000}
                </p>
              </div>
              <div className="flex justify-end items-center">
                <Button
                  type="submit"
                  disabled={props.loading}
                  style={{ fontFamily: "Poppins" }}
                >
                  Update
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default DynamicOverviewModal;
